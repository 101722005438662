import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  BANKS_ROUTE,
  PRE_QUALIFICATION_ROUTE,
  CALCULATORS_ROUTE,
  ABOUT_ROUTE,
  CONTACT_ROUTE,
  TERMS_ROUTE,
  PRIVACY_ROUTE,
  SITE_MAP_ROUTE,
  HELP_CENTER_ROUTE,
  ARTICLES_ROUTE,
  LAYOUT_ROUTE,
  LOAN_APPLICATION_ROUTE,
  LOAN_SUCCESS_ROUTE,
  MORTGAGE_ROUTE,
  REQUIRED_DOCUMENT_ROUTE,
  ERROR_ROUTE,
  PUBLIC_ROUTE,
} from './constants/route.constants';
import { LayoutGuard } from './guards/layout/layout.guard';
import { LoginComponent } from './modules/auth/components/login/login.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'public/login'
  },
  {
    path: BANKS_ROUTE.path,
    loadChildren: () => import('./modules/banks/banks.module').then(m => m.BanksModule),
  },
  // {
  //   path: CALCULATORS_ROUTE.path,
  //   loadChildren: () => import('./modules/loan-calculators/loan-calculators.module').then(m => m.LoanCalculatorsModule)
  // },
  {
    path: PRE_QUALIFICATION_ROUTE.path,
    loadChildren: () => import('./modules/pre-qualification/pre-qualification.module').then(m => m.PreQualificationModule)
  },
  {
    path: PRE_QUALIFICATION_ROUTE.path + '/:partnerId',
    loadChildren: () => import('./modules/pre-qualification/pre-qualification.module').then(m => m.PreQualificationModule)
  },
  {
    path: LOAN_APPLICATION_ROUTE.path,
    loadChildren: () => import('./modules/online-homeloan/online-homeloan.module').then(m => m.OnlineHomeloanModule)
  },
  {
    path: LOAN_SUCCESS_ROUTE.path,
    loadChildren: () => import('./modules/loan-scuccess/loan-scuccess.module').then(m => m.LoanScuccessModule)
  },
  {
    path: ERROR_ROUTE.path,
    loadChildren: () => import('./modules/static/error/error.module').then(m => m.ErrorModule)
  },
  // {
  //   path: CONTACT_ROUTE.path,
  //   loadChildren: () => import('./modules/static/contact/contact.module').then(m => m.ContactModule)
  // },
  {
    path: TERMS_ROUTE.path,
    loadChildren: () => import('./modules/static/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: PRIVACY_ROUTE.path,
    loadChildren: () => import('./modules/static/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: SITE_MAP_ROUTE.path,
    loadChildren: () => import('./modules/static/site-map/site-map.module').then(m => m.SiteMapModule)
  },
  // {
  //   path: HELP_CENTER_ROUTE.path,
  //   loadChildren: () => import('./modules/help-center/help-center.module').then(modules => modules.HelpCenterModule)
  // },
  // {
  //   path: ARTICLES_ROUTE.path,
  //   loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule)
  // },
  {
    path: LAYOUT_ROUTE.path,
    canLoad: [LayoutGuard],
    canActivate: [LayoutGuard],
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule)
  },
  // {
  //   path: MORTGAGE_ROUTE.path,
  //   loadChildren: () => import('./modules/mortgage-brokering/mortgage-brokering.module').then(m => m.MortgageBrokeringModule)
  // },
  {
    path: REQUIRED_DOCUMENT_ROUTE.path,
    loadChildren: () => import('./modules/requried-document/requried-document.module').then(m => m.RequriedDocumentModule)
  },
  {
    path: PUBLIC_ROUTE.path,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**', redirectTo: '', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }