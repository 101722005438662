import { Injectable } from '@angular/core';
import { AuthComponent } from '../view/auth.component';
import { MatDialog } from '@angular/material/dialog';
import { TabName } from '../constants/auth.constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn:'root'
})
export class AuthService {

  constructor(private $dialog: MatDialog,private router:Router) { }
  // open(tab: TabName = 'LOGIN', stopRedirect: boolean = false, token: string = null) {
  //   return this.$dialog.open(AuthComponent, {
  //     disableClose: true,
  //     width: '720px',
  //     data: { tab, token, stopRedirect },
  //     panelClass: 'account_modal',
  //   }).afterClosed();
  // }
  openLogin(stopRedirect: boolean = false) {
    this.router.navigate(['public/login'])
  }
  // openRegister(stopRedirect: boolean = false) {
  //   return this.open('REGISTER', stopRedirect);
  // }
  // openForgotPassword(stopRedirect: boolean = false) {
  //   return this.open('FORGOT', stopRedirect);
  // }
  // openResetPassword(token: string, stopRedirect: boolean = false) {
  //   return this.open('RESET', stopRedirect, token);
  // }
}
