import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordComponent } from './view/input-password.component';
import { InputSharedModule } from '../../common/input-shared/input-shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [InputPasswordComponent],
  imports: [
    CommonModule,
    InputSharedModule,
    MatIconModule,
    MatTooltipModule
  ],
  exports: [InputPasswordComponent]
})
export class InputPasswordModule { }
