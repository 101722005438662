import { Component, HostListener, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { LoaderService } from '../services/loader/loader.service';
import { HttpService } from 'src/app/services/http/http.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProfileService } from '@profile';
import { DataLayerService } from '../services/data-layer/data-layer.service';
import { TokenService } from '@token';

@Component({
  selector: 'nook-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  isLoading = false;
  profileData: any;
  // ipAddress: any;
  constructor(
        a2g: Angulartics2GoogleAnalytics,
        loader: LoaderService,
        private http: HttpClient,
        private router: ActivatedRoute,
        private _router: Router,
        private profile:ProfileService,
        public token: TokenService,
        private _dataLayerService: DataLayerService) {
    a2g.startTracking();
    loader.status.subscribe((status) => {
      this.isLoading = status;
    });
    this.profile.changes.subscribe((data) => {
      this.profileData = data;
    });
    this._router.events.subscribe(event=> {
      if (event instanceof NavigationEnd)
      {
          this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
          if(this.token.hasValue){
            const { _id, countryCode, email, phoneNumber, firstName, lastName } = this.profileData;
            this._dataLayerService.logEvent({
              event: "content-view",
              user_data: {
                user_id: _id,
                email_address: email,
                phone_number: `${countryCode}${phoneNumber}`,
                address: {
                  first_name: firstName,
                  last_name: lastName,
                },
              },
            });
          }
      }
  });
  }

  ngOnInit(): void {
  }

}
