import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { ProfileService } from '@profile';
import { TokenService } from '@token';
import { environment } from '@environment';
import { ProfileService } from '@profile';
import { LOGIN_ROUTE, PUBLIC_ROUTE } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class LayoutGuard implements CanActivate, CanLoad {
  constructor(
    public token: TokenService,
    private $router: Router,
    private profile: ProfileService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.handler();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.handler();
  }
  private handler() {
    if(!this.token.hasValue){
      this.$router.navigate([`${PUBLIC_ROUTE.path}/${LOGIN_ROUTE.path}`]);
    }
    return this.token.hasValue;
  }
}
