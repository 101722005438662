import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor(
    private $http: HttpClient,
    // private $dialog: MatDialog,
    // private $snackBar: MatSnackBar,
  ) {
  }
  get isOneTimeToken(): boolean {
    const remember = localStorage.getItem(environment.tokenRememberKey);
    return ![true, 'true'].some(value => remember === value);
  }
  get value() {
    if (this.isOneTimeToken && document.cookie.indexOf('checkClose') === -1) {
      document.cookie = `checkClose=true;`;
      this.reset();
      return null;
    }
    return localStorage.getItem(environment.tokenKey);
  }
  set value(token: string) {    
    localStorage.setItem(environment.tokenKey, token);
  }

  get hasValue(): boolean {
    return !!this.value;
  }
  async verify(): Promise<boolean> {
    // return new Promise((resolve, reject) => {
    //   if (this.value) {
    //     const headers = new HttpHeaders({
    //       Authorization: `Bearer ${this.value}`
    //     });
    //     this._http.head('/token', {headers})
    //     .subscribe(() => resolve(true), () => resolve(false));
    //   } else {
    //     resolve(false);
    //   }
    // });
    return true;
  }
  rememberToken(status: boolean) {
    localStorage.setItem(environment.tokenRememberKey, `${status}`);
  }
  forgetToken() {
    localStorage.removeItem(environment.tokenRememberKey);
  }
  remove() {
    localStorage.removeItem(environment.tokenKey);
  }
  reset() {
    this.remove();
    this.forgetToken();
  }
  get header() {
    return `Bearer ${this.value}`;
  }


}
