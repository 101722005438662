import {
    LOAN_PERSONAL_ROUTE,
    LOAN_CONTACT_ROUTE,
    LOAN_INFO_ROUTE,
    LOAN_EMPLOYMENT_ROUTE,
    LOAN_OTHER_ROUTE,
    LOAN_REQUIRED_ROUTE,
} from './route.constants';


export const LOAN_APPLICATION_STEPS = [
    {
        label: 'Personal  Information',
        url: LOAN_PERSONAL_ROUTE.url
    },
    {
        label: 'Contact Information',
        url: LOAN_CONTACT_ROUTE.url
    },
    {
        label: 'Loan Details',
        url: LOAN_INFO_ROUTE.url
    },
    {
        label: 'Employment Information',
        url: LOAN_EMPLOYMENT_ROUTE.url
    },
    {
        label: 'Other Information',
        url: LOAN_OTHER_ROUTE.url
    },
    {
        label: 'Required Information',
        url: LOAN_REQUIRED_ROUTE.url
    },
];
