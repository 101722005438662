import { LOAN_APPLICATION_ROUTE } from 'src/app/constants/route.constants';

export const LOAN_PERSONAL_ROUTE = {
    path: 'personal-information',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_CONTACT_ROUTE = {
    path: 'contact-information',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_INFO_ROUTE = {
    path: 'loan-details',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_EMPLOYMENT_ROUTE = {
    path: 'employment-information',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_OTHER_ROUTE = {
    path: 'other-information',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_REQUIRED_ROUTE = {
    path: 'required-information',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};
// export const LOAN_DOCUMENT_REQUIRED_ROUTE = {
//     path: 'required-document',
//     get url(): string {
//         return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
//     }
// };

export const LOAN_REVIEW_ROUTE = {
    path: 'review',
    get url(): string {
        return `${LOAN_APPLICATION_ROUTE.url}/${this.path}`;
    }
};

