import { NgModule } from '@angular/core';
import {
  InputEmailModule,
  InputPasswordModule,
  InputNameModule,
  InputUsernameModule,
  InputMobileModule
} from './modules';
import { InputSharedModule } from './common/input-shared/input-shared.module';

const Modules = [
  InputNameModule,
  InputEmailModule,
  InputMobileModule,
  InputPasswordModule,
  InputUsernameModule,
  InputSharedModule
];

@NgModule({
  imports: Modules,
  exports: Modules
})
export class InputModule { }
