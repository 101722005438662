<div class="reset_password_page account_inner_wrapper">
    <div mat-dialog-content>
        <!-- <ul class="tabs clearfix">
            <li>
                <a href="javascript:void(0)" class="active">Reset Password</a>
            </li>
        </ul> -->
        <!-- <a href="javascript:void(0)" mat-dialog-close class="modal-close"><img src="assets/icons/close.svg" alt=""></a> -->
        <!-- <div class="clearfix"></div> -->
        <h1 class="title-sec">Reset Password</h1>
        <div class="form-wrapper">
            <ng-container *ngIf="isForget;else resetTemplate">
                <ng-container *ngIf="!isTaskDone;else forgetDoneTemplate">
                    <p class="desc">Please enter your email address. You will receive a link to create a new password via email.
                    </p>
                    <div class="form-group">
                        <label for="email" class="form_label">Email</label>
                        <mat-form-field class="matInput_wrap">
                            <input id="email" matInput [formControl]="emailControl" [maxlength]="emailMaxLength" placeholder="Email" type="email" spellcheck="false" autocomplete="off">
                            <mat-error class="input_error">
                                <mat-icon matTooltip="{{ emailControl | validationError : 'email' }}" matTooltipClass="error_tooltip">error</mat-icon>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <button type="button" [nookButtonLoader]="emailControl.disabled" mat-flat-button [disabled]="emailControl.disabled" (click)="onForgotHandler()" color="primary" class="submit_btn">Get
                        new password</button>
                    <p class="para"> <a href="javascript:void(0)" (click)="onBackToLogin()">Go Back to Login</a></p>
                </ng-container>
                <ng-template #forgetDoneTemplate>
                    <p class="success-message">Please check your inbox, password reset link has been sent successfully to your email address.
                    </p>
                </ng-template>
            </ng-container>
            <ng-template #resetTemplate>
                <ng-container *ngIf="!isTaskDone; else resetDoneTemplate">
                    <form [formGroup]="resetForm">
                        <nook-input-password placeholder="Password" [control]="resetForm.controls['password']">
                        </nook-input-password>
                        <nook-input-password placeholder="Retype Password" [control]="resetForm.controls['confirmPassword']">
                        </nook-input-password>
                    </form>
                    <button type="button" [nookButtonLoader]="resetForm.disabled" mat-flat-button [disabled]="resetForm.disabled" (click)="onResetHandler()" color="primary" class="fullwidth">Update
                        password</button>
                </ng-container>
                <ng-template #resetDoneTemplate>
                    <p class="success-message">Password has been reset successfully!</p>
                    <p class="para"> <a href="javascript:void(0)" (click)="onBackToLogin()">Go Back to Login</a></p>
                </ng-template>
            </ng-template>
        </div>
        <!-- formsec_wrapper end -->
    </div>
    <!-- mat-dialog-content end -->
</div>
<!-- account_modal end -->