import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationErrorPipe } from './pipes/validation-error.pipe';
import { ErrorComponent } from './components/error/error.component';



@NgModule({
  declarations: [
    ValidationErrorPipe,
    ErrorComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ValidationErrorPipe,
    ErrorComponent
  ]
})
export class ValidationErrorModule { }
