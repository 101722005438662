<div class="page-search-filter" [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
	<div class="input-with-icon">
		<input matInput autocomplete="off" class="input-form-control" #searchSelectInput
			(keydown)="_handleKeydown($event)" (input)="onInputChange($event.target.value)"
			(blur)="onBlur($event.target.value)" [placeholder]="placeholderLabel" />

		<!-- <button mat-icon-button *ngIf="value" mat-icon-button aria-label="Clear" (click)="_reset(true)"
			class="button-form-control">
			<mat-icon>close</mat-icon>
		</button> -->
	</div>
</div>

<div *ngIf="noEntriesFoundLabel && value && _options?.length === 0" class="mat-select-search-no-entries-found">
	{{noEntriesFoundLabel}}
</div>