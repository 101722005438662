import { BANK_ROUTE } from './route.constants';

export const RCBC_BANK = {
    path: 'rcbc',
    shortname: 'RCBC',
    name: 'Rizal Commercial Banking Corporation',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/rcbc-bank/logo.png',
    iconUrl: '/assets/banking/rcbc-bank/icon.png',
    bannerUrl: '/assets/banking/rcbc-bank/banner.png'
};

export const CHINA_BANK = {
    path: 'chinabank',
    shortname: 'Chinabank',
    name: 'China Banking Corporation',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/china-bank/logo.jpg',
    iconUrl: '/assets/banking/china-bank/icon.jpg',
    bannerUrl: '/assets/banking/china-bank/banner.jpg'
};

export const BDO_BANK = {
    path: 'bdo',
    shortname: 'BDO',
    name: 'Banco de Oro',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/banco-de-oro/logo.png',
    iconUrl: '/assets/banking/banco-de-oro/icon.jpg',
    bannerUrl: '/assets/banking/banco-de-oro/banner.jpg'
};

// removed
export const CTBC_BANK = {
    path: 'ctbc',
    shortname: 'CTBC',
    name: 'CTBC Bank',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/ctbc-bank/logo.png',
    iconUrl: '/assets/banking/ctbc-bank/icon.jpg',
    bannerUrl: '/assets/banking/ctbc-bank/banner.jpg'
};

export const BOC_BANK = {
    path: 'boc',
    shortname: 'Bank of Commerce',
    name: 'Bank of Commerce',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/bank-of-commerce/logo.jpg',
    iconUrl: '/assets/banking/bank-of-commerce/icon.jpg',
    bannerUrl: '/assets/banking/bank-of-commerce/banner.jpg'
};

export const SECURITY_BANK = {
    path: 'security-bank',
    shortname: 'Security Bank',
    name: 'Security Bank',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/security-bank/logo.jpg',
    iconUrl: '/assets/banking/security-bank/icon.jpg',
    bannerUrl: '/assets/banking/security-bank/banner.jpg'
};

export const AUB_BANK = {
    path: 'aub',
    shortname: 'AUB',
    name: 'Asia United Bank',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/asia-united-bank/logo.jpg',
    iconUrl: '/assets/banking/asia-united-bank/icon.jpg',
    bannerUrl: '/assets/banking/asia-united-bank/banner.jpg'
};

export const BPI_BANK = {
    path: 'bpi',
    shortname: 'BPI Family Savings Bank',
    name: 'Bank of the Philippine Islands',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/bpi-bank/logo.jpg',
    iconUrl: '/assets/banking/bpi-bank/icon.jpg',
    bannerUrl: '/assets/banking/bpi-bank/banner.jpg'
};

export const FILIDIAN_BANK = {
    path: 'filidian',
    name: 'Filidian Bank',
    shortname: 'Filidian Bank',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/filidian-bank/logo.jpg',
    iconUrl: '/assets/banking/filidian-bank/icon.png',
    bannerUrl: '/assets/banking/filidian-bank/banner.jpg'
};

export const UNIONBANK = {
    path: 'unionbank',
    name: 'UnionBank',
    shortname: 'UnionBank',
    link: 'https://bank.nook.com.ph/home/',
    logoUrl: '/assets/banking/unionbank/logo.png',
    iconUrl: '/assets/banking/unionbank/icon.png',
    bannerUrl: '/assets/banking/unionbank/banner.jpg'
};

export const HSBCBANK ={
    path:'hsbcbank',
    name:'Hsbc Bank',
    shortname:'Hsbc Bank',
    link:'https://bank.nook.com.ph/home/',
    logoUrl:'/assets/banking/hsbcbank/hsbc-logo.png',
    iconUrl:'',
    bannerUrl:'/assets/banking/hsbcbank/HSBChomeloansbanner.jpg'
}

export const METROBANK ={
    path:'metrobank',
    name:'Metro Bank',
    shortname:'Metro Bank',
    link:'https://bank.nook.com.ph/home/',
    logoUrl:'/assets/banking/metrobank/Metrobank_bank_page_logo.png',
    iconUrl:'',
    bannerUrl:'/assets/banking/metrobank/Metrobankbanner-car-and-home-loan-promo-extended.jfif'
}

export const RCFBANK ={
    path:'rcf',
    name:'Right choice Bank',
    shortname:'Right choice Bank',
    link:'https://bank.nook.com.ph/home/',
    logoUrl:'/assets/banking/rightchoicebank/RCF_logo.png',
    iconUrl:'/assets/banking/rightchoicebank/RCF_logo.png',
    bannerUrl:'/assets/banking/rightchoicebank/Right_Choice_Bank_Page_Banner_Resized.jpg'
}

export const WELCOMEBANK ={
    path:'wb',
    name:'Welcome Bank',
    shortname:'Welcome Bank',
    link:'https://bank.nook.com.ph/home/',
    logoUrl:'/assets/banking/welcomebank/WBP6_Bank_Page_Logo.png',
    iconUrl:'/assets/banking/welcomebank/WBP6_Bank_Page_Logo.png',
    bannerUrl:'/assets/banking/welcomebank/Welcome_Bank_Page_Banner.jpg'
}


export const BANKS = [AUB_BANK, FILIDIAN_BANK, BDO_BANK, RCBC_BANK, BOC_BANK, SECURITY_BANK, BPI_BANK, UNIONBANK, CHINA_BANK,WELCOMEBANK,RCFBANK];
export function formatBanks() {
    return BANKS.map((bank) => ({ ...bank, url: BANK_ROUTE.url(bank.path) }));
}
export function formatBank() {
    return BANKS.sort(() => .5 - Math.random()).map((bank) => ({ ...bank, url: BANK_ROUTE.url(bank.path) }));
}
