import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './view/auth.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthService } from './services/auth.service';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputModule } from 'src/app/common/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonLoaderModule } from 'src/app/common/button-loader/button-loader.module';
import { MatButtonModule } from '@angular/material/button';
import { PasswordComponent } from './components/password/password.component';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectSearchModule } from 'src/app/common/mat-select-search/mat-select-search.module';
import { OnlyNumbersModule } from 'src/app/directives/only-numbers/only-numbers.module';
import { PreQualificationService } from '../pre-qualification/services/pre-qualification.service';
import { RouterModule, Routes } from '@angular/router';
import { FORGOT_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE, RESET_ROUTE } from 'src/app/constants';


const inrRoutes: Routes = [
  { path: '', component: AuthComponent,children:[
    {path: LOGIN_ROUTE.path,component:LoginComponent},
    {path: REGISTER_ROUTE.path,component:RegisterComponent},
    {path: FORGOT_ROUTE.path,component:PasswordComponent},
    {path: RESET_ROUTE.path,component:PasswordComponent}
  ] }
];

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    PasswordComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    InputModule,
    MatIconModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectSearchModule,
    OnlyNumbersModule,
    ButtonLoaderModule,
    MatButtonModule,
    NgxMaskModule.forRoot(),
    RouterModule.forChild(inrRoutes)
  ],
  exports: [
    MatDialogModule,
    MatButtonModule
  ],
  providers: [AuthService, PreQualificationService],
  // entryComponents: [AuthComponent]
})
export class AuthModule { }
