import { Component, OnInit, ChangeDetectorRef, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/constants';
// import { PopupService } from '@popup';
import { PASSWORD, EMAIL } from 'src/app/constants/form.constants';
import { ProfileService } from '@profile';
import { TabName } from '../../constants/auth.constants';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'nook-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
  token: any;
  get isForget(): boolean {
    return this.actRoute.snapshot.queryParams.reset ? false:true;
  }
  isTaskDone = false;
  emailControl: FormControl = new FormControl(null, [Validators.required, ...CustomValidators.username]);
  resetForm: FormGroup;
  readonly emailMaxLength = EMAIL.maxLength;
  readonly passwordMaxLength = PASSWORD.maxLength;
  constructor(
    fb: FormBuilder,
    private $profile: ProfileService,
    private $cdRef: ChangeDetectorRef,
    private $router:Router,
    private actRoute: ActivatedRoute
    // private $dialogRef: MatDialogRef<any>
  ) {
    // this.$dialogRef.updateSize('360px');
    this.token = this.actRoute.snapshot.queryParams.reset;
    this.resetForm = fb.group({
      password: [null, [Validators.required, ...CustomValidators.password]],
      confirmPassword: [null, [Validators.required, ...CustomValidators.password, CustomValidators.match('password')]]
    });
    const { password, confirmPassword } = this.resetForm.controls;
    password.valueChanges.subscribe(() => {
      confirmPassword.updateValueAndValidity();
      this.$cdRef.markForCheck();
    });
  }

  ngOnInit() {
  }
  onForgotHandler() {
    if (this.emailControl.valid && this.emailControl.enabled) {
      const email = this.emailControl.value;
      this.emailControl.disable();
      this.$profile.forgetPassword(email).then((status: boolean) => {
        if (status) {
          this.isTaskDone = true;
          // this.dialogRef.close();
        } else {
          this.emailControl.enable();
        }
      }).catch(() => {
        // this.$popup
        this.emailControl.enable();
      }).finally(() => {
        this.$cdRef.markForCheck();
      });
    } else if (this.emailControl.enabled) {
      this.emailControl.markAsTouched();
      this.$cdRef.markForCheck();
    }
  }
  onResetHandler() {
    if (this.resetForm.valid && this.resetForm.enabled) {
      const { password } = this.resetForm.value;
      this.resetForm.disable();
      this.$profile.resetPassword(this.token, password).then((status: boolean) => {
        if (status) {
          this.isTaskDone = true;
        } else {
          this.resetForm.enable();
        }
      }).catch(() => {
        // this.$popup
        this.resetForm.enable();
      });
    } else if (this.resetForm.enabled) {
      this.resetForm.markAllAsTouched();
      this.$cdRef.markForCheck();
    }
  }
  onBackToLogin() {
    // this.changeTab.emit('LOGIN');
    this.$router.navigate(['public/login'])
  }
  ngOnDestroy() {
    // this.$dialogRef.updateSize('720px');
  }
}
