import { Injectable } from '@angular/core';
import { PopupComponent } from '../components/popup.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class PopupService {

  constructor(private snackBar: MatSnackBar) {
    // this.warn('Warning Error');
  }
  open(message: string, type: 'WARNING' | 'ERROR' | 'SUCCESS' | 'DEFAULT' = 'DEFAULT', config: MatSnackBarConfig = {}) {
    
    config = {
      data: { message, type },
      duration: 3000,
      ...config
    };
    
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile) {
      config.verticalPosition = 'top';
      config.horizontalPosition = 'right';
    }

    let ur = document.URL;
    let a = ur.split('/');
    if(a[3] == 'pre-qualification' && message != 'Please Login to continue.'){
      this.snackBar.openFromComponent(PopupComponent, config);
    }
  }
  warn(message: string, config: MatSnackBarConfig = {}) {
    this.open(message, 'WARNING', config);
  }
  error(message: string, config: MatSnackBarConfig = {}) {
    this.open(message, 'ERROR', config);
  }
  success(message: string, config: MatSnackBarConfig = {}) {
    this.open(message, 'SUCCESS', config);
  }
  show(message: string, config: MatSnackBarConfig = {}) {
    this.open(message, 'DEFAULT', config);
  }
}
