import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'validationError',
  pure: false
})
export class ValidationErrorPipe implements PipeTransform {

  transform(control: AbstractControl, fieldName: string = 'Field'): any {
    // Custom Error messages
    if (control.hasError('custom')) {
      return control.errors.custom;
    }

    // Mask Error
    if (control.hasError('Mask error')) {
      return `${fieldName} is not valid.`;
    }

    if (control.hasError('mismatch')) {
      return `passwords do not match`;
    }
    // Pattern Error
    // if (control.hasError('pattern')) {
    //   return `${fieldName} ${PATTERN_REGEX.message(control.errors.pattern.requiredPattern)}`;
    // }

    // Required Error
    if (control.hasError('required')) {
      return `${fieldName} is required.`;
    }

    if (control.hasError('pattern')) {
      return `${fieldName} is not valid.`;
    }
    // Email Error
    if (control.hasError('email')) {
      return `${fieldName} is not valid.`;
    }

    if( control.hasError('disposable')){
      return `${fieldName} is not valid.`;
    }
    // Number Error
    if (control.hasError('number')) {
      return `${fieldName} is not a valid number.`;
    }

    // Min Length Error
    if (control.hasError('minlength')) {
      return `${fieldName} length should be at least ${control.getError('minlength').requiredLength}`;
    }

    // Max Length Error
    if (control.hasError('maxlength')) {
      return `${fieldName} length should be less than ${control.getError('maxlength').requiredLength}`;
    }

    // Min Error
    if (control.hasError('min')) {
      return `${fieldName} should be equal or greater than ${control.getError('min').min}`;
    }

    // Max Error
    if (control.hasError('max')) {
      return `${fieldName} should be equal or less than ${control.getError('max').max}`;
    }

    if(control.hasError('amountLess')){
      return `No loan amount must be lower than Php 650,000 to be submitted`;
    }


    // Confirm Password match
    if (control.hasError('compare')) {
      let message = null;
      const error = control.getError('compare');
      switch (error.type) {
        case 'MATCH': {
          message = `${fieldName} do not match with ${error.field}`;
          break;
        }
        case 'LOWER': {
          message = `${fieldName} should be lower than ${error.field}`;
          break;
        }
        case 'HIGHER': {
          message = `${fieldName} should be higher than ${error.field}`;
          break;
        }
        default: {
          message = `Comparison failed with field ${error.field}`;
          break;
        }
      }
      return message;
    }

    // White space
    if (control.hasError('whiteSpace')) {
      return `${fieldName} contains only white spaces.`;
    }

    // valid url
    if (control.hasError('url')) {
      return `${fieldName} is not a valid url.`;
    }
  }

}
