import { Component, OnInit, Input } from '@angular/core';
import { SocialShareService, Network } from 'src/app/services/social-share/social-share.service';


@Component({
  selector: 'nook-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit {
  @Input() isShareUrl = false;
  constructor(private socialShare: SocialShareService) { }

  ngOnInit() {
  }
  onFacebookShare() {
    this.onShareHandler(Network.FACEBOOK);
  }
  onTwitterShare() {
    this.onShareHandler(Network.TWITTER);
  }
  onInstagramShare() {
    this.onShareHandler(Network.INSTAGRAM);
  }
  onShareHandler(network: Network) {
    if (this.isShareUrl) {
      this.socialShare.share(network);
    } else {
      this.socialShare.open(network);
    }
  }
}
