import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TokenService } from '@token';
import { DASHBOARD_ROUTE } from 'src/app/constants';
import { TabName } from '../constants/auth.constants';

@Component({
  selector: 'nook-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  activeTab: TabName = 'LOGIN';
  get isLoginTab(): boolean {
    return this.activeTab === 'LOGIN';
  }
  get isRegisterTab(): boolean {
    return this.activeTab === 'REGISTER';
  }
  get isPasswordTab(): boolean {
    return !this.isLoginTab && !this.isRegisterTab;
  }
  constructor(
    // public dialogRef: MatDialogRef<AuthComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: { tab: TabName, token?: string },
    public token: TokenService,
    private $router:Router
  ) {
    // this.activeTab = data.tab;
  }

  ngOnInit(): void {
    if(this.token.hasValue){
      this.$router.navigateByUrl(DASHBOARD_ROUTE.url);
    }
  }

}
