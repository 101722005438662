<div class="signup_popup_field">
    <h1 class="title-sec">Create your Nook account</h1>
    <form class="form-wrapper" [formGroup]="userForm" *ngIf="!isUserVerified; else profileFormTemplate">

        <!-- <mat-form-field class="matInput_wrap">
            <input matInput formControlName="userName" [mask]="userNameMask" [maxlength]="userNameMaxLength"
                placeholder="Username" type="text" spellcheck="false" autocomplete="off">
            <mat-error class="input_error">
                <mat-icon matTooltip="{{userForm.controls['userName'] | validationError : 'Username'}}"
                    matTooltipClass="error_tooltip">error</mat-icon>
            </mat-error>
        </mat-form-field> -->

        <div class="form_field">
            <label for="email" class="form_label">Email</label>
            <mat-form-field class="matInput_wrap">
                <input id="email" matInput formControlName="email" placeholder="Email" [maxlength]="emailMaxLength"
                    type="email" spellcheck="false" autocomplete="off">
                <mat-icon class="info-icon" (click)="infoTooltip.show()" #infoTooltip="matTooltip"
                    matTooltip="Choose a permanent Email address where all account and loan notifications will be sent to"
                    matTooltipClass="custom-tooltip">info</mat-icon>
                <mat-error class="input_error">
                    <mat-icon matTooltip="{{userForm.controls['email'] | validationError : 'Email'}}"
                        matTooltipClass="error_tooltip">error</mat-icon>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form_field">
            <label for="password" class="form_label">Password</label>
            <nook-input-password [control]="userForm.controls['password']"></nook-input-password>
        </div>
        <div class="form_field">
            <label for="confirmPassword" class="form_label">Confirm Password</label>
            <nook-input-password [control]="userForm.controls['confirmPassword']" placeholder="Retype Password">
            </nook-input-password>
        </div>
        <div class="clearfix"></div>
        <button type="button" mat-flat-button [nookButtonLoader]="isProcessing" [disabled]="isProcessing"
            color="primary" (click)="onVerifyUserHandler()" class="submit_btn">
            Next
        </button>
        <p class="para">
            <a href="javascript:void(0)" (click)="redirectToLogin()">Already have an account? Log in</a>
        </p>
        <!-- <p class="para"><a href="javascript:void(0)">How to create an account?</a></p> -->
    </form>
    <ng-template #profileFormTemplate>
        <form class="form-wrapper" [formGroup]="registerForm">
            <div class="form_field">
                <label for="firstName" class="form_label">First Name</label>
                <mat-form-field class="matInput_wrap">
                    <input id="firstName" matInput formControlName="firstName" [maxlength]="nameMaxLength"
                        placeholder="First Name" type="text" spellcheck="false" autocomplete="off">
                    <mat-error>
                        <mat-icon [matTooltip]="registerForm.controls['firstName'] | validationError : 'First name'"
                            matTooltipClass="error_tooltip">error</mat-icon>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form_field">
                <label for="lastName" class="form_label">Last Name</label>
                <mat-form-field class="matInput_wrap">
                    <input id="lastName" matInput formControlName="lastName" [maxlength]="nameMaxLength"
                        placeholder="Last Name" type="text" spellcheck="false" autocomplete="off">
                    <mat-error>
                        <mat-icon [matTooltip]="registerForm.controls['lastName'] | validationError : 'Last name'"
                            matTooltipClass="error_tooltip">error</mat-icon>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form_field">
                <label for="phoneNumber" class="form_label">Phone Number</label>
                <div class="flex-wrapper">
                    <div class="country-code-form-field">
                        <mat-form-field class="matInput_wrap">
                            <mat-select panelClass="countryCodePanel" placeholder="+63" class="country_code"
                                formControlName="countryCode" (selectionChange)="onMobileInput()">
                                <mat-select-trigger *ngIf="countryCode?.value">
                                    {{countryCode?.value || 'Code'}}
                                </mat-select-trigger>
                                <mat-select-search [formControl]="codeFilterCtrl"></mat-select-search>
                                <!-- <mat-option disabled [value]="null">Select country</mat-option> -->
                                <mat-option *ngFor="let country_code of filteredcodes | async"
                                    [value]="country_code.country_code">
                                    <img class="img-country-flag" [src]="country_code?.flag" alt="">
                                    {{country_code.country_code}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                <mat-icon
                                    [matTooltip]="registerForm.controls['countryCode'] | validationError : 'Country Code'"
                                    matTooltipClass="error_tooltip">error</mat-icon>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="phone-form-field">
                        <mat-form-field class="matInput_wrap">
                            <input (keyup)="onMobileChange($event)" id="phoneNumber" matInput formControlName="phoneNumber" [maxlength]="phoneMaxLength"
                                placeholder="919xxxxxxx" autocomplete="off" [maxLength]="maxLength?'10':'15'" OnlyNumbers>
                            <mat-error>
                                <mat-icon [matTooltip]="registerForm.controls['phoneNumber'] | validationError : 'Mobile'"
                                    matTooltipClass="error_tooltip">error</mat-icon>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="policy_wrapper">
                <mat-checkbox [formControl]="policyControl">
                    I have read and understand the
                    <a href="https://www.nook.com.ph/terms/" target="_blank">Terms of Use</a>
                    <br />and
                    <a href="https://www.nook.com.ph/privacy/" target="_blank">Privacy Policy</a>.
                </mat-checkbox>
            </div>
            <div class="clearfix"></div>
            <button type="button" mat-flat-button [nookButtonLoader]="isProcessing"
                [disabled]="isProcessing || !policyControl.value" color="primary" (click)="onRegisterHandler()"
                class="submit_btn">
                Register
            </button>
            <p class="para">
                <a href="javascript:void(0)" (click)="redirectToLogin()">Already have an account? Log in</a>
            </p>
        </form>
    </ng-template>
</div>