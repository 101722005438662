import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environment';
import { BREADCRUMB } from 'src/app/constants/breadcrumb.constants';

@Component({
  selector: 'nook-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumb: string;
  @Input() type: string;
  routes = [{ key: 'Home', value: environment.nookUrl, isRoute:false }];
  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      if (this.breadcrumb && BREADCRUMB[this.breadcrumb]) {
        this.routes.push(...BREADCRUMB[this.breadcrumb]);
      }
    }, 200);
  }

  routeToURl(url){
    location.href = url;
  }

}
