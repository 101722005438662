
export const USER_GENDERS = {
    MALE: {
        label: 'Male',
        value: 'MALE'
    },
    FEMALE: {
        label: 'Female',
        value: 'FEMALE'
    },
    // OTHER: {
    //     label: 'Other',
    //     value: 'OTHER'
    // },
};
