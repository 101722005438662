import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import {
  CREDIT_CARD_STATUS,
  CustomValidators,
  LOAN_PROPERTY_STATUS,
  NATIONALITY,
} from "src/app/constants";
import { GlobalService } from "src/app/services/global/global.service";
import { PRE_LISTING_ROUTE } from "../constants";
import {
  LOAN_INFO_ROUTE,
  LOAN_OTHER_ROUTE,
  LOAN_PROPERTY_ROUTE,
  LOAN_WORK_ROUTE,
} from "../pages/pre-qualification-process/constants";

const storekey = window.btoa("Form-Data");

@Injectable({
  providedIn: "root",
})
export class PreQualificationService {
  // private $subscriptions: Subscription[] = [];
  private $summary: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly summaryRoute = this.$summary.asObservable();
  // pre-qualification form
  private $pqForm: FormGroup;
  fetchPreQualifications: any;
  preQualificationId: any;
  age: any;
  profileData: any;
  partnerData: any;
  get formGroup(): FormGroup {
    return this.$pqForm;
  }
  get propertyGroup(): FormGroup {
    return this.$pqForm.get("property") as FormGroup;
  }
  get workGroup(): FormGroup {
    return this.$pqForm.get("employmentInfo") as FormGroup;
  }
  get otherGroup(): FormGroup {
    return this.$pqForm.get("other") as FormGroup;
  }
  get loanGroup(): FormGroup {
    return this.$pqForm.get("loan") as FormGroup;
  }
  constructor(
    fb: FormBuilder,
    private $router: Router,
    private $http: HttpClient,
    private $global: GlobalService
  ) {
    this.createForm(fb);
  }
  createForm(fb: FormBuilder) {
    const { property, employmentInfo, other, loan, id, loanType } =
      this.retrieveValue || ({} as any);
    const {
      dob,
      nationality,
      localVisa,
      creditCard,
      prevLoans,
      otherIncome,
      married,
      coBorrower,
    } = other || ({} as any);
    this.$pqForm = fb.group({
      property: fb.group({
        value: [
          (property && property.value) || "",
          CustomValidators.propertyLoanValue.concat(Validators.required),
        ],
        loanType: [
          (loanType && loanType.value) ||
            localStorage.getItem("loanTypeSelected"),
          Validators.required,
        ],
        type: [(property && property.type) || "", Validators.required],
        status: [(property && property.status) || "", Validators.required],
        developer: [
          {
            value: (property && property.developer) || "",
            disabled: true,
          },
          Validators.required,
        ],
      }),
      employmentInfo: fb.group({
        type: [
          (employmentInfo && employmentInfo.type) || "",
          Validators.required,
        ],
        rank: [(employmentInfo && employmentInfo.rank) || ""],
        tenure: [
          (employmentInfo && employmentInfo.tenure) || null,
          Validators.required,
        ],
        income: [
          (employmentInfo && employmentInfo.income) || null,
          CustomValidators.income.concat(Validators.required),
        ],
      }),
      other: fb.group({
        dob: fb.group({
          month: [(dob && dob.month) || null, Validators.required],
          date: [(dob && dob.date) || null, Validators.required],
          year: [(dob && dob.year) || null, Validators.required],
        }),
        nationality: [nationality || null, Validators.required],
        localVisa: [
          typeof localVisa === "boolean" ? localVisa : null,
          Validators.required,
        ],
        creditCard: fb.group({
          status: [
            (creditCard && creditCard.status) || null,
            Validators.required,
          ],
          limit: [
            (creditCard && creditCard.limit) || null,
            Validators.required,
          ],
          cancelled: [
            creditCard && typeof creditCard.cancelled === "boolean"
              ? creditCard.cancelled
              : null,
            Validators.required,
          ],
        }),
        prevLoans: fb.group({
          status: [
            prevLoans && typeof prevLoans.status === "boolean"
              ? prevLoans.status
              : false,
            Validators.required,
          ],
          monthlyTotal: [
            (prevLoans && prevLoans.monthlyTotal) || null,
            Validators.required,
          ],
          remainingTotal: [
            (prevLoans && prevLoans.remainingTotal) || null,
            Validators.required,
          ],
        }),
        otherIncome: fb.group({
          status: [
            otherIncome && typeof otherIncome.status === "boolean"
              ? otherIncome.status
              : false,
            Validators.required,
          ],
          monthlyIncome: [
            (otherIncome && otherIncome.monthlyIncome) || null,
            Validators.required,
          ],
        }),
        married: fb.group({
          status: [
            married && typeof married.status === "boolean"
              ? married.status
              : false,
            Validators.required,
          ],
          spouseMonthlyIncome: [
            (married && married.spouseMonthlyIncome) || null,
            Validators.required,
          ],
        }),
        coBorrower: fb.group({
          status: [
            coBorrower && typeof coBorrower.status === "boolean"
              ? coBorrower.status
              : false,
            Validators.required,
          ],
          coBorrowerMonthlyIncome: [
            (coBorrower && coBorrower.coBorrowerMonthlyIncome) || null,
            Validators.required,
          ],
        }),
      }),
      loan: fb.group({
        type: [
          (loan && loan.type) ||
          localStorage.getItem("loanTypeSelected") === "PERSONAL"
            ? "PURCHASE_OF_PROPERTY"
            : null,
          Validators.required,
        ],
        termLength: [
          (loan && loan.termLength) ||
          localStorage.getItem("loanTypeSelected") === "PERSONAL"
            ? "Months"
            : "Years",
          Validators.required,
        ],
        term: [(loan && loan.term) || null, Validators.required],
        percent: [
          (loan && loan.percent) || 80,
          [Validators.required, Validators.min(1)],
        ],
        amount: [(loan && loan.amount) || null, Validators.required],
        fixingPeriod: [(loan && loan.fixingPeriod) || 1, Validators.required],
      }),
    });
    this.$developerHandler();
    this.$dobHandler();
    this.$creditCardHandler();
    this.$nationalityHandler();
    this.$previousLoansHandler();
    this.$loanAmountHandler();
    this.$otherIncomeHandler();
    this.$marriedHandler();
    this.$coBorrowerHandler();
  }
  private $loanAmountHandler() {
    const property: FormControl = this.propertyGroup.controls
      .value as FormControl;
    const { percent, amount } = this.loanGroup.controls;
    percent.valueChanges.subscribe((value: number) => {
      if (property.value || property.value === 0) {
        amount.patchValue((value * property.value) / 100);
      }
    });
    percent.updateValueAndValidity();
    property.valueChanges.subscribe((value: number) => {
      if (percent.value || percent.value === 0) {
        amount.patchValue((value * percent.value) / 100);
      }
    });
    property.updateValueAndValidity();
  }
  private $developerHandler() {
    const { status, developer } = this.propertyGroup.controls;
    status.valueChanges.subscribe((value: string) => {
      const shoulHaveDeveloper = [
        LOAN_PROPERTY_STATUS.PRE_SELLING.value,
        LOAN_PROPERTY_STATUS.READY_FOR_OCCUPANCY.value,
      ].some((item) => item === value);
      if (shoulHaveDeveloper) {
        developer.enable();
      } else {
        developer.disable();
      }
    });
    status.updateValueAndValidity();
  }

  private $dobHandler() {
    const { dob } = this.otherGroup.controls;
    const { month, date, year } = (dob as FormGroup).controls;
    // update dates according to month
    month.valueChanges.subscribe((monthIndex) => {
      if (monthIndex !== null) {
        const maxDate = new Date(year.value || 2000, monthIndex).getMaxDate();
        if (date.value > maxDate) {
          date.reset();
        }
      }
    });
    // update dates according to year
    year.valueChanges.subscribe((value) => {
      if (year !== null) {
        const maxDate = new Date(value, month.value || 0).getMaxDate();
        if (date.value > maxDate) {
          date.reset();
        }
      }
    });
    month.updateValueAndValidity();
    year.updateValueAndValidity();
  }
  private $nationalityHandler() {
    const { nationality, localVisa } = this.otherGroup.controls;
    nationality.valueChanges.subscribe((value) => {
      if (value === NATIONALITY.FOREIGNER.value) {
        localVisa.enable();
      } else {
        localVisa.disable();
      }
    });
    nationality.updateValueAndValidity();
  }
  private $creditCardHandler() {
    const { creditCard } = this.otherGroup.controls;
    const { status, limit, cancelled } = (creditCard as FormGroup).controls;
    status.valueChanges.subscribe((value) => {
      if (value === CREDIT_CARD_STATUS.NO.value) {
        limit.disable();
        cancelled.disable();
      } else {
        limit.enable();
        cancelled.enable();
      }
    });
    status.updateValueAndValidity();
  }
  private $previousLoansHandler() {
    const { prevLoans } = this.otherGroup.controls;
    const { status, monthlyTotal, remainingTotal } = (prevLoans as FormGroup)
      .controls;
    status.valueChanges.subscribe((value) => {
      if (value) {
        monthlyTotal.enable();
        remainingTotal.enable();
      } else {
        monthlyTotal.disable();
        remainingTotal.disable();
      }
    });
    status.updateValueAndValidity();
  }
  private $otherIncomeHandler() {
    const { otherIncome } = this.otherGroup.controls;
    const { status, monthlyIncome } = (otherIncome as FormGroup).controls;
    status.valueChanges.subscribe((value) => {
      if (value) {
        monthlyIncome.enable();
      } else {
        monthlyIncome.disable();
      }
    });
    status.updateValueAndValidity();
  }
  private $marriedHandler() {
    const { married } = this.otherGroup.controls;
    const { status, spouseMonthlyIncome } = (married as FormGroup).controls;
    status.valueChanges.subscribe((value) => {
      if (value) {
        spouseMonthlyIncome.enable();
      } else {
        spouseMonthlyIncome.disable();
      }
    });
    status.updateValueAndValidity();
  }
  private $coBorrowerHandler() {
    const { coBorrower } = this.otherGroup.controls;
    const { status, coBorrowerMonthlyIncome } = (coBorrower as FormGroup)
      .controls;
    status.valueChanges.subscribe((value) => {
      if (value) {
        coBorrowerMonthlyIncome.enable();
      } else {
        coBorrowerMonthlyIncome.disable();
      }
    });
    status.updateValueAndValidity();
  }

  set isSummaryRoute(value: boolean) {
    this.$summary.next(value);
  }

  navigateToInvalidStep() {
    const step = [
      {
        group: "propertyGroup",
        url: LOAN_PROPERTY_ROUTE.url,
      },
      {
        group: "workGroup",
        url: LOAN_WORK_ROUTE.url,
      },
      {
        group: "otherGroup",
        url: LOAN_OTHER_ROUTE.url,
      },
      {
        group: "loanGroup",
        url: LOAN_INFO_ROUTE.url,
      },
    ].find((item) => {
      const group: FormGroup = this[item.group];
      return group.invalid;
    });
    if (step) {
      this.$router.navigateByUrl(step.url);
    }
  }
  // storing data in localstorage
  storeValue() {
    const data = this.$global.encodeData(this.$pqForm.value);
    localStorage.setItem(storekey, data);
  }

  get retrieveValue() {
    const data = localStorage.getItem(storekey);
    if (data) {
      try {
        let decodedData = this.$global.decodeData(data);
        return decodedData;
      } catch (err) {
        this.clearValue();
      }
    }
  }
  clearValue() {
    localStorage.removeItem(storekey);
  }
  // navigation
  async onCompleteForm(refferalId?) {
    if (this.formGroup.valid && this.formGroup.enabled) {
      const application = this.$global.encodeData(this.formGroup.value);
      this.$router.navigate([PRE_LISTING_ROUTE.url], {
        queryParams: { application, refferalId },
      });
    } else if (this.formGroup.enabled) {
      this.formGroup.markAllAsTouched();
      return Promise.reject("invalid form");
    }
  }

  async fetchSchemes() {
    const value = window.deepCopy(this.formGroup.value);
    const dob = value.other.dob;
    delete value.other.dob;
    value.other.age =
      new Date().getFullYear() -
      new Date(dob.year, dob.month, dob.date).getFullYear();
    delete value.employmentInfo.rank;
    const resp = await this.$http
      .patch<Api.Response<any>>(`~/user/loan/pre-application`, value)
      .toPromise();
    return resp.data;
  }

  get preQaulificationsid() {
    return this.preQualificationId;
  }

  set preQaulificationsid(data) {
    this.preQualificationId = data;
  }

  async onSavePreQualifications(data) {
    const partnerEncryptedData = this.$global.partnerData;
    if (partnerEncryptedData) {
      data.partnerName = partnerEncryptedData.name;
      data.partnerId = partnerEncryptedData.shortId;
    }
    if (!data.partnerName || !data.partnerId) {
      delete data.partnerName;
      delete data.partnerId;
    }
    const apiData = JSON.parse(JSON.stringify(data));
    delete apiData.preQualificationId;
    const dob = apiData.other.dob;
    apiData.other.age =
      new Date().getFullYear() -
      new Date(dob.year, dob.month, dob.date).getFullYear();
    let date = new Date(dob.year, dob.month, dob.date);
    // if(!localStorage.getItem('loadEdited')) delete apiData.preQaulificationsId;
    delete apiData.employmentInfo.rank;
    apiData.other.dob = `${
      date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}`;
    const resp = this.$http
      .post<Api.Response<any>>(`~/user/prequalification`, apiData)
      .toPromise();
    return resp;
  }

  async onCheckPartnerHandler(id) {
    const resp = this.$http.get(`~/user/partner/${id}`).toPromise();
    return resp;
  }
}
