import { PRE_PROCESS_ROUTE } from '../../../constants/route.constants';


export const LOAN_PROPERTY_ROUTE = {
    path: 'property-information',
    get url(): string {
        return `${PRE_PROCESS_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_WORK_ROUTE = {
    path: 'work-information',
    get url(): string {
        return `${PRE_PROCESS_ROUTE.url}/${this.path}`;
    }
};


export const LOAN_OTHER_ROUTE = {
    path: 'other-information',
    get url(): string {
        return `${PRE_PROCESS_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_INFO_ROUTE = {
    path: 'loan-information',
    get url(): string {
        return `${PRE_PROCESS_ROUTE.url}/${this.path}`;
    }
};

