import { environment } from '@environment';
import {   
    // LAYOUT_ROUTE,
    PROFILE_ROUTE,
    LOAN_REFERRALS_ROUTE,
    HELP_CENTER_ROUTE,
    CONSTANT_ROUTE,
} from './route.constants';

export const BREADCRUMB = {
    dashboard: [
        { key: 'Dashboard', value: '' },
    ],
    
    'about-story': [
        { key: 'About Us', value: CONSTANT_ROUTE.aboutUrl ,isRoute:true },
        { key: 'Our Story', value: '' },
    ],
    'about-ceo': [
        { key: 'About Us', value: CONSTANT_ROUTE.aboutUrl,isRoute:true },
        { key: 'Our CEO', value: '' },
    ],
   
    'agent-details': [
        { key: 'Agents', value: '' },
        { key: 'Agents details', value: '' }
    ],
    
    profile: [
        { key: 'Profile', value: PROFILE_ROUTE.url ,isRoute:true}
    ],
    
    'loan-referrals': [
        { key: 'Loan-Referrals', value: LOAN_REFERRALS_ROUTE.url ,isRoute:true},
    ],
   
    'help-center': [
        { key: 'help-center', value: CONSTANT_ROUTE.helpCenter,isRoute:false }],

    'pre-qualification': [
        { key: 'Loan', value: `${environment.nookUrl}`,isRoute:false },
        { key: 'Pre Qualification', value: `${environment.nookUrl}` ,isRoute:false},
        { key: 'Process', value: '' }
    ],
    'pre-qualification-listing': [
        { key: 'Loan', value: `${environment.nookUrl}`,isRoute:false },
        { key: 'Pre Qualification', value: `${environment.nookUrl}` ,isRoute:false},
        { key: 'Listing', value: '' }
    ],
    'terms': [
        { key: 'Terms & Conditions', value: '' }
    ],
    'privacy': [
        { key: 'Privacy Policy', value: '' }
    ],
    'site-map': [
        { key: 'Site Map', value: '' }
    ]
};

