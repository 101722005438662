import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PopupService } from '@popup';
import { TokenService } from '@token';
import { MESSAGES } from 'src/app/constants/message.constants';
// import { LoaderService } from '@loader';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    // private $loader: LoaderService,
    private $token: TokenService,
    private $profile: ProfileService,
    private $popup: PopupService,
    private $router: Router,
  ) { }
  // intercept all http requests
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!navigator.onLine) {
      this.$popup.open(MESSAGES.OFFLINE, 'ERROR', {duration: 4000});
      return EMPTY;
    }
    // this._loader.markAsLoading();
    let url = req.url;
    if (url.charAt(0) === '~') {
      url = `${environment.apiUrl}${url.substr(1)}`;
    }
    // set headers
    const headers: {[key: string]: any} = {};
    // headers.authorization = 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlMjU0NDlkMjg5ZDQ1MTYwMjUzNjY1YSIsInRva2VuVHlwZSI6IkFHRU5UIiwidGltZXN0YW1wIjoxNTg2MzMzNjEyMTMyLCJpYXQiOjE1ODYzMzM2MTJ9.TuijpuTcs4PQjGpdCqKM2KFq7NIYsXLKER2VaHClYZI';
    if (this.$token.hasValue) {
      headers.authorization = `bearer ${this.$token.value}`;
    } else {
      headers.authorization = 'bearer dG9raWFwcDp0b2tpYXBw';
    }
    return next.handle(req.clone({
      setHeaders: headers,
      url
    })).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // this._loader.completeLoading();
        }
        // return throwError(error);
      }, event => {
        if (event instanceof HttpErrorResponse) {

          // this._loader.completeLoading();
          if (event.status === 401 && this.$token.hasValue) {
            this.$profile.clear();
            this.$popup.open(MESSAGES.ERROR.$401, 'ERROR', {duration: 3000});
            location.href = environment.nookUrl
          } else if (event.status === 504) {
            this.$popup.open(MESSAGES.ERROR.$504, 'ERROR', {duration: 3000});
          } else {
            if(url == `${environment.apiUrl}/user/login/status` && !this.$token.hasValue){
              localStorage.removeItem('deviceId');
              return
            }
            this.$popup.open(event.error.message || MESSAGES.ERROR.UNKNOWN, 'ERROR', {duration: 3000});
          }
        }
      })
    );
  }
}
