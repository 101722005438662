

export function cleanData(value: object) {
    // Deep Copy object to so that actual does not changes
    const newValue = JSON.parse(JSON.stringify(value));
    // iterate newValue and remove empty fields
    (function isEmpty(data: any): boolean {
        if (typeof data === 'object' && data !== null) {
            if (Array.isArray(data)) {
                const indexToRemove: number[] = [];
                data.forEach((item: any, index: number) => {
                    if (isEmpty(item)) {
                        indexToRemove.unshift(index);
                    }
                });
                indexToRemove.forEach((item) => {
                    data.splice(item, 1);
                });
            } else {
                Object.keys(data).forEach(key => {
                    if (isEmpty(data[key])) {
                        delete data[key];
                    }
                });
                if (!Object.keys(data).length) {
                    return true;
                }
            }
        }
        return data === null || data === undefined || data === '';
    })(newValue);
    return newValue;
}
