import { PRE_QUALIFICATION_ROUTE } from 'src/app/constants/route.constants';


export const PRE_HOME_ROUTE = {
    path: '',
    get url(): string {
        return `${PRE_QUALIFICATION_ROUTE.url}/${this.path}`;
    }
};

export const PRE_PROCESS_ROUTE = {
    path: 'process',
    get url(): string {
        return `${PRE_QUALIFICATION_ROUTE.url}/${this.path}`;
    }
};


export const PRE_LISTING_ROUTE = {
    path: 'listing',
    get url(): string {
        return `${PRE_QUALIFICATION_ROUTE.url}/${this.path}`;
    }
};

export const PRE_SUMMARY_ROUTE = {
    path: 'summary',
    get url(): string {
        return `${PRE_PROCESS_ROUTE.url}/${this.path}`;
    }
};

