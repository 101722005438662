export const LOAN_PURPOSE = {
  PURCHASE_OF_PROPERTY: {
    label: "Purchase of Property",
    value: "PURCHASE_OF_PROPERTY",
    loanType: `HOME`,
  },
  CONSTRUCTION: {
    label: "Construction",
    value: "CONSTRUCTION",
    loanType: `HOME`,
  },
  RENOVATION: {
    label: "Renovation",
    value: "RENOVATION",
    loanType: `HOME`,
  },
  REFINANCING: {
    label: "Refinancing",
    value: "REFINANCING",
    loanType: `HOME`,
  },
  LOAN_TAKE_OUT: {
    label: "Loan Take-out",
    value: "LOAN_TAKE_OUT",
    loanType: `HOME`,
  },
  HOME_EQUITY: {
    label: "Home Equity",
    value: "HOME_EQUITY",
    loanType: `HOME`,
  },
  REIMBURSEMENT_LOAN: {
    label: "Reimbursement Loan",
    value: "REIMBURSEMENT_LOAN",
    loanType: `HOME`,
  },
};

export const LOAN_TERMS: number[] = Array(25)
  .fill(1)
  .map((v, i) => v + i);

export const LOAN_PROPERTY_TYPES = {
  APARTMENT: {
    label: "Apartment",
    value: "APARTMENT",
    loanType: `HOME`,
  },
  CONDOMINIUM: {
    label: "Condominium",
    value: "CONDOMINIUM",
    loanType: `HOME`,
  },
  HOUSE_LOT: {
    label: "House & Lot",
    value: "HOUSE_LOT",
    loanType: `HOME`,
  },
  TOWNHOUSE: {
    label: "Townhouse",
    value: "TOWNHOUSE",
    loanType: `HOME`,
  },
  VACANT_LOT: {
    label: "Vacant Lot",
    value: "VACANT_LOT",
    loanType: `HOME`,
  },
  BILLS: {
    label: "Bills",
    value: "BILLS",
    loanType: `PERSONAL`,
  },
  BUSINESS: {
    label: "Business",
    value: "BUSINESS",
    loanType: `PERSONAL`,
  },
  CAR_REPAIR: {
    label: "Car Repair",
    value: "CAR_REPAIR",
    loanType: `PERSONAL`,
  },
  EDUCATION: {
    label: "Education",
    value: "EDUCATION",
    loanType: `PERSONAL`,
  },
  ELECTRONICS: {
    label: "Electronics",
    value: "ELECTRONICS",
    loanType: `PERSONAL`,
  },
  EMERGENCY: {
    label: "Emergency",
    value: "EMERGENCY",
    loanType: `PERSONAL`,
  },
  HOME_REPAIR: {
    label: "Home Repair",
    value: "HOME_REPAIR",
    loanType: `PERSONAL`,
  },
  MEDICAL: {
    label: "Medical",
    value: "MEDICAL",
    loanType: `PERSONAL`,
  },
  NEW_MOTORCYCLE_REPAIR: {
    label: "New Motorcycle / Repair",
    value: "NEW_MOTORCYCLE_REPAIR",
    loanType: `PERSONAL`,
  },
  TRAVEL: {
    label: "Travel",
    value: "TRAVEL",
    loanType: `PERSONAL`,
  },
  WEDDING: {
    label: "Wedding",
    value: "WEDDING",
    loanType: `PERSONAL`,
  },
  OTHERS: {
    label: "Others",
    value: "OTHERS",
    loanType: `PERSONAL`,
  },
};

export const LOAN_PROPERTY_STATUS = {
  FORECLOSED: {
    label: "Foreclosed",
    value: "FORECLOSED",
    loanType: `HOME`,
  },
  PRE_SELLING: {
    label: "Pre-Selling",
    value: "PRE_SELLING",
    loanType: `HOME`,
  },
  READY_FOR_OCCUPANCY: {
    label: "Ready for Occupancy",
    value: "READY_FOR_OCCUPANCY",
    loanType: `HOME`,
  },
  RESELLING: {
    label: "Reselling",
    value: "RESELLING",
    loanType: `HOME`,
  },
  REFINANCING: {
    label: "I Own It - Refinancing Loan",
    value: "REFINANCING",
    loanType: `HOME`,
  },
  NEW_CONSTRUCTION: {
    label: "New Construction",
    value: "NEW_CONSTRUCTION",
    loanType: `HOME`,
  },
  RENOVATION: {
    label: "Renovation",
    value: "RENOVATION",
    loanType: `HOME`,
  },
  PERSONAL: {
    label: "Personal loan not applicable",
    value: "PERSONAL",
    loanType: `PERSONAL`,
  },
};

export const LOAN_TYPES = {
  HOME: {
    label: "Home Loan",
    value: "HOME",
  },
  // PERSONAL: {
  //   label: "Personal Loan",
  //   value: "PERSONAL",
  // },
};

export const EMPLOYMENT_TYPE = {
  PRIVATE: {
    label: "Employed - Private Sector",
    value: "PRIVATE",
  },
  GOVT: {
    label: "Employed - Government",
    value: "GOVT",
  },
  BPO: {
    label: "Employed - BPO",
    value: "BPO",
  },
  OFW: {
    label: "Employed - OFW/Seafarer",
    value: "OFW",
  },
  SELF: {
    label: "Self - Employed",
    value: "SELF",
  },
  PROFESSIONAL: {
    label: "Professional",
    value: "PROFESSIONAL",
  },
  COMMISSION_BASED: {
    label: "Commission - Based",
    value: "COMMISSION_BASED",
  },
  FREELANCER: {
    label: "Freelancer",
    value: "FREELANCER",
  },
  FOREIGN_NATIONALS: {
    label: "Foreign Nationals",
    value: "FOREIGN_NATIONALS",
  },
};

export const EMPLOYMENT_TYPE_PERSONAL = {
  PRIVATE: {
    label: "Employed - Private Sector",
    value: "PRIVATE",
  },
  GOVT: {
    label: "Employed - Government",
    value: "GOVT",
  },
  BPO: {
    label: "Employed - BPO",
    value: "BPO",
  },
  OFW: {
    label: "Employed - OFW/Seafarer",
    value: "OFW",
  },
  SELF: {
    label: "Self - Employed",
    value: "SELF",
  },
  PROFESSIONAL: {
    label: "Professional",
    value: "PROFESSIONAL",
  },
  COMMISSION_BASED: {
    label: "Commission - Based",
    value: "COMMISSION_BASED",
  },
};

export const PERSONAL_LOAN_TERMS = [1, 6, 12, 18, 24, 30, 36, 42, 48, 60];

export const EMPLOYMENT_RANK = {
  ASSISTANT_MANAGER: {
    label: "Assistant Manager",
    value: "ASSISTANT_MANAGER",
  },
  ASSISSTANT_VICE_PRESIDENT: {
    label: "Assistant Vice President",
    value: "ASSISSTANT_VICE_PRESIDENT",
  },
  CHAIRMAN: {
    label: "Chairman",
    value: "CHAIRMAN",
  },
  CHIEF_EXECUTIVE_OFFICER: {
    label: "Chief Executive Officer",
    value: "CHIEF_EXECUTIVE_OFFICER",
  },
  CLERK: {
    label: "Clerk",
    value: "CLERK",
  },
  DIRECTOR: {
    label: "Director",
    value: "DIRECTOR",
  },
  EXECUTIVE_VICE_PRESIDENT: {
    label: "Executive Vice President",
    value: "EXECUTIVE_VICE_PRESIDENT",
  },
  FIRST_VICE_PRESIDENT: {
    label: "First Vice President",
    value: "FIRST_VICE_PRESIDENT",
  },
  GENERAL_EMPLOYEE: {
    label: "General Employee",
    value: "GENERAL_EMPLOYEE",
  },
  MANAGER: {
    label: "Manager",
    value: "MANAGER",
  },
  NON_PROFESIONNAL: {
    label: "Non-profesionnal",
    value: "NON_PROFESIONNAL",
  },
  OWNER: {
    label: "Owner",
    value: "OWNER",
  },
  PRESIDENT: {
    label: "President",
    value: "PRESIDENT",
  },
  PROFESSIONAL: {
    label: "Professional",
    value: "PROFESSIONAL",
  },
  RANK_FILE: {
    label: "Rank & File",
    value: "RANK_FILE",
  },
  SENIOR_ASSISTANT_MANAGER: {
    label: "Senior Assistant Manager",
    value: "SENIOR_ASSISTANT_MANAGER",
  },
  SENIOR_ASSISTANT_VICE_PRESIDENT: {
    label: "Senior Assistant Vice President",
    value: "SENIOR_ASSISTANT_VICE_PRESIDENT",
  },
  SENIOR_MANAGER: {
    label: "Senior Manager",
    value: "SENIOR_MANAGER",
  },
  SENIOR_VICE_PRESIDENT: {
    label: "Senior Vice President",
    value: "SENIOR_VICE_PRESIDENT",
  },
  SUPERVISOR: {
    label: "Supervisor",
    value: "SUPERVISOR",
  },
  VICE_PRESIDENT: {
    label: "Vice President",
    value: "VICE_PRESIDENT",
  },
};

export const EMPLOYMENT_TENURE = {
  "0_1": {
    label: "less than a year",
    value: "0_1",
  },
  "1_2": {
    label: "1 - 2 years",
    value: "1_2",
  },
  "2_3": {
    label: "2 - 3 years",
    value: "2_3",
  },
  "3_100": {
    label: "more than 3 years",
    value: "3_100",
  },
};

export const CREDIT_CARD_STATUS = {
  YES: {
    label: "Yes, active credit card",
    value: "YES",
  },
  NOT_NOW: {
    label: "No, but i used to own one",
    value: "NOT_NOW",
  },
  NO: {
    label: "No, i never had one",
    value: "NO",
  },
};

export const NATIONALITY = {
  FILIPINO: {
    label: "Filipino",
    value: "FILIPINO",
  },
  FOREIGNER: {
    label: "Foreigner",
    value: "FOREIGNER",
  },
};

// Loan Application Data

export const EDUCATIONAL_BACKGROUND = {
  COLLEGE: {
    label: "College",
    value: "college",
  },
  UNDER_GRADUATE: {
    label: "Under Graduate",
    value: "underGraduate",
  },
  POST_GRADUATE: {
    label: "Post Graduate",
    value: "postGraduate",
  },
  VOCATIONAL: {
    label: "Vocational",
    value: "vocational",
  },
};

export const CIVIL_STATUS = {
  SINGLE: {
    label: "Single",
    value: "single",
  },
  MARRIED: {
    label: "Married",
    value: "married",
  },
  SEPARATED: {
    label: "Separated",
    value: "separated",
  },
  WIDOWED: {
    label: "Widowed",
    value: "widowed",
  },
};

export const RELATIONS = {
  BROTHER: {
    label: "Brother",
    value: "brother",
  },
  FATHER: {
    label: "Father",
    value: "father",
  },
  MOTHER: {
    label: "Mother",
    value: "mother",
  },
  SISTER: {
    label: "Sister",
    value: "sister",
  },
  // SPOUSE: {
  //     label: 'Spouse',
  //     value: 'spouse'
  // },
  SON: {
    label: "Son",
    value: "son",
  },
  DAUGHTER: {
    label: "Daughter",
    value: "daughter",
  },
  FIANCE: {
    label: "Fiance",
    value: "fiance",
  },
  LIFE_DOMESTIC_PARTNER: {
    label: "Life/Domestic Partner",
    value: "life/domestic partner",
  },
};

export const HOME_OWNERSHIP = {
  OWNED: {
    label: "Owned",
    value: "owned",
  },
  RENTED: {
    label: "Rented",
    value: "rented",
  },
  MORTGAGED: {
    label: "Mortgaged",
    value: "mortgaged",
  },
  USED_FREE: {
    label: "Used Free",
    value: "used_free",
  },
  LIVING_WITH_RELATIVES: {
    label: "Living with Relatives",
    value: "living_with_relatives",
  },
};

export const TYPE = {
  // CUSTOMER: 'Customer',
  CUSTOMER: {
    label: "Customer",
    value: "Customer",
  },
  SUPPLIER: {
    label: "Supplier",
    value: "Supplier",
  },
};
export const LOAN_FIXING_PERIOD: number[] = [1, 2, 3, 4, 5];

export const DOC_TYPES = {
  RESERVE_AGREEMENT: {
    label: "Reservation Agreement",
    value: "reservation_agreement",
  },
  TAX_DECLARATION_1: {
    label: "Tax Declaration 1",
    value: "tax_declaration_1",
  },
  TAX_DECLARATION_2: {
    label: "Tax Declaration 2",
    value: "tax_declaration_2",
  },
  BILL_MATERIAL: {
    label: "Bill Material",
    value: "bill_material",
  },
  FLOOR_PLAN: {
    label: "Floor Plan",
    value: "floor_plan",
  },
};

export const INDUSTRIES = {
  AGRI_FOREST_FISH: {
    label: "Agriculture & Forestry/Wildlife",
    value: "AGRI_FOREST_FISH",
  },
  ADVERTISING: {
    label: "Advertising",
    value: "ADVERTISING",
  },
  BUSINESS_INFORMATION: {
    label: "Business & Information",
    value: "BUSINESS_INFORMATION",
  },
  CONST_UTIL_CONTRACT: {
    label: "Construction/Utilities/Contracting",
    value: "CONST_UTIL_CONTRACT",
  },
  EDUCATION: {
    label: "Education",
    value: "EDUCATION",
  },
  ENTERTAINMENT_FASHION: {
    label: "Entertainment & Fashion",
    value: "ENTERTAINMENT_FASHION",
  },
  FINANCE_INSURANCE: {
    label: "Finance & Insurance",
    value: "FINANCE_INSURANCE",
  },
  FOOD_HOSPITALITY: {
    label: "Food & Hospitality",
    value: "FOOD_HOSPITALITY",
  },
  GAMING: {
    label: "Gaming",
    value: "GAMING",
  },
  HEALTH_SERVICES: {
    label: "Health Services",
    value: "HEALTH_SERVICES",
  },
  INFORMATION_TECHNOLOGY: {
    label: "Information Technology",
    value: "INFORMATION_TECHNOLOGY",
  },
  MANUFACTURING: {
    label: "Manufacturing",
    value: "MANUFACTURING",
  },
  MOTOR_VEHICLE: {
    label: "Motor Vehicle",
    value: "MOTOR_VEHICLE",
  },
  MUSIC_MEDIA: {
    label: "Music & Media",
    value: "MUSIC_MEDIA",
  },
  NATURAL_RES_ENV: {
    label: "Natural Resources/Environmental",
    value: "NATURAL_RES_ENV",
  },
  OTHER: {
    label: "Other",
    value: "OTHER",
  },
  PERSONAL_SERVICES: {
    label: "Personal Services",
    value: "PERSONAL_SERVICES",
  },
  REAL_ESTATE_HOUSING: {
    label: "Real Estate & Housing",
    value: "REAL_ESTATE_HOUSING",
  },
  RETAIL: {
    label: "Retail",
    value: "RETAIL",
  },
  SAFETY_SECURITY_LEGAL: {
    label: "Safety/Security & Legal",
    value: "SAFETY_SECURITY_LEGAL",
  },
  TRANSPORTATION: {
    label: "Transportation",
    value: "TRANSPORTATION",
  },
};

export const LOAN_APPLICATION_STATUS = {
  DRAFT: {
    label: "Draft",
    value: "DRAFT",
  },
  // ALL: {
  //     label: 'All',
  //     value: 'ALL'
  // },
  NEW: {
    label: "New",
    value: "NEW",
  },
  NOOK_REVIEW: {
    label: "Nook Review",
    value: "NOOK_REVIEW",
  },
  REFERRED: {
    label: "Referred to Bank",
    value: "REFERRED",
  },
  BANK_APPROVED: {
    label: "Bank Approved",
    value: "BANK_APPROVED",
  },
  BANK_DECLINED: {
    label: "Bank Declined",
    value: "BANK_DECLINED",
  },
  NOOK_DECLINED: {
    label: "Nook Declined",
    value: "NOOK_DECLINED",
  },
  WAITING_ON_BORROWER: {
    label: "Waiting on Borrower",
    value: "WAITING_ON_BORROWER",
  },
  PENDING_APPRAISAL: {
    label: "Pending Appraisal",
    value: "PENDING_APPRAISAL",
  },
  APPLICATION_WITHDRAWN: {
    label: "Application Withdrawn",
    value: "APPLICATION_WITHDRAWN",
  },
  CREDIT_ASSESSMENT: {
    label: "Credit Assessment",
    value: "CREDIT_ASSESSMENT",
  },
  ARCHIVE: {
    label: "Archive",
    value: "ARCHIVE",
  },
  LOAN_BOOKED: {
    label: "Loan Booked",
    value: "LOAN_BOOKED",
  },
  APPROVED_AWAITING_CLIENT: {
    label: "Approved-Awaiting Client",
    value: "APPROVED_AWAITING_CLIENT",
  },
  INITIAL_DOCUMENTS_COMPLETED: {
    label: "Initial Documents Completed",
    value: "INITIAL_DOCUMENTS_COMPLETED",
  },
  FINAL_DOCUMENTS_COMPLETED: {
    label: "Final Documents Completed",
    value: "FINAL_DOCUMENTS_COMPLETED",
  },
  AWAITING_SELLER_DEVELOPER: {
    label: "Awaiting-Seller/Developer",
    value: "AWAITING_SELLER_DEVELOPER",
  },
  AWAITING_PROPERTY_CONSTRUCTION: {
    label: "Awaiting Property Construction",
    value: "AWAITING_PROPERTY_CONSTRUCTION",
  },
  DRAFT_REVIEW: {
    label: "Draft Review",
    value: "DRAFT_REVIEW",
  },
  CONDITIONAL_APPROVAL: {
    label: "Conditional Approval",
    value: "CONDITIONAL_APPROVAL",
  },
  FINAL_CREDIT_ASSESSMENT: {
    label: "Final Credit Assessment",
    value: "FINAL_CREDIT_ASSESSMENT",
  },
};

export const TOOLTIP_DATA = {
  propertyValue:
    "Each bank has a minimum amount that they will lend.Less than this isn't worth it for them.Amount shown is the minimum property value accepted",
  propertyType:
    "Will be used to determine the length of the loan (ie. term of loan).Eg. some banks allow 25 years for house & lots, 15 years for land, and 10 years for condo apartments.  ",
  type: "Most banks do not allow BPO workers. Some allow BPO workers if they are management level and have 3+ years tenure.",
  developerName:
    "If Pre-Selling or Ready For Occupancy is chosen in the above step (1.3) then the user must select the Developer of the property from the dropdown that appears.  Banks often have accredited developers that they work with.  See other spreadsheet tab below.",
  propertyStatus: {
    DEFAULT:
      "All banks allow: Foreclosed and Reselling.  But some don't allow us to refer people who are buying pre-seling and ready for occupancy homes (ie. usually apartments/condos purchased directly from developers.",
    READY_FOR_OCCUPANCY: "Developer has recently finished building it.",
    PRE_SELLING: "Developer has not finished building it.",
    RESELLING: "A pre-owned home for sale again.",
    // NEW_CONSTRUCTION: "You are building a property",
    FORECLOSED: "A home where the borrower has gone bankrupt.",
    // RENOVATION: "You are renovating a property you own"
  },
  employmentType: {
    PRIVATE: "You are employed by a private business",
    BPO: "You are employed by an outsourcing business",
    OFW: "You are employed overseas",
    SELF: "You own a business",
    PROFESSIONAL: "Doctor or other professional occupation",
    GOVT: "You are employed by a goverment",
    FREELANCER: "Those project-based employees from Eg. Upwork",
    COMMISSION_BASED:
      "No definite salary, earning per sale compensation. Ex: Sales Agent",
  },
  marriedToFlipino: "  ",
  employmentTenure:
    "Mainly used for BPO workers.If manager role (see 2.2) and more than 3 years tenure, OK to borrow from certain banks.Number stated is the minimum years of tenure.",
  grossIncome:
    "Used in the calculation of whether they can afford to repay the loanable amount noted in below.Monthly income amount shown.",
  birthDate:
    "Borrower must be at least 21 years old.And must not turn 65 years of age during the term of the loan.Eg. If you took out a 20 year loan at age 40, this is ok because the loan will finish by the time you are 60 which is less than 65 years old.",
  nationality:
    "Local Philippine citizens are fine.Foreigners are not allow to borrow unless they are married to a filipino and can provide a marriage contract.",
  creditCard:
    "Please select an option.Note that banks will check this during the credit assessment.",
  cardLimit:
    "This field will appear if they say they have an active credit card.Include credit card limit in total debt amount.",
  montlyPaymentOfAllLoans: "Enter the Monthly payment of your all Loans ",
  remainingBalanceOfLoan: "Enter total Remaining balance of all loans",
  cancelCard:
    "If yes, this might be an outright rejection, depending on bank guidelines.",
  otherLoans: "If yes, include Balance (PhP) in total debt amount",
  investment:
    "If yes, add income to the borrowers available income to cover monthly loan repayments",
  investmentAmount: "Enter the Monthly amount of investments",
  maritalStatus: "If Yes, add income to total income amount.",
  spouseIncome: "Enter Monthly income of your spouse",
  coBorrower:
    "If yes, add income to the borrowers available income to cover monthly loan repayments",
  coBorrowerIncome: "Enter co-borrower Monthly income",
  loanType:
    "Different loans have different requirements.At this stage, we will manually work with our bank partners on the requirements of any loan that is not a standard Purchase of Property",
  loanTerm: `Banks generally have different maximum loan terms for different types of properties.Some are standard but most differ depending on the property.`,
  lonableAmount:
    "This is the percentage of the property value that can be borrowed from the bank.  Typically it is 80% of the property value (which means the borrower must have a deposit of 20% of the value.\nBut this percentage can increase if the bank allows us to process loans for accredited developer.",
  lonableAmountPhp:
    "This is the peso amount for the loanable amount. This field changes based on the Loanable amount (%) slider in the above field.",
  loanSelectType: "Select loan type",
};
