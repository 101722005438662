import {
    Validators,
    ValidatorFn,
    AbstractControl,
    FormGroup,
    FormArray,
    FormControl
} from '@angular/forms';
import { USERNAME, USER_NAME, PASSWORD, PROPERTY, DESCRIPTION, USER_LICENSE, USER_COMPANY, USER_ABOUT, USER_TITLE, USER_LANGUAGE, LOAN } from './form.constants';

export function passwordMatchValidator(controlName: string, controlToMatchName: string): ValidatorFn {
    return (control: AbstractControl) => {
        const siblingControl = control.get(controlName) as FormControl;
        const controlToMatch = control.get(controlToMatchName) as FormControl;
        const currentErrors = { ...controlToMatch.errors };
        if (siblingControl.invalid) {
            return null;
        }
        if (siblingControl.value !== controlToMatch.value) {
            controlToMatch.setErrors({ ...currentErrors, mismatch: true });
            return null;
        } else {
            delete currentErrors.mismatch;
            if (Object.keys(currentErrors).length) {
                controlToMatch.setErrors({ ...currentErrors });
            } else {
                controlToMatch.setErrors(null);
            }
            return null;
        }
    }
}


export const CustomValidators = {
    whiteSpace(control: AbstractControl) {
        if (typeof control.value !== 'number') {
            const value = control.value || '';
            const isWhitespace = (value.trim().length !== value.length) && (value.trim().length === 0);
            return !isWhitespace ? null : { whiteSpace: true };
        }
        return null;
    },
    emailPattern(control: AbstractControl) {
        const value = control && control.value;
        const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,5}$/i;
        if (value && typeof value === 'string' && !regex.test(value)) {
            return {
                email: true
            };
        }
        return null;
    },
    get username(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(USERNAME.minLength),
            this.maxLength(USERNAME.maxLength)
        ];
    },
    get name(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(USER_NAME.minLength),
            this.maxLength(USER_NAME.maxLength)
        ];
    },
    get license(): ValidatorFn[] {
        return [
            this.minLength(USER_LICENSE.minLength),
            this.maxLength(USER_LICENSE.maxLength)
        ];
    },
    get company(): ValidatorFn[] {
        return [
            this.minLength(USER_COMPANY.minLength),
            this.maxLength(USER_COMPANY.maxLength)
        ];
    },
    get userAbout(): ValidatorFn[] {
        return [
            this.minLength(USER_ABOUT.minLength),
            this.maxLength(USER_ABOUT.maxLength)
        ];
    },
    get userTitle(): ValidatorFn[] {
        return [
            this.minLength(USER_TITLE.minLength),
            this.maxLength(USER_TITLE.maxLength)
        ];
    },
    get userLanguage(): ValidatorFn[] {
        return [
            this.minLength(USER_LANGUAGE.minLength),
            this.maxLength(USER_LANGUAGE.maxLength)
        ];
    },
    get propertyTitle(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(PROPERTY.title.minLength),
            this.maxLength(PROPERTY.title.maxLength)
        ];
    },
    get propertyDescription(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(PROPERTY.description.minLength),
            this.maxLength(PROPERTY.description.maxLength)
        ];
    },
    get propertyAddress(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(PROPERTY.address.minLength),
            this.maxLength(PROPERTY.address.maxLength)
        ];
    },
    get propertyBarangay(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(PROPERTY.barangay.minLength),
            this.maxLength(PROPERTY.barangay.maxLength)
        ];
    },
    get propertyPrice(): ValidatorFn[] {
        return [
            Validators.min(PROPERTY.price.min),
            Validators.max(PROPERTY.price.max)
        ];
    },
    propertyLoanValue: [
        Validators.min(localStorage.getItem('loanTypeSelected') === 'PERSONAL' ? LOAN.personal.min : LOAN.property.min),
        Validators.max(localStorage.getItem('loanTypeSelected') === 'PERSONAL' ? LOAN.personal.max : LOAN.property.max),
    ],
    get email(): ValidatorFn[] {
        return [
            Validators.email,
            this.emailPattern
        ];
    },
    get password(): ValidatorFn[] {
        return [
            this.whiteSpace,
            Validators.required,
            this.minLength(PASSWORD.minLength),
            this.maxLength(PASSWORD.maxLength)
        ];
    },
    get description(): ValidatorFn[] {
        return [
            this.whiteSpace,
            this.minLength(DESCRIPTION.minLength),
            this.maxLength(DESCRIPTION.maxLength)
        ];
    },
    get zipCode(): ValidatorFn[] {
        return [
            this.minLength(PASSWORD.minLength),
            this.maxLength(PASSWORD.maxLength)
        ];
    },
    number(control: AbstractControl): { [key: string]: any } {
        const pattern = new RegExp(`^[0-9]*$`);
        if (control.value && !pattern.test(control.value)) {
            return { number: true };
        }
        return null;
    },
    minLength(requiredLength: number) {
        return (control: AbstractControl) => {
            if (control.value) {
                const value = control.value.toString();
                if (value.length < requiredLength) {
                    return { minlength: { requiredLength } };
                }
            }
            return null;
        };
    },
    maxLength(requiredLength: number) {
        return (control: AbstractControl) => {
            if (control.value) {
                const value = control.value.toString();
                if (value.length > requiredLength) {
                    return { maxlength: { requiredLength } };
                }
            }
            return null;
        };
    },
    get phone(): ValidatorFn[] {
        return [
            this.number,
            this.maxLength(15)
        ];
    },
    get fax(): ValidatorFn[] {
        return [
            this.number,
            this.minLength(7),
            this.maxLength(15)
        ];
    },
    match(field: string, otherField?: string, parentControl?: FormGroup | FormArray): ValidatorFn {
        return this.compare(field, 'MATCH', otherField, parentControl)((a, b) => a === b);
    },
    lowerThan(field: string, otherField?: string, parentControl?: FormGroup | FormArray): ValidatorFn {
        return this.compare(field, 'LOWER', otherField, parentControl)((a, b) => a < b);
    },
    equalLowerThan(field: string, otherField?: string, parentControl?: FormGroup | FormArray): ValidatorFn {
        return this.compare(field, 'LOWER', otherField, parentControl)((a, b) => a <= b);
    },
    higherThan(field: string, otherField?: string, parentControl?: FormGroup | FormArray): ValidatorFn {
        return this.compare(field, 'HIGHER', otherField, parentControl)((a, b) => a > b);
    },
    equalHigherThan(field: string, otherField?: string, parentControl?: FormGroup | FormArray): ValidatorFn {
        return this.compare(field, 'HIGHER', otherField, parentControl)((a, b) => a >= b);
    },
    compare(field: string, type: 'MATCH' | 'LOWER' | 'HIGHER', fieldName?: string, parentControl?: FormGroup | FormArray) {
        return (fn: (a: any, b: any) => boolean) => {
            return (control: AbstractControl) => {
                if (control.value) {
                    const parent = parentControl ? parentControl : control.parent;
                    if (parent) {
                        const matchControl: AbstractControl = parent.controls[field];
                        if (!matchControl) {
                            throw (new Error(`Match Control [${fieldName || field}] not found on parent control.`));
                        }
                        if (matchControl.touched && ![control, matchControl].some((fc) => ['', undefined, null].includes(fc.value))) {
                            if (!fn(control.value, matchControl.value)) {
                                return {
                                    compare: {
                                        field: fieldName || field,
                                        type
                                    }
                                };
                            }
                        }
                    }
                }
            };
        };
    },
    url(control: AbstractControl) {
        const regForUrl = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&@!\-\/]))?/;
        if (!regForUrl.test(control.value)) {
            return { url: true };
        }
        return null;
    },
    answer: [
        Validators.required,
        Validators.maxLength(120)
    ],
    income: [
        Validators.min(localStorage.getItem('loanTypeSelected') === 'PERSONAL' ? LOAN.incomePersonal.min : LOAN.incomeHome.min),
        Validators.max(localStorage.getItem('loanTypeSelected') === 'PERSONAL' ? LOAN.incomePersonal.max : LOAN.incomeHome.max),
    ],
};

