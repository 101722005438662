<div class="main">
    <mat-icon (click)="dialogRef.close()">close</mat-icon>
    <br>    
    <h2 class="heading">{{(data.type==='pq')?'PRE-QUALIFICATION INFORMATION':'HOME LOAN INFORMATION'}}</h2>

    <mat-dialog-content>

        <div class="form-group">
            <span class="input-label">Date :</span>
            <span class="input-value">{{data?.data?.createdAt | formatDate}}</span>
        </div>
        <div class="form-group">
            <span class="input-label">Reference ID :</span>
            <span class="input-value">{{data?.data?.referenceId | format}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='pq'">
            <span class="input-label">Loan Type :</span>
            <span class="input-value">{{data?.data?.loanType}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='pq'">
            <span class="input-label">Property Value :</span>
            <span class="input-value">{{data?.data?.propertyValue| amount}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='pq'">
            <span class="input-label">Property Type :</span>
            <span class="input-value">{{data?.data?.propertyType}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='pq'">
            <span class="input-label">No of Bank(s) :</span>
            <span class="input-value">{{data?.data?.No_Of_Banks}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='hla'">
            <span class="input-label">Loan Amount :</span>
            <span class="input-value">{{data?.data?.loanAmount | amount | format : 'Php $$'}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='hla'">
            <span class="input-label">Bank :</span>
            <span class="input-value" style="word-break: break-all;">{{data?.data?.bank}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='hla'">
            <span class="input-label">Status :</span>
            <span class="input-value" style="word-break: break-all;">{{data?.data?.status}}</span>
        </div>
        <div class="form-group" *ngIf="data.type==='pq'" style="justify-content: center;">
            <button type="button" mat-flat-button  (click)="onViewDetails(data.data._id)">View details</button>
        </div>
        <ng-container *ngIf="data.type==='hla'">
            <div class="form-group action_btns">
                <div class="btn_wrap" *ngIf="data.data.status === 'DRAFT'">
                    <a mat-flat-button  href="{{loanReviewUrl}}?application={{data.data.id}}" target="_blank" mat-dialog-close>Continue Edit</a>
                </div>
                <div class="btn_wrap" (click)="onDocRequired(data.data.id)">
                    <a  mat-flat-button style="color: white;">Upload Document</a>
                </div>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <a class="viewmore" mat-dialog-close > << Back</a>
    </mat-dialog-actions>
</div>