import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CIVIL_STATUS, CustomValidators } from "src/app/constants";

export class ApplicationForm {
  formGroup: FormGroup;
  get bankInfoGroup(): FormGroup {
    return this.formGroup.get("bankInfo") as FormGroup;
  }
  get personalInfoGroup(): FormGroup {
    return this.formGroup.get("personalInfo") as FormGroup;
  }
  get spouseInfoGroup(): FormGroup {
    return this.personalInfoGroup.get("spouseInfo") as FormGroup;
  }
  get contactInfoGroup(): FormGroup {
    return this.formGroup.get("contactInfo") as FormGroup;
  }
  get loanDetailsGroup(): FormGroup {
    return this.formGroup.get("loanDetails") as FormGroup;
  }
  get employmentInfoGroup(): FormGroup {
    return this.formGroup.get("employmentInfo") as FormGroup;
  }
  get dependentsInfoArray(): FormArray {
    return this.formGroup.get("dependentsInfo") as FormArray;
  }
  get referenceInfoArray(): FormArray {
    return this.formGroup.get("referenceInfo") as FormArray;
  }
  get tradeReferenceArray(): FormArray {
    return this.formGroup.get("tradeReferences") as FormArray;
  }
  get documentsGroup(): FormGroup {
    return this.formGroup.get("documents") as FormGroup;
  }

  get leagelDocs() {
    return this.documentsGroup.get("legalDocument") as FormArray;
  }
  get colleteralDoc() {
    return this.documentsGroup.controls["colleteralDoc"] as FormArray;
  }
  get incomeDocument() {
    return this.documentsGroup.controls["incomeDocument"] as FormArray;
  }

  get loanAttorneyInfo() {
    return this.formGroup.get("loanAttorneyInfo") as FormGroup;
  }
  constructor(private $fb: FormBuilder, public initialValue: any) {
    this.$createForm();
  }
  private isPersonalLoan() {
    return localStorage.getItem("loanTypeSelected") === "PERSONAL";
  }
  private $createForm() {
    // validators
    const NameValidators = CustomValidators.name.concat(Validators.required);
    const MobileValidators = CustomValidators.phone.concat(Validators.required);
    const EmailValidators = CustomValidators.email.concat(Validators.required);
    const CompanyValidators = CustomValidators.company.concat(
      Validators.required
    );

    this.formGroup = this.$fb.group({
      // partnerName: [''],
      // partnerId: [''],
      bankInfo: this.$fb.group({
        path: [null],
        iconUrl: [null, Validators.required],
        bankId: [null, Validators.required],
        bankName: [null, Validators.required],
        abbrevation: [null, Validators.required],
      }),
      documents: this.$fb.group({
        legalDocument: this.$fb.array([
          //  this.createLegalDoc()
        ]),
        incomeDocument: this.$fb.array([
          //   this.createIncomeDoc()
        ]),
        colleteralDoc: this.$fb.array([
          // this.createColleteralDoc()
        ]),
        purchasePropertyInfo: this.$fb.group({
          contactPerson: ["", this.isPersonalLoan() ? [] : Validators.required],
          contactNumber: [
            null,
            this.isPersonalLoan() ? [] : Validators.required,
          ],
          address: ["", this.isPersonalLoan() ? [] : Validators.required],
        }),
      }),
      propertyInfo: this.$fb.group({
        value: [null, [Validators.required, Validators.min(0)]],
        type: [null, Validators.required],
        status: [null, Validators.required],
        developer: [null],
      }),
      loanDetails: this.$fb.group({
        maxLoanTerm: [null, [Validators.required, Validators.min(0)]],
        fixedPeriod: [null, [Validators.required, Validators.min(0)]],
        loanTerm: [null, [Validators.required, Validators.min(0)]],
        termLength: [null, Validators.required],
        rate: [null, [Validators.required, Validators.min(0)]],
        monthlyRepayment: [null, [Validators.required, Validators.min(0)]],
        hasCoBorrower: [null, Validators.required],
        loanType: [null, Validators.required],
        loanPercent: [null, Validators.required],
        loanAmount: [null, Validators.required],
      }),
      personalInfo: this.$fb.group({
        firstName: ["", NameValidators],
        middleName: ["", CustomValidators.name],
        lastName: ["", NameValidators],
        gender: ["", Validators.required],
        nationality: ["", Validators.required],
        localVisa: [""],
        creditCard: this.$fb.group({
          status: [false, Validators.required],
          limit: [null],
          cancelled: [null],
        }),
        prevLoans: this.$fb.group({
          status: [false, Validators.required],
          monthlyTotal: [null],
          remainingTotal: [null],
        }),
        educationBackground: ["", Validators.required],
        civilStatus: ["", Validators.required],
        monthlyIncome: [null, [Validators.required, Validators.min(0)]],
        otherIncome: [0],
        motherMaidenName: ["", NameValidators],
        placeOfBirth: [""],
        birthDate: this.$fb.group({
          date: ["", Validators.required],
          month: ["", Validators.required],
          year: ["", Validators.required],
        }),
        spouseInfo: this.$fb.group({
          firstName: ["", NameValidators],
          lastName: ["", NameValidators],
          middleName: ["", NameValidators],
          motherMaidenName: ["", CustomValidators.name],
          birthDate: this.$fb.group({
            date: ["", Validators.required],
            month: ["", Validators.required],
            year: ["", Validators.required],
          }),
          monthlyIncome: [0, [Validators.required, Validators.min(0)]],
          isCoborrower: [true, Validators.required],
        }),
        coBorrowerInfo: this.$fb.group({
          firstName: ["", NameValidators],
          lastName: ["", NameValidators],
          middleName: ["", NameValidators],
          birthDate: this.$fb.group({
            date: [0, Validators.required],
            month: [0, Validators.required],
            year: [0, Validators.required],
          }),
          monthlyIncome: [0, [Validators.required, Validators.min(0)]],
          relationship: ["", Validators.required],
        }),
      }),
      contactInfo: this.$fb.group({
        phoneNumber: [null, CustomValidators.phone],
        email: [null, EmailValidators],
        mobileNumber: [null, MobileValidators],
        currentAddress: this.$fb.group({
          address: [null, Validators.required],
          homeOwnership: [null, Validators.required],
          permanentResidenceSince: [null, Validators.required],
        }),
        permanentAddress: this.$fb.group({
          address: [null, Validators.required],
          homeOwnership: [null, Validators.required],
          permanentResidenceSince: [null, Validators.required],
        }),
        previousAddress: this.$fb.group({
          address: [null, Validators.required],
          homeOwnership: [null, Validators.required],
          permanentResidenceSince: [null, Validators.required],
        }),
      }),
      employmentInfo: this.$fb.group({
        type: [null, Validators.required],
        rank: [null, Validators.required],
        tenure: [null, Validators.required],
        companyIndustry: [null, Validators.required],
        tin: [null, Validators.required],
        companyName: [null, CompanyValidators],
        sss: [null, Validators.required],
        officePhone: [null, MobileValidators],
        officeEmail: [null, EmailValidators],
        officeAddress: [null, Validators.required],
        // cityId: [null, Validators.required],
        // cityName: [null, Validators.required],
        // regionId: [null, Validators.required],
        // regionName: [null, Validators.required],
        // barangay: [null, Validators.required],
        // country: [null, Validators.required],
        coBorrowerInfo: this.$fb.group({
          employmentType: [null, Validators.required],
          tin: [null, Validators.required],
          employmentRank: [null, Validators.required],
          sss: [null, Validators.required],
          employmentTenure: [null, Validators.required],
          companyName: [null, CompanyValidators],
          companyIndustry: [null, Validators.required],
          officePhone: [null, MobileValidators],
          officeEmail: [null, EmailValidators],
          officeAddress: [null, Validators.required],
          // cityId: [null, Validators.required],     // Refer to city schema
          // cityName: [null, Validators.required],
          // regionId: [null, Validators.required], // Refer to region schema
          // regionName: [null, Validators.required],
          // barangay: [null, Validators.required],
          // country: [null, Validators.required],
        }),
      }),
      dependentsInfo: this.$fb.array([this.createDependentInfoGroup()]),
      referenceInfo: this.$fb.array([this.createReferenceInfoGroup()]),
      tradeReferences: this.$fb.array([this.createTradeReferenceInfoGroup()]),
      loanAttorneyInfo: this.$fb.group({
        name: [""],
        contactNumber: [""],
        address: [""],
        relationship: [""],
      }),
      // propertyDocuments: this.$fb.group({
      //     borrowerValidDocIds: this.$fb.array([
      //         new FormControl(null),
      //         new FormControl(null)
      //     ]),
      //     coBorrowerValidId: this.$fb.array([
      //         new FormControl(null),
      //         new FormControl(null)
      //     ]),
      //     latestITR: [null],
      //     employmentCert: [null],
      //     // purchasePropertyInfo: this.$fb.group({
      //     //     address: [null, Validators.required],
      //     //     contactPerson: [null, NameValidators],
      //     //     contactNumber: [null, MobileValidators],
      //     //     collateralDocStatus: [null, Validators.required],
      //     //     collateralDocList: this.$fb.array([
      //     //         this.createCollateralDocGroup({ docType: DOC_TYPES.RESERVE_AGREEMENT.value }),
      //     //         this.createCollateralDocGroup({ docType: DOC_TYPES.TAX_DECLARATION_1.value }),
      //     //         this.createCollateralDocGroup({ docType: DOC_TYPES.TAX_DECLARATION_2.value }),
      //     //         this.createCollateralDocGroup({ docType: DOC_TYPES.BILL_MATERIAL.value }),
      //     //         this.createCollateralDocGroup({ docType: DOC_TYPES.FLOOR_PLAN.value })
      //     //     ]),
      //     // }),
      //     nookAgent: [null, CustomValidators.name],
      // })
    });
    // const { dependentsInfo = [], propertyDocuments } = this.initialValue || {} as any;
    // if (dependentsInfo.length > 1) {
    //     let moreToInsert = dependentsInfo.length - 1;
    //     while (moreToInsert) {
    //         this.dependentsInfoArray.push(this.createDependentInfoGroup());
    //         --moreToInsert;
    //     }
    // }
    // if (this.initialValue) {
    //     if (propertyDocuments) {
    //         propertyDocuments.borrowerValidDocIds = [];
    //         propertyDocuments.coBorrowerValidId = [];
    //         propertyDocuments.latestITR = null;
    //         propertyDocuments.employmentCert = null;
    //         if (!!propertyDocuments.purchasePropertyInfo) {
    //             propertyDocuments.purchasePropertyInfo.collateralDocList = [];
    //         }
    //     }
    //     this.formGroup.patchValue(this.initialValue);
    // }
    this.$dobHandler();
    this.$spouseHandler();
    this.$coBorrowerHandler();
  }
  createDependentInfoGroup(data?: any): FormGroup {
    const group = this.$fb.group({
      name: [(data && data.name) || null, CustomValidators.name],
      age: [
        (data && data.age) || null,
        [Validators.min(1), Validators.max(150)],
      ],
      relationship: [(data && data.relationship) || null],
    });
    return group;
  }
  createReferenceInfoGroup(data?: any): FormGroup {
    const group = this.$fb.group({
      name: [
        (data && data.name) || null,
        CustomValidators.name.concat(Validators.required),
      ],
      relationship: [
        (data && data.relationship) || null,
        [Validators.required],
      ],
      mobileNumber: [
        (data && data.mobileNumber) || null,
        CustomValidators.phone.concat(Validators.required),
      ],
      address: [(data && data.address) || null, [Validators.required]],
    });
    return group;
  }
  createTradeReferenceInfoGroup(data?: any): FormGroup {
    const group = this.$fb.group({
      companyName: [(data && data.companyName) || ""],
      type: [(data && data.type) || ""],
      contactPerson: [(data && data.contactPerson) || ""],
      contactNumber: [(data && data.contactNumber) || ""],
      position: [(data && data.position) || ""],
    });
    return group;
  }
  createCollateralDocGroup(data?: any) {
    return this.$fb.group({
      docType: [(data && data.docType) || null],
      docUrl: [(data && data.docUrl) || null],
    });
  }
  private $dobHandler() {
    [
      this.spouseInfoGroup,
      this.personalInfoGroup,
      this.personalInfoGroup.get("coBorrowerInfo") as FormGroup,
    ].forEach((group: FormGroup) => {
      const { birthDate } = group.controls;
      const { month, date, year } = (birthDate as FormGroup).controls;
      // update dates according to month
      month.valueChanges.subscribe((monthIndex) => {
        if (monthIndex !== null) {
          const maxDate = new Date(year.value || 2000, monthIndex).getMaxDate();
          if (date.value > maxDate) {
            date.reset();
          }
        }
      });
      // update dates according to year
      year.valueChanges.subscribe((value) => {
        if (year !== null) {
          const maxDate = new Date(value, month.value || 0).getMaxDate();
          if (date.value > maxDate) {
            date.reset();
          }
        }
      });
      month.updateValueAndValidity();
      year.updateValueAndValidity();
    });
  }
  private $spouseHandler() {
    const { civilStatus } = this.personalInfoGroup.controls;
    civilStatus.valueChanges.subscribe((value: string) => {
      if (CIVIL_STATUS.MARRIED.value === value) {
        this.spouseInfoGroup.enable();
      } else {
        this.spouseInfoGroup.disable();
      }
    });
    civilStatus.updateValueAndValidity();
  }
  private $coBorrowerHandler() {
    const { hasCoBorrower } = this.loanDetailsGroup.controls;
    const controls = [
      this.personalInfoGroup.controls.coBorrowerInfo,
      this.employmentInfoGroup.controls.coBorrowerInfo,
      // this.propertyDocumentsGroup.controls.coBorrowerValidId
    ];
    hasCoBorrower.valueChanges.subscribe((value) => {
      if (value) {
        controls.forEach((control) => control.enable());
      } else {
        controls.forEach((control) => control.disable());
      }
    });
    hasCoBorrower.updateValueAndValidity();
  }
  // get imageErrorMessage(): string | null {
  //     const {
  //         borrowerValidDocIds,
  //         coBorrowerValidId,
  //         latestITR,
  //         employmentCert,
  //         purchasePropertyInfo
  //     } = this.propertyDocumentsGroup.controls;
  //     const { collateralDocList, collateralDocStatus } = (purchasePropertyInfo as FormGroup).controls;
  //     if (borrowerValidDocIds.invalid) {
  //         return 'Please upload borrower id\"s';
  //     }
  //     if (coBorrowerValidId.invalid) {
  //         return 'Please upload co-borrower id\"s';
  //     }
  //     if (latestITR.invalid) {
  //         return 'Please upload latest ITR';
  //     }
  //     if (employmentCert.invalid) {
  //         return 'Please upload employment certificate';
  //     }
  //     if (collateralDocStatus.value && !collateralDocList.value.some(el => el.docUrl)) {
  //         return 'Please upload atleast 1 (one) collateral document.';
  //     }
  //     return null;
  // }

  createLegalDoc(data?: any): FormGroup {
    const group = this.$fb.group({
      status: [data.status || "Pending"],
      documentRequired: [data.documentRequired],
      description: [data.description || ""],
      url: [data.url || ""],
      createdAt: [data.createdAt],
    });
    return group;
  }
  createIncomeDoc(data?: any): FormGroup {
    const group = this.$fb.group({
      status: [data.status || "Pending"],
      documentRequired: [data.documentRequired],
      description: [data.description || ""],
      url: [data.url || ""],
      createdAt: [data.createdAt],
    });
    return group;
  }
  createColleteralDoc(data?: any): FormGroup {
    const group = this.$fb.group({
      status: [data.status || "Pending"],
      documentRequired: [data.documentRequired],
      description: [data.description || ""],
      url: [data.url || ""],
      createdAt: [data.createdAt],
    });
    return group;
  }
}
