import { LOAN_PROPERTY_ROUTE, LOAN_WORK_ROUTE, LOAN_OTHER_ROUTE, LOAN_INFO_ROUTE } from './route.constants';

export const PRE_QUALIFICATION_STEPS = [
    {
        label: 'Step 1',
        link: LOAN_PROPERTY_ROUTE.url
    },
    {
        label: 'Step 2',
        link: LOAN_WORK_ROUTE.url
    },
    {
        label: 'Step 3',
        link: LOAN_OTHER_ROUTE.url
    },
    {
        label: 'Step 4',
        link: LOAN_INFO_ROUTE.url
    }
];
