import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router, UrlTree } from "@angular/router";
import { HttpService } from "@http";
import { UploaderService } from "@uploader";
import { BehaviorSubject, Subject } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import {
  LOAN_APPLICATIONS_ROUTE,
  LOAN_APPLICATION_STATUS,
  NOTIFICATION_TYPE,
  PRE_QUALIFICATION_ROUTE,
} from "src/app/constants";
import { GlobalService } from "src/app/services/global/global.service";
import { ProfileService } from "src/app/services/profile/profile.service";
import { cleanData } from "src/utils";
import { PreQualificationService } from "../../pre-qualification/services/pre-qualification.service";
import {
  LOAN_CONTACT_ROUTE,
  LOAN_EMPLOYMENT_ROUTE,
  LOAN_INFO_ROUTE,
  LOAN_OTHER_ROUTE,
  LOAN_PERSONAL_ROUTE,
  LOAN_REQUIRED_ROUTE,
  LOAN_REVIEW_ROUTE,
} from "../constants";
import { ApplicationForm } from "../models";

const storekey = "LOAN_APPLICATION";

@Injectable({
  providedIn: "root",
})
export class HomeLoanService {
  $documents = new BehaviorSubject(null);
  bankUrl = new Subject();
  requestData: any;
  application: ApplicationForm;
  classifcation = new BehaviorSubject("");
  private $summary: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly summaryRoute = this.$summary.asObservable();
  ipAddress: any;
  partnerData: any;
  set isSummaryRoute(value: boolean) {
    this.$summary.next(value);
  }
  isLoanding = new BehaviorSubject(false);
  // Form Group for steps
  steps: FormGroup[] = [];
  loanId: string = null;
  isEditMode = true;
  patchValue = true;
  thisFB: any;
  constructor(
    fb: FormBuilder,
    private $router: Router,
    private $http: HttpService,
    private $global: GlobalService,
    private $uploader: UploaderService,
    private $profile: ProfileService,
    private $dailog: MatDialog,
    private $pqService: PreQualificationService
  ) {
    this.thisFB = fb;
    this.application = new ApplicationForm(fb, this.retrieveValue);
    this.$profile.changes.subscribe((data) => {
      if (data) {
        const { personalInfoGroup, contactInfoGroup } = this.application;
        const { firstName, middleName, lastName, email, phoneNumber } = data;
        personalInfoGroup.patchValue({ firstName, middleName, lastName });
        contactInfoGroup.patchValue({ email, mobileNumber: phoneNumber });
      } else {
        this.$router.navigateByUrl(PRE_QUALIFICATION_ROUTE.url);
      }
    });
    this.handleSummerRoute();
    this.getIp();
  }
  setInitialValue(value: any) {
    this.application.formGroup.patchValue(value);
  }
  setValue(value: any) {
    const { formGroup, bankInfoGroup, personalInfoGroup } = this.application;
    const { bankInfo } = value;

    if (this.patchValue) {
      formGroup.patchValue(value);
    }
    // if (!value.personalInfo.civilStatus) {
    //   personalInfoGroup.get('civilStatus').patchValue('single');
    // }
    bankInfoGroup.patchValue(bankInfo);
    formGroup.updateValueAndValidity();
    setTimeout(() => {
      this.$handleBankUpdate();
    }, 10);
  }
  storeValue() {
    // if (this.loanId) {

    const data = this.$global.encodeData(this.application.formGroup.value);
    localStorage.setItem(storekey, data);
    // }
  }
  get retrieveValue() {
    const data = localStorage.getItem(storekey);
    if (data) {
      try {
        return this.$global.decodeData(data);
      } catch (err) {
        this.clearValue();
      }
    }
  }
  clearValue() {
    localStorage.removeItem(storekey);
  }
  async createApplication(body) {
    body.ipAddress = this.ipAddress;
    const partnerEncryptedData = this.$global.partnerData;
    if (partnerEncryptedData) {
      body.partnerName = partnerEncryptedData.name;
      body.partnerId = partnerEncryptedData.shortId;
    }
    if (!body.partnerName || !body.partnerId) {
      delete body.partnerName;
      delete body.partnerId;
    }
    const { data } = await this.$http.post(
      `~/user/loan/application`,
      cleanData(body)
    );
    this.clearPartnerData();
    return data;
  }
  async updateApplication(body) {
    debugger;
    body.ipAddress = this.ipAddress;
    const partnerEncryptedData = this.$global.partnerData;
    if (partnerEncryptedData) {
      body.partnerName = partnerEncryptedData.name;
      body.partnerId = partnerEncryptedData.shortId;
    }
    if (!body.partnerName || !body.partnerId) {
      delete body.partnerName;
      delete body.partnerId;
    }
    const { data } = await this.$http.patch(
      `~/user/loan/application`,
      cleanData(body)
    );
    this.clearPartnerData();
    return data;
  }

  async saveApplication(
    isDraft: boolean = false,
    redirect?,
    isIncomplete?,
    isImage?,
    applicationStatus?
  ) {
    debugger;
    const body = await this.formatData(
      isDraft,
      applicationStatus,
      isIncomplete
    );
    console.log(body);
    const { formGroup } = this.application;
    if (this.loanId) {
      body.loanId = this.loanId;
      if (isImage) body["notificationType"] = NOTIFICATION_TYPE.IMAGE;
      if (
        !isIncomplete &&
        (formGroup.controls.personalInfo.dirty ||
          formGroup.controls.personalInfo.dirty ||
          formGroup.controls.contactInfo.dirty ||
          formGroup.controls.employmentInfo.dirty ||
          formGroup.controls.dependentsInfo.dirty ||
          formGroup.controls.loanAttorneyInfo.dirty)
      )
        body["notificationType"] = NOTIFICATION_TYPE.PERSONAL_DETAIL;
      if (
        !isIncomplete &&
        isImage &&
        (formGroup.controls.personalInfo.dirty ||
          formGroup.controls.personalInfo.dirty ||
          formGroup.controls.contactInfo.dirty ||
          formGroup.controls.employmentInfo.dirty ||
          formGroup.controls.dependentsInfo.dirty ||
          formGroup.controls.loanAttorneyInfo.dirty)
      )
        body["notificationType"] = NOTIFICATION_TYPE.BOTH;
    }
    this.classifcation.subscribe((value) => {
      if (value.length) {
        body["loanDetails"]["propertyClassification"] = value;
      }
    });
    if (localStorage.getItem("docs"))
      body.documents = JSON.parse(localStorage.getItem("docs"));
    let result;
    if (body.loanId) {
      result = await this.updateApplication(body);
    } else {
      result = await this.createApplication(body);
    }
    // this.isLoanding.next(true);
    if (isDraft && redirect) {
      this.isLoanding.next(true);
      this.$router.navigateByUrl(LOAN_APPLICATIONS_ROUTE.url);
      this.$dailog.closeAll();
      localStorage.removeItem("docs");
    }
    return result;
  }

  async formatData(
    saveAsDraft: boolean = false,
    applicationStatus?,
    isIncomplete?
  ) {
    const { formGroup } = this.application;
    if (formGroup.valid || saveAsDraft) {
      const body = window.deepCopy(formGroup.getRawValue());
      debugger;
      if (applicationStatus) {
        body.applicationStatus = applicationStatus;
      }
      if (saveAsDraft) {
        body.applicationStatus = LOAN_APPLICATION_STATUS.DRAFT.value;
      } else {
        body.applicationStatus = LOAN_APPLICATION_STATUS.NEW.value;
      }
      // await this.$uploadFiles(body.documents);
      if (body.personalInfo.birthDate) {
        const { date, month, year } = body.personalInfo?.birthDate;
        body.personalInfo.birthDate =
          date && month && year ? `${month + 1}/${date}/${year}` : "";
      }
      if (
        body.personalInfo.spouseInfo &&
        body.personalInfo?.spouseInfo?.birthDate
      ) {
        const { date, month, year } = body.personalInfo.spouseInfo.birthDate;
        body.personalInfo.spouseInfo.birthDate =
          date && month && year ? `${month + 1}/${date}/${year}` : "";
      }
      if (
        body.personalInfo.coBorrowerInfo &&
        body.personalInfo.coBorrowerInfo?.birthDate
      ) {
        const { date, month, year } =
          body.personalInfo.coBorrowerInfo.birthDate;
        body.personalInfo.coBorrowerInfo.birthDate =
          date && month && year ? `${month + 1}/${date}/${year}` : "";
      }
      if (this.loanId) {
        body.loanId = this.loanId;
      }
      if (!body.personalInfo.birthDate) {
        let dob = this.$pqService.formGroup.value.other.dob;
        let date = new Date(dob.year, dob.month, dob.date);
        body.personalInfo.birthDate = `${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()}`;
      }
      return body;
    } else {
      return Promise.reject(new Error("Form is Invalid"));
    }
  }

  async uploadMedias(documents) {
    const data = Object.assign({}, this.application.documentsGroup.value);
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = await this.uploadedMediasArray(data[key], key);
      }
    }
    documents = data;
    return;
  }

  async uploadedMediasArray(data, key) {
    const files = data.map(({ url }) => url);
    const urls: string[] = await this.$uploader.uploadFiles(
      files.filter((file) => file instanceof File)
    );
    data.map((el) => {
      if (el.url instanceof File) {
        el.url = urls.shift();
      }
      return el;
    });
    return data;
  }

  // data handlers
  async $uploadFiles(propertyDocs: any, file?) {
    const { documentsGroup } = this.application;
    if (documentsGroup.valid) {
      const {
        legalDocument = [],
        incomeDocument = [],
        colleteralDoc = [],
      } = documentsGroup.value;
      const files = [
        ...legalDocument.map(({ url }) => url),
        ...incomeDocument.map(({ url }) => url),
        ...colleteralDoc.map(({ url }) => url),
      ];
      let urls: string[];
      urls = [await this.$uploader.uploadFile(file)];
      if (legalDocument.length) {
        propertyDocs.legalDocument = legalDocument
          .map((file) => {
            if (file.url instanceof File) {
              file.url = urls.shift();
              file.status = "Pending";
              file.createdAt = new Date().getTime();
              return file;
            }
            if (!file.url) {
              delete file.createdAt;
            }
            return file;
          })
          .filter((file) => file.documentRequired.length);
      }
      if (incomeDocument.length) {
        propertyDocs.incomeDocument = incomeDocument
          .map((file) => {
            if (file.url instanceof File) {
              file.url = urls.shift();
              file.status = "Pending";
              file.createdAt = new Date().getTime();
              return file;
            }
            if (!file.url) {
              delete file.createdAt;
            }
            return file;
          })
          .filter((file) => file.documentRequired.length);
      }
      if (colleteralDoc.length) {
        propertyDocs.colleteralDoc = colleteralDoc
          .map((file) => {
            if (file.url instanceof File) {
              file.url = urls.shift();
              file.status = "Pending";
              file.createdAt = new Date().getTime();
              return file;
            }
            if (!file.url) {
              delete file.createdAt;
            }
            return file;
          })
          .filter((file) => file.documentRequired.length);
      }
    } else {
    }
  }

  async $uploadFile(docType, index, file, propertyDocs) {
    const { documentsGroup } = this.application;
    let url = await this.$uploader.uploadFile(file);
    if (documentsGroup.value[`${docType}`].length) {
      propertyDocs[`${docType}`] = documentsGroup.value[`${docType}`]
        .map((file, currentIndex) => {
          if (index == currentIndex) {
            file.url = url;
            file.status = "Pending";
            file.createdAt = new Date().getTime();
            return file;
          }
          if (!file.url || file.url instanceof File) {
            delete file.status;
            delete file.createdAt;
          }
          return file;
        })
        .filter((file) => file.documentRequired.length);
    }
  }

  navigateToInvalidStep(currentPath: string) {
    const {
      personalInfoGroup,
      contactInfoGroup,
      loanDetailsGroup,
      employmentInfoGroup,
      dependentsInfoArray,

      documentsGroup,
    } = this.application;
    const steps = [
      { route: LOAN_PERSONAL_ROUTE, group: personalInfoGroup },
      { route: LOAN_CONTACT_ROUTE, group: contactInfoGroup },
      { route: LOAN_INFO_ROUTE, group: loanDetailsGroup },
      { route: LOAN_EMPLOYMENT_ROUTE, group: employmentInfoGroup },
      { route: LOAN_OTHER_ROUTE, group: dependentsInfoArray },
      { route: LOAN_REQUIRED_ROUTE, group: documentsGroup },
    ];
    const currentIndex = steps.findIndex(({ route }) => {
      return route.path === currentPath;
    });
    const invalidStep = steps.find(({ group }, index: number) => {
      if (index > currentIndex) {
        return false;
      }
      return group.invalid;
    });
    if (invalidStep) {
      this.$router.navigate([invalidStep.route.url], {
        queryParamsHandling: "preserve",
      });
      return true;
    }
  }
  async loanDetails(id: string) {
    this.loanId = id;
    const { data } = await this.$http.get(`~/user/loan/${id}`);
    localStorage.setItem("loanTypeSelected", data.loanType);
    this.isEditMode =
      data.applicationStatus === LOAN_APPLICATION_STATUS.DRAFT.value;
    if (!this.isEditMode) {
      this.application.formGroup.disable();
    }
    return data;
  }
  handleSummerRoute() {
    this.$router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url: UrlTree = this.$router.parseUrl(event.url);
        if (url.root && url.root.children.primary) {
          const { segments } = url.root.children.primary;
          this.$summary.next(
            segments[segments.length - 1].path === LOAN_REVIEW_ROUTE.path
          );
        }
      }
    });
  }
  private $handleBankUpdate() {
    const { fixedPeriod, loanTerm } =
      this.application.loanDetailsGroup.controls;
    fixedPeriod.valueChanges
      .pipe(
        filter((val) => val),
        distinctUntilChanged()
      )
      .subscribe(() => {
        setTimeout(() => {
          this.updateApplicationData(fixedPeriod, loanTerm);
        });
      });
    loanTerm.valueChanges
      .pipe(
        filter((val) => val),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        setTimeout(() => {
          this.updateApplicationData(loanTerm, fixedPeriod);
        });
      });
  }
  async updateApplicationData(
    control: AbstractControl,
    otherControl: AbstractControl
  ) {
    const {
      personalInfo,
      propertyInfo,
      loanDetails,
      bankInfo,
      employmentInfo,
      loanType,
    } = this.application.formGroup.value;
    if (!this.patchValue) {
      return;
    }
    const { spouseInfo = {}, coBorrowerInfo = {} } = personalInfo;
    const body: any = window.deepCopy({
      property: propertyInfo,
      loanType: loanType,
      loan: {
        type: loanDetails.loanType,
        term: loanDetails.loanTerm,
        amount: loanDetails.loanAmount,
        percent: loanDetails.loanPercent,
        fixingPeriod: loanDetails.fixedPeriod,
      },
      employmentInfo: {
        rank: employmentInfo.rank,
        type: employmentInfo.type,
        tenure: employmentInfo.tenure,
        income: personalInfo.monthlyIncome,
      },
      other: {
        dob: personalInfo.birthDate,
        nationality: personalInfo.nationality,
        localVisa: personalInfo.localVisa,
        creditCard: personalInfo.creditCard,
        prevLoans: personalInfo.prevLoans,
        otherIncome: {
          status: !!personalInfo.otherIncome,
          monthlyIncome: personalInfo.otherIncome || 0,
        },
        married: {
          status: !!spouseInfo.monthlyIncome,
          spouseMonthlyIncome: spouseInfo.monthlyIncome || 0,
        },
        coBorrower: {
          status: !!coBorrowerInfo.monthlyIncome,
          coBorrowerMonthlyIncome: coBorrowerInfo.monthlyIncome || 0,
        },
      },
    });
    const params = new HttpParams().set("bankId", bankInfo.bankId);
    const dob = body.other.dob;
    delete body.other.dob;
    // body.other.age = new Date().getFullYear() - new Date(dob.year, dob.month, dob.date).getFullYear();
    body.other.age = 25;
    const { data } = await this.$http.patch<any>(
      `~/user/loan/pre-application`,
      cleanData(body),
      { params }
    );
    if (data.length) {
      this.application.loanDetailsGroup.patchValue({
        rate: data[0].interestRate,
        monthlyRepayment: data[0].monthlyPayment,
      });
      otherControl.setErrors(null);
    } else {
      control.setErrors({ custom: `Bank don't allow for this duration.` });
    }
  }

  getIp() {
    fetch("https://extreme-ip-lookup.com/json/")
      .then((res) => res.json())
      .then((response) => {
        this.ipAddress = response.query;
      });
  }

  async onCheckPartnerHandler(id) {
    const resp = this.$http.get(`~/user/partner/${id}`);
    return resp;
  }

  clearPartnerData() {
    this.$global.partnerData = null;
    this.$global.partnerDataEvent.emit(this.$global.partnerData);
  }
}
