import { Directive, AfterViewInit, Input, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[nookButtonLoader]'
})
export class ButtonLoaderDirective implements AfterViewInit {
  private $loaderStatus = false;
  @Input()
  set nookButtonLoader(status: any) {
    this.$loaderStatus = !!status;
    if (this.$loaderElement) {
      this._updateStatus();
    }
  }
  private $loaderElement: HTMLElement;
  wrapper: HTMLElement = null;
  @HostBinding('class.button-loader__wrapper')
  get loaderWrapper() {
    return this.$loaderStatus;
  }
  constructor(private $elRef: ElementRef<HTMLButtonElement>) {
  }
  ngAfterViewInit() {
    this.$loaderElement = document.createElement('span');
    this.$loaderElement.className = 'button-loader';
    this.$elRef.nativeElement.appendChild(this.$loaderElement);
    this._updateStatus();
  }
  private _updateStatus() {
    if (this.$loaderStatus) {
      this.$loaderElement.classList.add('button-loader--active');
    } else {
      this.$loaderElement.classList.remove('button-loader--active');
    }
  }

}
