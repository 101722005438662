import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';


export const MY_DATE_FORMATS = {
  parse: {
     dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
     dateInput: 'input',
     monthYearLabel: {year: 'numeric', month: 'short'},
     dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
     monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@Injectable()
export class DateAdapterService extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
       const day = date.getDate();
       const month = date.getMonth() + 1;
       const year = date.getFullYear();
       // Return the format as per your requirement
       return `${year}-${month}-${day}`;
    } else {
       return date.toDateString();
    }
 }
}
