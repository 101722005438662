import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
// import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ValidationErrorModule } from 'src/app/pipes/validation-error';

const Modules = [
  // SharedModule,
  MatInputModule,
  MatFormFieldModule,
  ReactiveFormsModule,
  ValidationErrorModule
];

@NgModule({
  imports: Modules,
  exports: Modules
})
export class InputSharedModule { }
