export const MESSAGES = {
	ERROR: {
		$401: 'Your session is expired, please login again',
		$404: 'Resource not found',
		$500: 'Internal Server Error',
		$504: 'Something went wrong',
		UNKNOWN: 'Something went wrong'
	},
	NAVIGATION: {
		NOT_FOUND: 'Page not found'
	},
	LOGOUT: 'You are logged out successfully',
	LOGIN: 'You are logged in successfully',
	OFFLINE: 'You are offline, please connect to internet and retry',
	DELETED: 'Sorry! This user has been deleted',
	BLOCKED: 'Sorry! This user has been blocked by the Admin',
	// POST: 'Are you sure , you want to post ?',
	CONFIRM: {
		LOGOUT: {
			title: 'Confirm Logout',
			message: 'Are you sure, you want to logout?'
		}
	},
	CONFIRM_POST: {
		POST: {
			title: 'Submit Application',
			message: 'Are you sure you want to submit ?'
		}
	},
	CONFIRM_SAVE: {
		POST: {
			title: 'Save Pre-Qualification',
			message: 'Please login or create an account to save pre-qualification results'
		}
	}
};
export const INVOICE_SUCCESS = 'Successfull ';

export const COMPLETE_APPLICATION = "Your application status will be incomplete since you haven't saved your application"

export const NOTIFICATION_TYPE = {
    IMAGE:'image',
    PERSONAL_DETAIL:'personal-detail',
    BOTH:'both'

}