import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { GlobalService } from "@global";
import { PreQualificationService } from "src/app/modules/layout/pages/pre-qualification/service/pre-qualification.service";
import { LOAN_REVIEW_ROUTE } from "src/app/modules/online-homeloan/constants";
import { HomeLoanService } from "src/app/modules/online-homeloan/services/home-loan.service";
import { PRE_LISTING_ROUTE } from "src/app/modules/pre-qualification/constants";

@Component({
  selector: "nook-loan-detail",
  templateUrl: "./loan-detail.component.html",
  styleUrls: ["./loan-detail.component.scss"],
})
export class LoanDetailComponent implements OnInit {
  loanReviewUrl = LOAN_REVIEW_ROUTE.url;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoanDetailComponent>,
    private $global: GlobalService,
    private $preQualificationService: PreQualificationService,
    private $router: Router,
    private $homeService: HomeLoanService
  ) {}

  ngOnInit(): void {}

  onViewDetails(id) {
    const x = window.open("", "blank");
    this.$preQualificationService.getPreQualificationDetails(id).then((res) => {
      const { property, employmentInfo, other, loan, loanType } = res["data"];
      other["dob"] = {
        month: new Date(res["data"].other.dob).getMonth(),
        date: new Date(res["data"].other.dob).getDate(),
        year: new Date(res["data"].other.dob).getFullYear(),
      };
      const application = this.$global.encodeData({
        property,
        employmentInfo,
        other,
        loan,
        loanType,
        id,
      });
      const tree = <any>this.$router
        .createUrlTree([PRE_LISTING_ROUTE.url], {
          queryParams: { application, refferalId: res["data"].partnerId },
        })
        .toString();
      x.location = tree;
    });
    localStorage.removeItem("showPopup");
    this.dialogRef.close();
  }

  onDocRequired(id) {
    this.$homeService.loanDetails(id).then((res) => {
      const {
        bankInfo,
        personalInfo,
        employmentInfo,
        propertyInfo,
        applicationStatus,
        loanDetails,
        tradeReferences,
        contactInfo,
        dependentsInfo,
        referenceInfo,
      } = res;
      const application = btoa(
        JSON.stringify({
          bankInfo,
          personalInfo,
          employmentInfo,
          propertyInfo,
          id,
          applicationStatus,
          loanDetails,
          tradeReferences,
          contactInfo,
          dependentsInfo,
          referenceInfo,
        }).replace(/[\u00A0-\u2666]/g, function (c) {
          return "&#" + c.charCodeAt(0) + ";";
        })
      );
      this.$router.navigate(["application/required-information"], {
        queryParams: { request: application, isUpload: true },
      });
    });
    this.dialogRef.close();
  }
}
