import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TokenService } from '@token';
import { HttpClient } from '@angular/common/http';
import { PopupService } from '@popup';
import { MESSAGES } from 'src/app/constants/message.constants';
import { first } from 'rxjs/operators';
import { environment } from '@environment';

import { stat } from 'fs';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private $isAuthenticated = false;
  get isAuthenticated(): boolean {
    return this.$isAuthenticated || this.$token.hasValue;
  }
  private $profile: ReplaySubject<any> = new ReplaySubject(1);
  readonly changes = this.$profile.asObservable();
  constructor(
    private $http: HttpClient,
    private $token: TokenService,
    private $popup: PopupService
    ) { }

  setProfile(data: any) {
    this.$isAuthenticated = true;
    this.$profile.next(data);
  }

  clear() {
    this.$token.reset();
    this.$isAuthenticated = false;
    this.$profile.next(null);
    this.$popup.success(MESSAGES.LOGOUT);
  }

  async fetchProfile() {
    const { data }: any = await this.$http.get('~/user/profile').toPromise();
    return data;
  }
  async getProfile() {
    const data = await this.fetchProfile();
    this.setProfile(data);
  }
  async update(value: any) {
    const { message }: any = await this.$http.patch(`~/user/profile`, value).toPromise();
    this.$popup.success(message);
  }
  async updatePassword(body: any) {
    const resp = await this.$http.patch<Api.Response<any>>(`~/user/change-password`, body).toPromise();
    this.$popup.success(resp.message);
  }
  async updatePicture(url: string) {
    await this.update({ profilePicUrl: url });
    const value = await this.changes.pipe(first()).toPromise();
    this.$profile.next({ ...value, profilePicUrl: url });
  }
  async updateIsSSO(status: any) {
    await this.update({ isSSO: status });
    const value = await this.changes.pipe(first()).toPromise();
    this.$profile.next({ ...value, isSSO: status });
  }

  async updateBanner(url: string) {
    await this.update({ backGroundImageUrl: url });
    const value = await this.changes.pipe(first()).toPromise();
    this.$profile.next({ ...value, backGroundImageUrl: url });
  }

  async postAccountDetail(body) {
    const resp = await this.$http.post<Api.Response<any>>(`~/user/bankDetail`, body).toPromise();
    this.$popup.success(resp.message);
  }
  /**
   * @description A function to check availability of email and username.
   * @param body Data {email, username, password}
   */
  async checkValidity(body: any): Promise<void> {
    await this.$http.post<any>(`~/user/register`, body).toPromise();
  }
  async register(body: any) {
    if (!body.partnerId || !body.partnerName) {
      delete body.partnerId;
      delete body.partnerName;
    }
    const { statusCode, message, data } = await this.$http.patch<any>(`~/user/complete-registration`, body).toPromise();
    if (statusCode === 200) {
      this.$token.rememberToken(true);
      this.$token.value = data.accessToken;
      await this.setProfile(data.formatedData);
      this.$popup.success(message);
      return true;
    }
    this.$popup.error(message);
    return false;
  }
  async login({ rememberToken, ...body }: any): Promise<void> {
    body = {
      ...body,
      deviceToken: 'as'
    };
    if (!body.partnerId || !body.partnerName) {
      delete body.partnerId;
      delete body.partnerName;
    }
    const { statusCode, data, message } = await this.$http.post<any>(`~/user/login`, body).toPromise();
    if (statusCode === 200) {
      this.$token.rememberToken(rememberToken);
      this.$token.value = data.accessToken;
      await this.setProfile(data.formatedData);
      this.$popup.success(message);
      return;
    }
    this.$popup.error(message);
    return Promise.reject(new Error('Failed'));
  }
  async forgetPassword(email: string) {
    const resp = await this.$http.patch<any>(`~/user/forgetPassword`, { email }).toPromise();
    if (resp.statusCode === 200) {
      this.$popup.success(resp.message);
      return true;
    }
    this.$popup.error(resp.message);
    return false;
  }
  async resetPassword(link: string, password: string) {
    const resp = await this.$http.patch<any>(`~/user/reset-password`, { link, password }).toPromise();
    if (resp.statusCode === 200) {
      this.$popup.success(resp.message);
      return true;
    }
    this.$popup.error(resp.message);
    return false;
  }

  async logout() {
    const resp = await this.$http.patch<any>('~/user/logout', { deviceId: localStorage.getItem('deviceId') }).toPromise();
    if (resp.statusCode === 200) {
      this.$popup.success(resp.message);
      localStorage.clear();
      return true
    } else {
      this.$popup.error(resp.message);
      return false;
    }
  }

  async checkLogin() {
    const resp: any = await this.$http.patch<any>('~/user/login/status', { deviceId: localStorage.getItem('deviceId') }).toPromise();
    if (resp?.data[0]?.loginStatus) {
      return this.$token.hasValue;
    } else {
      localStorage.clear();
      location.href = `${environment.appNook}/public/login`;
      return false;
    }
  }

}
