import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private $counter: BehaviorSubject<number> = new BehaviorSubject(0);
  readonly status: Observable<boolean> = this.$counter.pipe(map(val => !!val));
  constructor(private $router: Router) {
    this.$handleRouting();
  }
  private $handleRouting() {
    this.$router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.$counter.next(this.$counter.getValue() + 1);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.$counter.next(this.$counter.getValue() - 1);
      }
    });
  }
}
