import { Injectable } from '@angular/core';

export enum Network {
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM =  'INSTAGRAM',
}

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {
  socialLinks = {
    TWITTER: 'http://www.twitter.com/nookphilippines',
    FACEBOOK: 'http://www.facebook.com/nookphilippines',
    INSTAGRAM: 'http://www.instagram.com/nookphilippines',
  };
  constructor() { }
  share(network: Network) {
    const url = location.href;
    // open a tweet form
    if (network === Network.TWITTER) {
      window.open(`http://twitter.com/intent/tweet?url=${url}`, 'tweet');
    }
    // open a post form
    if (network === Network.FACEBOOK) {
      window.open(`https://www.facebook.com/sharer.php?u=${url}`, '_blank');
    }
    // open a post form
    if (network === Network.INSTAGRAM) {
      window.open(`https://www.instagram.com/?url=${url}`, '_blank');
    }
  }
  /**
   * @description Open Social Media Page for Twitter, Facebook and Instagram
   */
  open(network: Network) {
    window.open(this.socialLinks[network], '_blank');
  }
}
