import { NgModule } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { SocialShareModule } from '../social-share/social-share.module';
import { AuthModule } from 'src/app/modules/auth';
import { RouterModule } from '@angular/router';
import { AmountPipe } from './pipes/amount/amount.pipe';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { PopupModule } from '@popup';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmBoxModule } from '../confirm-box';
import { LoanDetailComponent } from './component/loan-detail/loan-detail.component';
import { FormatModule } from 'src/app/pipes/format';
import { DateModule } from 'src/app/pipes/date/date.module';



const Modules = [
  // AuthModule,
  PopupModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  ConfirmBoxModule,
  SocialShareModule
];

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    LoanDetailComponent,
    AmountPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...Modules,
    FormatModule,
    DateModule
  ],
  exports: [
    BreadcrumbComponent,
    HeaderComponent,
    FooterComponent,
    AmountPipe,
    ...Modules,
  ]
})
export class SharedModule { }
