import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

const currencyPipe = new CurrencyPipe('en-PH');

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(value: any, decimal: number = 0): any {
    const price = currencyPipe.transform(value, 'PHP', 'code', `1.${decimal}-${decimal}`);
    return price ? price.replace('PHP', '') : price;
  }

}
