import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PreQualificationService {
  constructor(private $http: HttpClient) {}

  async fetchPreQualifications(params: HttpParams = new HttpParams()) {
    const resp = await this.$http
      .get<Api.Response<any>>("~/user/prequalification", { params })
      .toPromise();
    return resp.data;
  }

  async getPreQualificationDetails(id) {
    const resp = await this.$http
      .get(`~/user/prequalification/${id}`)
      .toPromise();
    return resp;
  }
}
