<!-- <nook-header></nook-header>
<main>
    <router-outlet></router-outlet>
</main>
<nook-footer></nook-footer> -->


<div class="wrapper">
    <nook-header></nook-header>
    <div class="wrapper-inner">
        <router-outlet></router-outlet>
    </div>
    <nook-footer></nook-footer>
</div>
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>