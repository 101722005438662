import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './view/app.component';
import { SharedModule } from './common/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS, DateAdapterService } from './services/date-adapter/date-adapter.service';
import { InterceptorModule } from './common/interceptor/interceptor.module';
import { ProfileService } from '@profile';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '@environment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    InterceptorModule,
    MatProgressBarModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: DateAdapterService },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  
  ], 
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(profile: ProfileService) {
    if (profile.isAuthenticated) {
      profile.getProfile();
    }
  }
}
