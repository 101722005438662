<div class="account-inner account_inner_wrapper">
    <!-- <div class="bannersec" style="background-image: url(assets/banner/NookGeorgettev2.png)">
        <h1>Your New Home Starts With Nook</h1>
        <p>Welcome back, Please log in</p>
    </div> -->
    <div class="container">
        <div class="formsec_wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- formsec_wrapper end -->
</div>