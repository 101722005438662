import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { EMAIL, PASSWORD } from 'src/app/constants/form.constants';
import { DASHBOARD_ROUTE, VIDEO_URL, CustomValidators, MESSAGES } from 'src/app/constants';
import { ProfileService } from '@profile';
import { GlobalService } from '@global';
import { PreQualificationService } from 'src/app/modules/pre-qualification/services/pre-qualification.service';
import { environment } from '@environment';
import { DataLayerService } from 'src/app/services/data-layer/data-layer.service';
import { PopupComponent } from 'src/app/common/popup/components/popup.component';

@Component({
  selector: 'nook-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  emailMaxLength = EMAIL.maxLength;
  passwordMaxLength = PASSWORD.maxLength;
  refferalId: any;
  profileData: any;
  get videoUrl(): string {
    return VIDEO_URL;
  }
  constructor(
    fb: FormBuilder,
    private $router: Router,
    private $profile: ProfileService,
    private _snackBar: MatSnackBar,
    // private $dialogRef: MatDialogRef<any>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private $global: GlobalService,
    route: ActivatedRoute,
    private $pqService: PreQualificationService,
    private _dataLayerService: DataLayerService
  ) {
    this.formGroup = fb.group({
      email: [null, [Validators.required, Validators.maxLength(EMAIL.maxLength),...CustomValidators.email]],
      password: [null, [Validators.required, ...CustomValidators.password]],
      rememberToken: [false, Validators.required]
    });
    route.queryParams.subscribe(({application, refferalId}) => {
      if (refferalId) {
        this.refferalId =  refferalId;
        this.checkPartnerHandler();
      } else {
        this.$global.partnerData = null;
        this.$global.partnerDataEvent.emit(this.$global.partnerData);
      }
    });
  }

  ngOnInit() {
    this.$profile.changes.subscribe((data) => {
      this.profileData = data;
    });
  }

  checkPartnerHandler() {
    this.$pqService.onCheckPartnerHandler(this.refferalId).then(res => {
      this.$global.partnerData = res['data'];
      this.$global.partnerDataEvent.emit(res['data']);
    });
  }
  onSubmitHandler() {
    if (this.formGroup.valid && this.formGroup.enabled) {
      const value = this.formGroup.value;
      const partnerEncryptedData = this.$global.partnerData;
      if (partnerEncryptedData) {
        value.partnerName = partnerEncryptedData.name;
        value.partnerId = partnerEncryptedData.shortId;
      }
      this.formGroup.disable();
      value['deviceId'] = this.$global.getDeviceId();
      this.$profile.login(value).then(() => {
        const { stopRedirect } = false|| {} as any;
        if (!stopRedirect) {
          if(localStorage.getItem('isLoggedIn')){
            this.$global.isLoggedIn.next(true);
          }
          let scheme = JSON.parse(localStorage.getItem('scheme'));
          const { _id, email } = this.profileData;

          this._dataLayerService.logEvent({
            event: "login_form_submission",
            user_data: {
              user_id: _id,
              email_address: email,
            },
          });

          if(scheme){
            this.$global.applyScheme.next(scheme);
          }else{
            this.$router.navigate([DASHBOARD_ROUTE.url])
          }
        }
        this._snackBar.openFromComponent(PopupComponent, {
          data: {message: MESSAGES.LOGIN, type: "SUCCESS"},
          duration: 2000,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        });
        // this.$dialogRef.close(true);
      }).catch((err) => {
        localStorage.removeItem('deviceId');
        this.formGroup.enable();

        // TODO: This part should be done at popup service, I don't know why that is not working
        this._snackBar.openFromComponent(PopupComponent, {
          data: {message: err.error.message, type: "ERROR"},
          duration: 2000,
          horizontalPosition: 'end',
          verticalPosition: 'top'
        });

      });
    } else if (this.formGroup.enabled) {
      this.formGroup.markAllAsTouched();
      this._snackBar.openFromComponent(PopupComponent, {
        data: {message: "Please complete all fields correctly.", type: "ERROR"},
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }
  }
  redirectToRegister() {
    // this.changeTab.emit('REGISTER');
    this.$router.navigate(['public/register'])
  }
  onForgotPassword() {
    this.$router.navigate(['public/forgot-password'])
    // this.changeTab.emit('FORGOT');
    // this.$dialogRef.close();
    // this.$dialog.open(ForgotPasswordComponent, { width: '360px', disableClose: true, data: null });
  }

}
