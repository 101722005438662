export const COUNTRIES_FLAGS = [
    {
      "name": "Afghanistan",
      "iso_code": "+93",
      "country_code": "+93",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_af.png"
    },
    {
      "name": "Andorra",
      "iso_code": "+376",
      "country_code": "+376",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ad.png"
    },
    {
      "name": "AmericanSamoa",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_as.png"
    },
    {
      "name": "Angola",
      "iso_code": "+244",
      "country_code": "+244",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ao.png"
    },
    {
      "name": "Albania",
      "iso_code": "+355",
      "country_code": "+355",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_al.png"
    },
    {
      "name": "Algeria",
      "iso_code": "+213",
      "country_code": "+213",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_dz.png"
    },
    {
      "name": "Anguilla",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ai.png"
    },
    {
      "name": "Antarctica",
      "iso_code": "+672",
      "country_code": "+672",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_aq.png"
    },
    {
      "name": "Argentina",
      "iso_code": "+54",
      "country_code": "+54",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ar.png"
    },
    {
      "name": "Antigua and Barbuda",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ag.png"
    },
    {
      "name": "Congo, The Democratic Republic of the",
      "iso_code": "+243",
      "country_code": "+243",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cd.png"
    },
    {
      "name": "Costa Rica",
      "iso_code": "+506",
      "country_code": "+506",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cr.png"
    },
    {
      "name": "Ivory Coast",
      "iso_code": "+225",
      "country_code": "+225",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ci.png"
    },
    {
      "name": "Cook Islands",
      "iso_code": "+682",
      "country_code": "+682",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ck.png"
    },
    {
      "name": "Croatia",
      "iso_code": "+385",
      "country_code": "+385",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_hr.png"
    },
    {
      "name": "Cuba",
      "iso_code": "+53",
      "country_code": "+53",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cu.png"
    },
    {
      "name": "Aruba",
      "iso_code": "+297",
      "country_code": "+297",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_aw.png"
    },
    {
      "name": "Armenia",
      "iso_code": "+374",
      "country_code": "+374",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_am.png"
    },
    {
      "name": "Australia",
      "iso_code": "+61",
      "country_code": "+61",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_au.png"
    },
    {
      "name": "Curacao",
      "iso_code": "+599",
      "country_code": "+599",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cw.png"
    },
    {
      "name": "Cyprus",
      "iso_code": "+357",
      "country_code": "+357",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cy.png"
    },
    {
      "name": "Austria",
      "iso_code": "+43",
      "country_code": "+43",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_at.png"
    },
    {
      "name": "Bahamas",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bs.png"
    },
    {
      "name": "Czech Republic",
      "iso_code": "+420",
      "country_code": "+420",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cz.png"
    },
    {
      "name": "Denmark",
      "iso_code": "+45",
      "country_code": "+45",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_dk.png"
    },
    {
      "name": "Dominica",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_dm.png"
    },
    {
      "name": "Djibouti",
      "iso_code": "+253",
      "country_code": "+253",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_dj.png"
    },
    {
      "name": "Dominican Republic",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_do.png"
    },
    {
      "name": "Azerbaijan",
      "iso_code": "+994",
      "country_code": "+994",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_az.png"
    },
    {
      "name": "Egypt",
      "iso_code": "+20",
      "country_code": "+20",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_eg.png"
    },
    {
      "name": "Barbados",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bb.png"
    },
    {
      "name": "Ecuador",
      "iso_code": "+593",
      "country_code": "+593",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ec.png"
    },
    {
      "name": "El Salvador",
      "iso_code": "+503",
      "country_code": "+503",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sv.png"
    },
    {
      "name": "Bangladesh",
      "iso_code": "+880",
      "country_code": "+880",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bd.png"
    },
    {
      "name": "Estonia",
      "iso_code": "+372",
      "country_code": "+372",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ee.png"
    },
    {
      "name": "Equatorial Guinea",
      "iso_code": "+240",
      "country_code": "+240",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gq.png"
    },
    {
      "name": "Eritrea",
      "iso_code": "+291",
      "country_code": "+291",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_er.png"
    },
    {
      "name": "Bahrain",
      "iso_code": "+973",
      "country_code": "+973",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bh.png"
    },
    {
      "name": "Ethiopia",
      "iso_code": "+251",
      "country_code": "+251",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_et.png"
    },
    {
      "name": "Belarus",
      "iso_code": "+375",
      "country_code": "+375",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_by.png"
    },
    {
      "name": "Falkland Islands (Malvinas)",
      "iso_code": "+500",
      "country_code": "+500",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fk.png"
    },
    {
      "name": "Faroe Islands",
      "iso_code": "+298",
      "country_code": "+298",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fo.png"
    },
    {
      "name": "Finland",
      "iso_code": "+358",
      "country_code": "+358",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fi.png"
    },
    {
      "name": "Belize",
      "iso_code": "+501",
      "country_code": "+501",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bz.png"
    },
    {
      "name": "Fiji",
      "iso_code": "+679",
      "country_code": "+679",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fj.png"
    },
    {
      "name": "French Guiana",
      "iso_code": "+594",
      "country_code": "+594",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gf.png"
    },
    {
      "name": "France",
      "iso_code": "+33",
      "country_code": "+33",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fr.png"
    },
    {
      "name": "Belgium",
      "iso_code": "+32",
      "country_code": "+32",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_be.png"
    },
    {
      "name": "French Polynesia",
      "iso_code": "+689",
      "country_code": "+689",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pf.png"
    },
    {
      "name": "French Southern Territories",
      "iso_code": "+262",
      "country_code": "+262",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tf.png"
    },
    {
      "name": "Benin",
      "iso_code": "+229",
      "country_code": "+229",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bj.png"
    },
    {
      "name": "Germany",
      "iso_code": "+49",
      "country_code": "+49",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_de.png"
    },
    {
      "name": "Gambia",
      "iso_code": "+220",
      "country_code": "+220",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gm.png"
    },
    {
      "name": "Georgia",
      "iso_code": "+995",
      "country_code": "+995",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ge.png"
    },
    {
      "name": "Ghana",
      "iso_code": "+233",
      "country_code": "+233",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gh.png"
    },
    {
      "name": "Gibraltar",
      "iso_code": "+350",
      "country_code": "+350",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gi.png"
    },
    {
      "name": "Gabon",
      "iso_code": "+241",
      "country_code": "+241",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ga.png"
    },
    {
      "name": "Greece",
      "iso_code": "+30",
      "country_code": "+30",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gr.png"
    },
    {
      "name": "Bhutan",
      "iso_code": "+975",
      "country_code": "+975",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bt.png"
    },
    {
      "name": "Bermuda",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bm.png"
    },
    {
      "name": "Greenland",
      "iso_code": "+299",
      "country_code": "+299",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gl.png"
    },
    {
      "name": "Guam",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gu.png"
    },
    {
      "name": "Guatemala",
      "iso_code": "+502",
      "country_code": "+502",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gt.png"
    },
    {
      "name": "Guadeloupe",
      "iso_code": "+590",
      "country_code": "+590",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gp.png"
    },
    {
      "name": "Guernsey",
      "iso_code": "+44",
      "country_code": "+44",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gg.png"
    },
    {
      "name": "Grenada",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gd.png"
    },
    {
      "name": "Bolivia, Plurinational State of",
      "iso_code": "+591",
      "country_code": "+591",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bo.png"
    },
    {
      "name": "Bonaire",
      "iso_code": "+599",
      "country_code": "+599",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bq.png"
    },
    {
      "name": "Guyana",
      "iso_code": "+595",
      "country_code": "+595",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gy.png"
    },
    {
      "name": "Haiti",
      "iso_code": "+509",
      "country_code": "+509",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ht.png"
    },
    {
      "name": "Guinea",
      "iso_code": "+224",
      "country_code": "+224",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gn.png"
    },
    {
      "name": "Holy See (Vatican City State)",
      "iso_code": "+379",
      "country_code": "+379",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_va.png"
    },
    {
      "name": "Hong Kong",
      "iso_code": "+852",
      "country_code": "+852",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_hk.png"
    },
    {
      "name": "Honduras",
      "iso_code": "+504",
      "country_code": "+504",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_hn.png"
    },
    {
      "name": "Guinea-Bissau",
      "iso_code": "+245",
      "country_code": "+245",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gw.png"
    },
    {
      "name": "Hungary",
      "iso_code": "+36",
      "country_code": "+36",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_hu.png"
    },
    {
      "name": "Heard Island and McDonald Islands",
      "iso_code": "",
      "country_code": "",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_hm.png"
    },
    {
      "name": "India",
      "iso_code": "+91",
      "country_code": "+91",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_in.png"
    },
    {
      "name": "Iceland",
      "iso_code": "+354",
      "country_code": "+354",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_is.png"
    },
    {
      "name": "Iran, Islamic Republic of",
      "iso_code": "+98",
      "country_code": "+98",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ir.png"
    },
    {
      "name": "Indonesia",
      "iso_code": "+62",
      "country_code": "+62",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_id.png"
    },
    {
      "name": "Ireland",
      "iso_code": "+353",
      "country_code": "+353",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ie.png"
    },
    {
      "name": "Bosnia and Herzegovina",
      "iso_code": "+387",
      "country_code": "+387",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ba.png"
    },
    {
      "name": "Iraq",
      "iso_code": "+964",
      "country_code": "+964",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_iq.png"
    },
    {
      "name": "Israel",
      "iso_code": "+972",
      "country_code": "+972",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_il.png"
    },
    {
      "name": "Jamaica",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_jm.png"
    },
    {
      "name": "Latvia",
      "iso_code": "+371",
      "country_code": "+371",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lv.png"
    },
    {
      "name": "Japan",
      "iso_code": "+81",
      "country_code": "+81",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_jp.png"
    },
    {
      "name": "Italy",
      "iso_code": "+39",
      "country_code": "+39",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_it.png"
    },
    {
      "name": "Jersey",
      "iso_code": "+44",
      "country_code": "+44",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_je.png"
    },
    {
      "name": "Jordan",
      "iso_code": "+962",
      "country_code": "+962",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_jo.png"
    },
    {
      "name": "Kazakhstan",
      "iso_code": "+7",
      "country_code": "+7",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kz.png"
    },
    {
      "name": "Bouvet Island",
      "iso_code": "+47",
      "country_code": "+47",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bv.png"
    },
    {
      "name": "Botswana",
      "iso_code": "+267",
      "country_code": "+267",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bw.png"
    },
    {
      "name": "Kenya",
      "iso_code": "+254",
      "country_code": "+254",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ke.png"
    },
    {
      "name": "Isle of Man",
      "iso_code": "+44",
      "country_code": "+44",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_im.png"
    },
    {
      "name": "Kiribati",
      "iso_code": "+686",
      "country_code": "+686",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ki.png"
    },
    {
      "name": "North Korea",
      "iso_code": "+850",
      "country_code": "+850",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kp.png"
    },
    {
      "name": "Kosovo",
      "iso_code": "+383",
      "country_code": "+383",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_xk.png"
    },
    {
      "name": "South Korea",
      "iso_code": "+82",
      "country_code": "+82",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kr.png"
    },
    {
      "name": "Brazil",
      "iso_code": "+55",
      "country_code": "+55",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_br.png"
    },
    {
      "name": "Kuwait",
      "iso_code": "+965",
      "country_code": "+965",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kw.png"
    },
    {
      "name": "Kyrgyzstan",
      "iso_code": "+996",
      "country_code": "+996",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kg.png"
    },
    {
      "name": "Lao People's Democratic Republic",
      "iso_code": "+856",
      "country_code": "+856",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_la.png"
    },
    {
      "name": "Mali",
      "iso_code": "+223",
      "country_code": "+223",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ml.png"
    },
    {
      "name": "Malta",
      "iso_code": "+356",
      "country_code": "+356",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mt.png"
    },
    {
      "name": "Marshall Islands",
      "iso_code": "+692",
      "country_code": "+692",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mh.png"
    },
    {
      "name": "Martinique",
      "iso_code": "+596",
      "country_code": "+596",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mq.png"
    },
    {
      "name": "Mauritania",
      "iso_code": "+222",
      "country_code": "+222",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mr.png"
    },
    {
      "name": "Bulgaria",
      "iso_code": "+359",
      "country_code": "+359",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bg.png"
    },
    {
      "name": "Mauritius",
      "iso_code": "+230",
      "country_code": "+230",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mu.png"
    },
    {
      "name": "Lebanon",
      "iso_code": "+961",
      "country_code": "+961",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lb.png"
    },
    {
      "name": "Liberia",
      "iso_code": "+231",
      "country_code": "+231",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lr.png"
    },
    {
      "name": "Lesotho",
      "iso_code": "+266",
      "country_code": "+266",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ls.png"
    },
    {
      "name": "Liechtenstein",
      "iso_code": "+423",
      "country_code": "+423",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_li.png"
    },
    {
      "name": "Libyan Arab Jamahiriya",
      "iso_code": "+218",
      "country_code": "+218",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ly.png"
    },
    {
      "name": "Lithuania",
      "iso_code": "+370",
      "country_code": "+370",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lt.png"
    },
    {
      "name": "Macao",
      "iso_code": "+853",
      "country_code": "+853",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mo.png"
    },
    {
      "name": "Macedonia, The Former Yugoslav Republic of",
      "iso_code": "+389",
      "country_code": "+389",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mk.png"
    },
    {
      "name": "Luxembourg",
      "iso_code": "+352",
      "country_code": "+352",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lu.png"
    },
    {
      "name": "British Indian Ocean Territory",
      "iso_code": "+246",
      "country_code": "+246",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_io.png"
    },
    {
      "name": "Malawi",
      "iso_code": "+265",
      "country_code": "+265",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mw.png"
    },
    {
      "name": "Madagascar",
      "iso_code": "+261",
      "country_code": "+261",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mg.png"
    },
    {
      "name": "Maldives",
      "iso_code": "+960",
      "country_code": "+960",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mv.png"
    },
    {
      "name": "Malaysia",
      "iso_code": "+60",
      "country_code": "+60",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_my.png"
    },
    {
      "name": "Brunei Darussalam",
      "iso_code": "+673",
      "country_code": "+673",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bn.png"
    },
    {
      "name": "Nauru",
      "iso_code": "+674",
      "country_code": "+674",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nr.png"
    },
    {
      "name": "Nepal",
      "iso_code": "+977",
      "country_code": "+977",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_np.png"
    },
    {
      "name": "Cameroon",
      "iso_code": "+237",
      "country_code": "+237",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cm.png"
    },
    {
      "name": "Netherlands",
      "iso_code": "+31",
      "country_code": "+31",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nl.png"
    },
    {
      "name": "Canada",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ca.png"
    },
    {
      "name": "New Caledonia",
      "iso_code": "+687",
      "country_code": "+687",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nc.png"
    },
    {
      "name": "New Zealand",
      "iso_code": "+64",
      "country_code": "+64",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nz.png"
    },
    {
      "name": "Niger",
      "iso_code": "+227",
      "country_code": "+227",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ne.png"
    },
    {
      "name": "Mayotte",
      "iso_code": "+262",
      "country_code": "+262",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_yt.png"
    },
    {
      "name": "Mexico",
      "iso_code": "+52",
      "country_code": "+52",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mx.png"
    },
    {
      "name": "Micronesia, Federated States of",
      "iso_code": "+691",
      "country_code": "+691",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_fm.png"
    },
    {
      "name": "Moldova, Republic of",
      "iso_code": "+373",
      "country_code": "+373",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_md.png"
    },
    {
      "name": "Monaco",
      "iso_code": "+377",
      "country_code": "+377",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mc.png"
    },
    {
      "name": "Mongolia",
      "iso_code": "+976",
      "country_code": "+976",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mn.png"
    },
    {
      "name": "Montenegro",
      "iso_code": "+382",
      "country_code": "+382",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_me.png"
    },
    {
      "name": "Burundi",
      "iso_code": "+257",
      "country_code": "+257",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bi.png"
    },
    {
      "name": "Montserrat",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ms.png"
    },
    {
      "name": "Mozambique",
      "iso_code": "+258",
      "country_code": "+258",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mz.png"
    },
    {
      "name": "Namibia",
      "iso_code": "+264",
      "country_code": "+264",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_na.png"
    },
    {
      "name": "Myanmar",
      "iso_code": "+95",
      "country_code": "+95",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mm.png"
    },
    {
      "name": "Morocco",
      "iso_code": "+212",
      "country_code": "+212",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ma.png"
    },
    {
      "name": "Burkina Faso",
      "iso_code": "+226",
      "country_code": "+226",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bf.png"
    },
    {
      "name": "Poland",
      "iso_code": "+48",
      "country_code": "+48",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pl.png"
    },
    {
      "name": "Qatar",
      "iso_code": "+974",
      "country_code": "+974",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_qa.png"
    },
    {
      "name": "Russia",
      "iso_code": "+7",
      "country_code": "+7",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ru.png"
    },
    {
      "name": "Romania",
      "iso_code": "+40",
      "country_code": "+40",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ro.png"
    },
    {
      "name": "Rwanda",
      "iso_code": "+250",
      "country_code": "+250",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_rw.png"
    },
    {
      "name": "Réunion",
      "iso_code": "+262",
      "country_code": "+262",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_re.png"
    },
    {
      "name": "Saint Barthélemy",
      "iso_code": "+590",
      "country_code": "+590",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_bl.png"
    },
    {
      "name": "Saint Kitts and Nevis",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kn.png"
    },
    {
      "name": "Nicaragua",
      "iso_code": "+505",
      "country_code": "+505",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ni.png"
    },
    {
      "name": "Nigeria",
      "iso_code": "+234",
      "country_code": "+234",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ng.png"
    },
    {
      "name": "Niue",
      "iso_code": "+683",
      "country_code": "+683",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nu.png"
    },
    {
      "name": "Northern Mariana Islands",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mp.png"
    },
    {
      "name": "Cambodia",
      "iso_code": "+855",
      "country_code": "+855",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_kh.png"
    },
    {
      "name": "Norway",
      "iso_code": "+47",
      "country_code": "+47",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_no.png"
    },
    {
      "name": "Oman",
      "iso_code": "+968",
      "country_code": "+968",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_om.png"
    },
    {
      "name": "Pakistan",
      "iso_code": "+92",
      "country_code": "+92",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pk.png"
    },
    {
      "name": "Palau",
      "iso_code": "+680",
      "country_code": "+680",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pw.png"
    },
    {
      "name": "Palestinian Territory, Occupied",
      "iso_code": "+970",
      "country_code": "+970",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ps.png"
    },
    {
      "name": "Panama",
      "iso_code": "+507",
      "country_code": "+507",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pa.png"
    },
    {
      "name": "Paraguay",
      "iso_code": "+595",
      "country_code": "+595",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_py.png"
    },
    {
      "name": "Papua New Guinea",
      "iso_code": "+675",
      "country_code": "+675",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pg.png"
    },
    {
      "name": "Norfolk Island",
      "iso_code": "+672",
      "country_code": "+672",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_nf.png"
    },
    {
      "name": "Peru",
      "iso_code": "+51",
      "country_code": "+51",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pe.png"
    },
    {
      "name": "Pitcairn",
      "iso_code": "+872",
      "country_code": "+872",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pn.png"
    },
    {
      "name": "Philippines",
      "iso_code": "+63",
      "country_code": "+63",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ph.png"
    },
    {
      "name": "  Sint Maarten",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sx.png"
    },
    {
      "name": "Slovakia",
      "iso_code": "+421",
      "country_code": "+421",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sk.png"
    },
    {
      "name": "Slovenia",
      "iso_code": "+386",
      "country_code": "+386",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_si.png"
    },
    {
      "name": "Solomon Islands",
      "iso_code": "+677",
      "country_code": "+677",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sb.png"
    },
    {
      "name": "Somalia",
      "iso_code": "+252",
      "country_code": "+252",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_so.png"
    },
    {
      "name": "South Africa",
      "iso_code": "+27",
      "country_code": "+27",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_za.png"
    },
    {
      "name": "South Georgia and the South Sandwich Islands",
      "iso_code": "+500",
      "country_code": "+500",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gs.png"
    },
    {
      "name": "South Sudan",
      "iso_code": "+211",
      "country_code": "+211",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ss.png"
    },
    {
      "name": "Cayman Islands",
      "iso_code": "+345",
      "country_code": "+345",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ky.png"
    },
    {
      "name": "Spain",
      "iso_code": "+34",
      "country_code": "+34",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_es.png"
    },
    {
      "name": "Sri Lanka",
      "iso_code": "+94",
      "country_code": "+94",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lk.png"
    },
    {
      "name": "Chad",
      "iso_code": "+235",
      "country_code": "+235",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_td.png"
    },
    {
      "name": "Central African Republic",
      "iso_code": "+236",
      "country_code": "+236",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cf.png"
    },
    {
      "name": "Sudan",
      "iso_code": "+249",
      "country_code": "+249",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sd.png"
    },
    {
      "name": "Saint Lucia",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_lc.png"
    },
    {
      "name": "Cape Verde",
      "iso_code": "+238",
      "country_code": "+238",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cv.png"
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_vc.png"
    },
    {
      "name": "Samoa",
      "iso_code": "+685",
      "country_code": "+685",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ws.png"
    },
    {
      "name": "Sao Tome and Principe",
      "iso_code": "+239",
      "country_code": "+239",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_st.png"
    },
    {
      "name": "San Marino",
      "iso_code": "+378",
      "country_code": "+378",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sm.png"
    },
    {
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "iso_code": "+290",
      "country_code": "+290",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sh.png"
    },
    {
      "name": "Saudi Arabia",
      "iso_code": "+966",
      "country_code": "+966",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sa.png"
    },
    {
      "name": "Saint Martin",
      "iso_code": "+590",
      "country_code": "+590",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_mf.png"
    },
    {
      "name": "Saint Pierre and Miquelon",
      "iso_code": "+508",
      "country_code": "+508",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pm.png"
    },
    {
      "name": "Singapore",
      "iso_code": "+65",
      "country_code": "+65",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sg.png"
    },
    {
      "name": "Serbia",
      "iso_code": "+381",
      "country_code": "+381",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_rs.png"
    },
    {
      "name": "Seychelles",
      "iso_code": "+248",
      "country_code": "+248",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sc.png"
    },
    {
      "name": "Sierra Leone",
      "iso_code": "+232",
      "country_code": "+232",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sl.png"
    },
    {
      "name": "Senegal",
      "iso_code": "+221",
      "country_code": "+221",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sn.png"
    },
    {
      "name": "Trinidad and Tobago",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tt.png"
    },
    {
      "name": "Tunisia",
      "iso_code": "+216",
      "country_code": "+216",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tn.png"
    },
    {
      "name": "Turkey",
      "iso_code": "+90",
      "country_code": "+90",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tr.png"
    },
    {
      "name": "Turkmenistan",
      "iso_code": "+993",
      "country_code": "+993",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tm.png"
    },
    {
      "name": "Turks and Caicos Islands",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tc.png"
    },
    {
      "name": "Tuvalu",
      "iso_code": "+688",
      "country_code": "+688",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tv.png"
    },
    {
      "name": "Uganda",
      "iso_code": "+256",
      "country_code": "+256",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ug.png"
    },
    {
      "name": "Ukraine",
      "iso_code": "+380",
      "country_code": "+380",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ua.png"
    },
    {
      "name": "United Arab Emirates",
      "iso_code": "+971",
      "country_code": "+971",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ae.png"
    },
    {
      "name": "United Kingdom",
      "iso_code": "+44",
      "country_code": "+44",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_gb.png"
    },
    {
      "name": "United States",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_us.png"
    },
    {
      "name": "Uruguay",
      "iso_code": "+598",
      "country_code": "+598",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_uy.png"
    },
    {
      "name": "Uzbekistan",
      "iso_code": "+998",
      "country_code": "+998",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_uz.png"
    },
    {
      "name": "Vanuatu",
      "iso_code": "+678",
      "country_code": "+678",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_vu.png"
    },
    {
      "name": "Venezuela, Bolivarian Republic of",
      "iso_code": "+58",
      "country_code": "+58",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ve.png"
    },
    {
      "name": "Suriname",
      "iso_code": "+597",
      "country_code": "+597",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sr.png"
    },
    {
      "name": "Svalbard and Jan Mayen",
      "iso_code": "+47",
      "country_code": "+47",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sj.png"
    },
    {
      "name": "Swaziland",
      "iso_code": "+268",
      "country_code": "+268",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sz.png"
    },
    {
      "name": "Sweden",
      "iso_code": "+46",
      "country_code": "+46",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_se.png"
    },
    {
      "name": "Syrian Arab Republic",
      "iso_code": "+963",
      "country_code": "+963",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_sy.png"
    },
    {
      "name": "Taiwan",
      "iso_code": "+886",
      "country_code": "+886",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tw.png"
    },
    {
      "name": "Tajikistan",
      "iso_code": "+992",
      "country_code": "+992",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tj.png"
    },
    {
      "name": "China",
      "iso_code": "+86",
      "country_code": "+86",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cn.png"
    },
    {
      "name": "Christmas Island",
      "iso_code": "+61",
      "country_code": "+61",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cx.png"
    },
    {
      "name": "Chile",
      "iso_code": "+56",
      "country_code": "+56",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cl.png"
    },
    {
      "name": "Switzerland",
      "iso_code": "+41",
      "country_code": "+41",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ch.png"
    },
    {
      "name": "Tanzania, United Republic of",
      "iso_code": "+255",
      "country_code": "+255",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tz.png"
    },
    {
      "name": "East Timor",
      "iso_code": "+670",
      "country_code": "+670",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tl.png"
    },
    {
      "name": "Tokelau",
      "iso_code": "+690",
      "country_code": "+690",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tk.png"
    },
    {
      "name": "Tonga",
      "iso_code": "+676",
      "country_code": "+676",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_to.png"
    },
    {
      "name": "Togo",
      "iso_code": "+228",
      "country_code": "+228",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_tg.png"
    },
    {
      "name": "Thailand",
      "iso_code": "+66",
      "country_code": "+66",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_th.png"
    },
    {
      "name": "Viet Nam",
      "iso_code": "+84",
      "country_code": "+84",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_vn.png"
    },
    {
      "name": "Cocos (Keeling) Islands",
      "iso_code": "+61",
      "country_code": "+61",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cc.png"
    },
    {
      "name": "Virgin Islands, British",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_vg.png"
    },
    {
      "name": "Congo",
      "iso_code": "+242",
      "country_code": "+242",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_cg.png"
    },
    {
      "name": "Virgin Islands, U.S.",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_vi.png"
    },
    {
      "name": "Yemen",
      "iso_code": "+967",
      "country_code": "+967",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ye.png"
    },
    {
      "name": "U.S. Minor Outlying Islands",
      "iso_code": "",
      "country_code": "",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_um.png"
    },
    {
      "name": "Zambia",
      "iso_code": "+260",
      "country_code": "+260",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_zm.png"
    },
    {
      "name": "Åland Islands",
      "iso_code": "+358",
      "country_code": "+358",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_ax.png"
    },
    {
      "name": "Comoros",
      "iso_code": "+269",
      "country_code": "+269",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_km.png"
    },
    {
      "name": "Western Sahara",
      "iso_code": "+212",
      "country_code": "+212",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_eh.png"
    },
    {
      "name": "Colombia",
      "iso_code": "+57",
      "country_code": "+57",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_co.png"
    },
    {
      "name": "Wallis and Futuna",
      "iso_code": "+681",
      "country_code": "+681",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_wf.png"
    },
    {
      "name": "Portugal",
      "iso_code": "+351",
      "country_code": "+351",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pt.png"
    },
    {
      "name": "Zimbabwe",
      "iso_code": "+263",
      "country_code": "+263",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_zw.png"
    },
    {
      "name": "Puerto Rico",
      "iso_code": "+1",
      "country_code": "+1",
      "flag": "https://appinventive-lambda.s3.amazonaws.com/woomf/flag_pr.png"
    }
  ]