import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmBoxComponent } from '../view/confirm-box.component';

@Injectable()
export class ConfirmBoxService {

  constructor(private $dialog: MatDialog) { }
  open(data: {title: string, message: string}) {
    const config: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      data
    };
    // if (window.innerWidth < 601) { 
    //   config.position = {
    //     bottom: '0px'
    //   };
    //   config.width = '100vw';
    //   config.maxWidth = '100vw';
    // }
    return this.$dialog.open(ConfirmBoxComponent, config).afterClosed();
  }
}
