import { Component, OnInit } from '@angular/core';
// import { CALCULATORS_ROUTE, BANKS_ROUTE } from 'src/app/constants/route.constants';
import { BANKS, formatBanks } from 'src/app/constants/bank.constants';
import {
  BANKS_ROUTE,
  TERMS_ROUTE,
  ABOUT_ROUTE,
  PRIVACY_ROUTE,
  CONTACT_ROUTE,
  SITE_MAP_ROUTE,
  ARTICLES_ROUTE,
  MORTGAGE_ROUTE,
  CALCULATORS_ROUTE,
  HELP_CENTER_ROUTE,
  POWER_CALCULATOR_ROUTE,
  PRE_QUALIFICATION_ROUTE,
  REPAYMENT_CALCULATOR_ROUTE,
  CONSTANT_ROUTE,
 } from 'src/app/constants/route.constants';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '@environment';

@Component({
  selector: 'nook-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  calculatorUrl = CALCULATORS_ROUTE.url;
  borrowingPowerFragment: string = POWER_CALCULATOR_ROUTE.path;
  monthlyRepaymentFragment: string = REPAYMENT_CALCULATOR_ROUTE.path;
  banks = BANKS.sort((a:any,b:any)=>a.path.localeCompare(b.path));
  termsUrl: string = TERMS_ROUTE.url;
  privacyUrl: string = PRIVACY_ROUTE.url;
  sitemapUrl: string = SITE_MAP_ROUTE.url;
  aboutUrl: string = ABOUT_ROUTE.url;
  contactUrl: string = CONTACT_ROUTE.url;
  helpCenterUrl: string = HELP_CENTER_ROUTE.url;
  articlesUrl: string = ARTICLES_ROUTE.url;
  mortgageUrl: string = MORTGAGE_ROUTE.url;
  loansUrl: string = PRE_QUALIFICATION_ROUTE.url;
  isHomePage = false;
  isPreQualification = false;
  year = new Date().getFullYear();
  constantRoute = CONSTANT_ROUTE;
  homeUrl:any = environment.nookUrl
  constructor(private $router: Router) {
    $router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = event.urlAfterRedirects === '/';
        this.isPreQualification = event.urlAfterRedirects.includes('pre-qualification/listing');
      } 
     });

    }

  ngOnInit(): void {
  }
  onClickBank(path: string) {
    this.$router.navigate([BANKS_ROUTE.url, path]);
  }
}
