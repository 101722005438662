<header class="header-wrapper" [class.transparent]="isHeaderBanner">
    <div class="container">
        <div class="header-inner">
            <div class="logo_wrapper">
                <a href="javascript:void(0)" [href]="homeURl" class="branding">
                    <img src="assets/images/logo.svg" alt="" class="color">
                    <img src="assets/images/logo-white.png" alt="" class="white">
                </a>
                <a href="javascript:void(0)" routerLink="/" class="branding" *ngIf="partnerData?.logoUrl">
                    <img class="partnerLogo color" [src]="partnerData?.logoUrl" [attr.alt]="partnerData?.name">
                </a>
            </div>
            <div class="right">
                <ul class="header-menu">
                    <li>
                        <a href="javascript:void(0)" [matMenuTriggerFor]="homeloan">Home Loans
                            <i class="arrow"></i> </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" [matMenuTriggerFor]="others">Other <i class="arrow"></i>
                        </a>
                    </li>
                </ul>
                <button type="button" mat-button class="signup" *ngIf="!isAuthenticated" (click)="onLoginHandler()">Sign
                    In / Register</button>
                <button mat-icon-button *ngIf="isAuthenticated" [matMenuTriggerFor]="menuOptions" class="profile-btn">
                    <img [src]="profileImage" alt="" (error)="onProfileImageError()">
                </button>
                 <button [routerLink]="loansUrl" type="button" *ngIf="isAuthenticated" class="button-primary button-primary--rounded" color="primary" 
                    mat-flat-button>Get Started
                </button>
            </div>
        </div>
    </div>
</header>

<mat-menu #homeloan="matMenu" class="header-dropdown">
    <h2 class="title" >Home Loans </h2>
    <ul class="menubar" style="display:inline">
        <li>
            <a href="https://www.nook.com.ph/home-loan-pre-qualify">Loan Application</a>
        </li>
        <li>
            <a href="https://www.nook.com.ph/home-loan-pre-qualify">Pre-Qualification</a>
        </li>
        <li>
            <a href="https://www.nook.com.ph/mortgage-brokering">Mortgage Brokering</a>
        </li>
        <li>
            <a href="https://www.nook.com.ph/loan-calculators">Loan Calculators</a>
        </li>
    </ul>
</mat-menu>
<mat-menu #others="matMenu" class="header-dropdown" xPosition="before">
    <h2 class="title" >Other </h2>
    <ul class="menubar" style="display:inline">
        <li>
            <a  [href]="constantRoute.blogUrl" target="_blank" >Blog</a>
        </li>
        <li>
            <a [href]="constantRoute?.carrerUrl" target="_blank">Careers</a>
        </li>
        <li>
            <a [href]="constantRoute?.bankPatnersUrl" target="_blank">Bank Partners</a>
        </li>
        <li>
            <a [href]="constantRoute?.aboutUrl" target="_blank">About Us</a>
        </li>
        <li>
            <a [href]="constantRoute?.contactUrl" target="_blank">Contact</a>
        </li>
        <li>
            <a [href]="constantRoute?.helpCenter" target="_blank">Help Center</a>
        </li>
        <li>
            <a target="_blank" (click)="onSwitchToTapfiliate()">Switch to: Affiliate account</a>
        </li>
    </ul>
</mat-menu>
<mat-menu #menuOptions xPosition="before">
    <a href="javascript:void(0)" mat-menu-item *ngFor="let menu of menus" [routerLink]="menu.url">
        <mat-icon>{{menu.icon}}</mat-icon>
        <span>{{menu.label}}</span>
    </a>
    <a [href]="constantRoute?.helpCenter" mat-menu-item target="_blank">
        <mat-icon>help_outline</mat-icon>
        <span>Help Center</span>
    </a>
    <button mat-menu-item (click)="onLogoutHandler()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>

<div class="header-mobile" [class.transparent]="isHeaderBanner" [class.togglemenu]="isToggle">
    <div class="inner">
        <div class="toggle-wrap">
            <button type="button" class="toggle" mat-icon-button (click)="toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="logo_wrapper">
            <a [href]="homeURl" class="branding">
                <img src="assets/images/logo.svg" alt="" class="color">
                <img src="assets/images/logo-white.png" alt="" class="white">
            </a>
            <a href="javascript:void(0)" routerLink="/" class="branding" *ngIf="partnerData?.logoUrl">
                <img class="partnerLogo color" [src]="partnerData?.logoUrl" [attr.alt]="partnerData?.name">
            </a>
        </div>
        <button type="button" mat-button class="register" *ngIf="!isAuthenticated" (click)="onLoginHandler()">Sign In /
            <br> Register</button>
        <button mat-icon-button *ngIf="isAuthenticated" [matMenuTriggerFor]="menuOptions" class="profile-btn">
            <img [src]="profileImage" alt="" (error)="onProfileImageError()">
        </button>
    </div>
</div>
<nav class="navsidebar" [class.open]="isToggle">
    <div class="headsec">
        <button type="button" class="toggle" mat-icon-button (click)="toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <a href="javascript:void(0)" [href]="homeURl" class="logosec">
            <img src="assets/images/logo.svg" alt="" class="color">
        </a>
        <button type="button" class="toggle" mat-icon-button (click)="toggle()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <ul class="parent">
        <li>
            <a href="javascript:void(0)" [routerLink]="landingUrl">
                Home loans
            </a>
            <ul class="child">
                <li>
                    <a href="https://www.nook.com.ph/home-loan-pre-qualify">Loan Application</a>
                </li>
                <li>
                    <a href="https://www.nook.com.ph/mortgage-brokering">Mortgage Brokering</a>
                </li>
                <li>
                    <a href="https://www.nook.com.ph/home-loan-pre-qualify">Pre-Qualification</a>
                </li>
                <li>
                    <a href="https://www.nook.com.ph/loan-calculators">Loan Calculators</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="javascript:void(0)" >
                others
            </a>
            <ul class="child">
                <li>
                    <a  [href]="constantRoute.blogUrl" target="_blank" >Blog</a>
                </li>
                <li>
                    <a [href]="constantRoute?.carrerUrl" target="_blank">Careers</a>
                </li>
                <li>
                    <a [href]="constantRoute?.bankPatnersUrl" target="_blank">Bank Partners</a>
                </li>
                <li>
                    <a [href]="constantRoute?.aboutUrl" target="_blank">About Us</a>
                </li>
                <li>
                    <a [href]="constantRoute?.contactUrl" target="_blank">Contact</a>
                </li>
                <li>
                    <a [href]="constantRoute?.helpCenter" target="_blank">Help Center</a>
                </li>
            </ul>
        </li>

        <li>
            <a href="javascript:void(0)" [routerLink]="articlesUrl">
                Articles
            </a>
        </li>
    </ul>
    <div class="btn-wrap">
        <button type="button" mat-button class="signup" *ngIf="!isAuthenticated" (click)="onLoginHandler()">Sign In /
            Register</button>
    </div>
</nav>