export const VIDEO_URL = 'https://youtu.be/xg8FEpYZNRc';
export const SMS_URL = 'sms:+639221101244';
export const VIBER_URL = 'viber://pa?chatURI=nook';
// export const CHAT_URL = 'https://m.me/NookPhilippines?ref=w8118002';
export const CHAT_URL = "https://chat.nook.com.ph";
export const CALL_URL = 'https://calendly.com/nook-home-loan-consultants/nook-home-loan-consultation';
export const SEGMENT_KEY = 'zRmxg614LRSE6hyJS5uCpPqoQHOrIN39';
//export const SEGMENT_KEY = 'ViUPG8MAqjrGP2oj31t7Ck7phaLOU1JO';

export const TITLE_TEXT = {
    DEFAULT: `Nook | We’re Making Home Loans Simple`,
    // DEFAULT: `We'll Get Your Home Loan Approved`,
    PRE_QUALIFICATION: 'Nook - Home Loan Pre-Qualification Wizard',
    ARTICLES: 'Nook - Articles, Blog and News Stand',
    MORTGAGE_BROKERING: 'Nook - Mortgage Brokering',
    HOMELOAN_CALCULATOR: 'Nook - Home Loan Calculator',
    CONTACT_US: 'Nook - Contact Us',
    ABOUT_US: 'Nook - About Us',
};
