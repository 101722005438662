import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProfileService } from '@profile';
import { CustomValidators } from 'src/app/constants';
import { USERNAME, EMAIL, PASSWORD, USER_NAME, PHONE } from 'src/app/constants/form.constants';
import { TERMS_ROUTE, PRIVACY_ROUTE, DASHBOARD_ROUTE } from 'src/app/constants/route.constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { COUNTRIES_FLAGS } from 'src/app/constants/countries';
import { takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { GlobalService } from '@global';
import { PreQualificationService } from 'src/app/modules/pre-qualification/services/pre-qualification.service';
import { DataLayerService } from 'src/app/services/data-layer/data-layer.service';
import { environment } from '@environment';
import Tap from "@tapfiliate/tapfiliate-js";
import { SEGMENT_KEY } from 'src/app/constants';

import random from 'random'

@Component({
  selector: 'nook-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  userForm: FormGroup;
  registerForm: FormGroup;
  isUserVerified = false;
  private $isProcessing = false;
  CONST_WALA_DATA = COUNTRIES_FLAGS;
  ipAddress: any;
  private $destroy = new Subject();
  public countryCodes = [];
  private _countryCodes = [];
  public codeFilterCtrl: FormControl = new FormControl();
  public filteredcodes: ReplaySubject<any> = new ReplaySubject(1);
  refferalId: any;
  tapfiliateReferralID: any;
  profileData: any;
  maxLength: boolean = true;
  set isProcessing(value: boolean) {
    const form = this.isUserVerified ? this.registerForm : this.userForm;
    this.$isProcessing = value;
    if (value) {
      form.disable();
    } else {
      form.enable();
    }
  }
  get isProcessing() {
    return this.$isProcessing;
  }
  readonly policyControl = new FormControl(false, Validators.required);
  // readonly userNameMaxLength: number = USERNAME.maxLength;
  readonly passwordMaxLength: number = PASSWORD.maxLength;
  // readonly userNameMask: string = USERNAME.maskPattern;
  readonly nameMaxLength: number = USER_NAME.maxLength;
  readonly emailMaxLength: number = EMAIL.maxLength;
  readonly phoneMaxLength: number = 10;
  readonly phoneMask: string = PHONE.maskPattern;
  readonly privacyUrl = PRIVACY_ROUTE.url;
  readonly termsUrl = TERMS_ROUTE.url;

  newSegmentClient;
  Analytics = require('analytics-node');
  constructor(
    fb: FormBuilder,
    private $router: Router, 
    private $profile: ProfileService,
    // private $dialogRef: MatDialogRef<any>,
    private $changeDetectorRef: ChangeDetectorRef,
    private $global: GlobalService,
    route: ActivatedRoute,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private $pqService: PreQualificationService,
    private _dataLayerService: DataLayerService,
  ) {
    this.newSegmentClient = new this.Analytics(SEGMENT_KEY);
    const PhoneValidator = CustomValidators.phone.concat(Validators.required);
    this.userForm = fb.group({
      // userName: ['', CustomValidators.username.concat(Validators.required)],
      email: ['', CustomValidators.email.concat(Validators.required)],
      password: ['', CustomValidators.password],
      confirmPassword: ['', CustomValidators.password.concat(CustomValidators.match('password'))],
    });
    this.registerForm = fb.group({
      // userName: [null, CustomValidators.username.concat(Validators.required)],
      email: [null, CustomValidators.email.concat(Validators.required)],
      password: [null, CustomValidators.password],
      firstName: [null, [Validators.required, ...CustomValidators.name]],
      lastName: [null, [Validators.required, ...CustomValidators.name]],
      phoneNumber: [null, PhoneValidator],
      countryCode: ['+63', Validators.required]
    });
    const { password, confirmPassword } = this.userForm.controls;
    password.valueChanges.subscribe(() => {
      confirmPassword.updateValueAndValidity();
      this.$changeDetectorRef.markForCheck();
    });

    route.queryParams.subscribe(({application, refferalId, ref}) => {
      if (refferalId) {
        this.refferalId =  refferalId;
        this.checkPartnerHandler();
      } else {
        this.$global.partnerData = null;
        this.$global.partnerDataEvent.emit(this.$global.partnerData);
      }
      if (ref) {
        this.tapfiliateReferralID =  ref;
      } 
    });
  }

  get countryCode() {
    return this.registerForm.get('country_code') as FormControl;
  }

  ngOnInit() {
    this.getIp();
    this.getCountryCodes();
    Tap.init(environment.tapfiliateID);
    localStorage.setItem('tapfiliateId', this.tapfiliateReferralID)
  }
  redirectToLogin() {
    // this.changeTab.emit('LOGIN');
    this.$router.navigate(['public/login'])
  }


  checkPartnerHandler() {
    this.$pqService.onCheckPartnerHandler(this.refferalId).then(res => {
      this.$global.partnerData = res['data'];
      this.$global.partnerDataEvent.emit(res['data']);
     });
  }
  onVerifyUserHandler() {
    if (this.userForm.valid && !this.isProcessing) {
      const { confirmPassword, ...value } = this.userForm.value;
      this.isProcessing = true;
      this.$profile.checkValidity(value).then(() => {
        this.isUserVerified = true;
        this.registerForm.patchValue(value);
        this.isProcessing = false;
      }).catch(() => {
        this.isProcessing = false;
      });
    } else if (!this.isProcessing) {
      this.userForm.markAllAsTouched();
    }
  }

  onRegisterHandler() {
    if (this.registerForm.valid && !this.isProcessing) {
      const value = this.registerForm.value;
      value.ipAddress = this.ipAddress;
      this.isProcessing = true;
      const partnerEncryptedData = this.$global.partnerData;
      if (partnerEncryptedData) {
        value.partnerName = partnerEncryptedData.name;
        value.partnerId = partnerEncryptedData.shortId;
      }
      value['deviceId'] = this.$global.getDeviceId();
      this.$profile.register(value).then((status: boolean) => {
        if (status) {
          const { stopRedirect } = false|| {} as any;
          if (!stopRedirect) {
            if(localStorage.getItem('isLoggedIn')){
              this.$global.isLoggedIn.next(true);
            }
            this.$profile.changes.subscribe((data) => {
              this.profileData = data;
            });
            const { _id, email, phoneNumber, countryCode, firstName, lastName } = this.profileData; 
            var date = new Date().toLocaleString();
            try{
              this.newSegmentClient.identify({
                type: "Identify",
                userId: _id,
                traits: {
                  userId: _id,
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  createdAt: date,
                }
              });
            }catch(e){
              console.log(e);
            }
            
            try{
              let context = {
                userId: _id,
                userAgent: window.navigator.userAgent,
                ip: this.ipAddress,
                active: true,
              }

              this.newSegmentClient.track({
                event: 'Account Created',
                userId: _id,
                properties:{
                  context: context,
                }
              }, (message) => console.log(message));
            }catch(e){
              console.log(e);
            }

            this._dataLayerService.logEvent({
              event: "signup_form_submission",
              user_data: {
                user_id: _id,
                email_address: email,
                phone_number: `${countryCode}${phoneNumber}`,
                address: {
                  first_name: firstName,
                  last_name: lastName,
                },
              },
            });
            const user_id = _id;
            const options = {
              meta_data: {
                fullname: `${firstName} ${lastName}`,
                email,
                user_id,
                referral_code: localStorage.getItem('tapfiliateId')
              },
            }

            if (localStorage.getItem('tapfiliateId')) {
              Tap.customer(user_id, options);
            }

            this.$router.navigate([DASHBOARD_ROUTE.url], { queryParamsHandling: 'preserve' });
          }
          // this.$dialogRef.close(true);
        } else {
          this.isProcessing = false;
        }
      }).catch(() => {
        localStorage.removeItem('deviceId');
        this.isProcessing = false;
      });
    } else if (!this.isProcessing) {
      this.registerForm.markAllAsTouched();
      localStorage.removeItem('deviceId');
    }
  }

  getIp() {
    fetch('https://extreme-ip-lookup.com/json/')
      .then(res => res.json())
      .then(response => {
        this.ipAddress = response.query;
      });
  }

  // ************************************************
  // Get country codes
  // ************************************************
  getCountryCodes() {
    this.countryCodes = this.CONST_WALA_DATA;
    this._countryCodes = this.CONST_WALA_DATA.map((obj) => {
      obj['searchTerm'] = obj['country_code'] + ' ' + obj['name'];
      return obj;
    });
    this.filteredcodes.next(this._countryCodes.slice());
    // listen for search field value changes
    this.codeFilterCtrl.valueChanges
      .pipe(takeUntil(this.$destroy))
      .subscribe(() => {
        this.filtercodes();
      });
  }

  // ************************************************
  // Filtering country codes
  // ************************************************
  private filtercodes() {
    if (!this._countryCodes) {
      return;
    }
    // get the search keyword
    let search = this.codeFilterCtrl.value.trim();
    if (!search) {
      this.filteredcodes.next(this._countryCodes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the codes
    this.filteredcodes.next(
      this._countryCodes.filter(code => {
        if (code.searchTerm) {
          return code.searchTerm.toString().toLowerCase().indexOf(search) > -1;
        }
      })
    );
  }

  onMobileInput(event?){
    let code = this.registerForm.value.countryCode;
    this.registerForm.controls.phoneNumber.setValue('')
    if(code!=='+63'){
      this.registerForm.controls['phoneNumber'].setValidators(Validators.minLength(7));
      this.maxLength = false;
    }else{
      this.registerForm.controls['phoneNumber'].setValidators([Validators.minLength(10), Validators.pattern('[9]\\d{9}')]);
      this.maxLength = true
    }
  }
  onMobileChange(event?) {
    let code = this.registerForm.value.countryCode;
    if(code!=='+63'){
      this.registerForm.controls['phoneNumber'].setValidators(Validators.minLength(7));
      this.maxLength = false;
    }else{
      this.registerForm.controls['phoneNumber'].setValidators([Validators.minLength(10), Validators.pattern('[9]\\d{9}')]);
      this.maxLength = true
    }
  }
}

