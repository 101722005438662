

export const USERNAME = {
    minLength: 1,
    maxLength: 32,
    maskPattern: 'A*'
};

export const PASSWORD = {
    minLength: 6,
    maxLength: 100
};

export const PHONE_FIELD = {
    minLength: 7,
    maxLength: 15,
    maskPattern: '999999999999999'
};
export const PHONE = {
    minLength: 7,
    maxLength: 15,
    maskPattern: '999999999999999'
};

export const FAX = {
    minLength: 7,
    maxLength: 15,
    maskPattern: '000-000-0000'
};

export const DESCRIPTION = {
    minLength: 1,
    maxLength: 1000,
};

export const USER_TITLE = {
    minLength: 2,
    maxLength: 200,
};

export const USER_ABOUT = {
    minLength: 0,
    maxLength: 2000,
};

export const USER_COMPANY = {
    minLength: 0,
    maxLength: 100,
};

export const SINCE_FIELD = {
    minLength: 4,
    maxLength: 15,
    maskPattern: '9999-9999'
};
export const USER_LICENSE = {
    minLength: 0,
    maxLength: 50,
};

export const USER_LANGUAGE = {
    minLength: 0,
    maxLength: 200,
};

export const EMAIL = {
    minLength: 3,
    maxLength: 254
};

export const ZIP_CODE = {
    minLength: 3,
    maxLength: 4,
    maskPattern: '9000'
};

export const USER_NAME = {
    minLength: 1,
    maxLength: 50,
    pattern: {
        regex: /^[a-zA-Z ]{1,30}$/,
        message: ''
    }
};

export const SEARCH_FIELD = {
    minLength: 0,
    maxLength: 100
};

export const PROPERTY = {
    title: {
        minLength: 1,
        maxLength: 60
    },
    description: {
        minLength: 1,
        maxLength: 2000
    },
    address: {
        minLength: 1,
        maxLength: 300
    },
    barangay: {
        minLength: 1,
        maxLength: 100
    },
    price: {
        min: 0,
        max: 10000000000,
        maskPattern: '9999999999.00'
    },
    area: {
        min: 0,
        max: 10000000,
        maskPattern: '9999999.00'
    }
};



export const LOAN = {
    property: {
        min: 625000,
        mask: '999999999999',
        max: 10000000000
    },
    personal: {
        min: 10000,
        mask: '999999999999',
        max: 1000000
    },
    income: {
        min: 20000,
        mask: '9999999999',
        max: 10000000000
    },
    incomePersonal: {
        min: 20000,
        mask: '9999999999',
        max: 10000000000
    },
    incomeHome: {
        min: 25000,
        mask: '9999999999',
        max: 10000000000
    },
    age: {
        min: 21,
        mask: '99',
        max: 65,
    },
    sss: {
        minLength: 2,
        mask: '00-0000000-0',
        maxLength: 12
    },
    tin: {
        minLength: 3,
        mask: '000-000-000',
        maxLength: 11
    }
};