import { Component, OnInit, Input } from '@angular/core';
import { PASSWORD } from 'src/app/constants/form.constants';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'nook-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss']
})
export class InputPasswordComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  @Input() placeholder = 'Password';
  maxLength = PASSWORD.maxLength;
  isPasswordVisible = false;
  get eyeIcon(): string {
    return this.isPasswordVisible ? 'visibility' : 'visibility_off';
  }
  get inputType(): string {
    return this.isPasswordVisible ? 'text' : 'password';
  }
  constructor() { }

  ngOnInit() {
  }

}
