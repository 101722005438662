import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { HttpService } from '@http';
import { TOOLTIP_DATA } from 'src/app/constants';
import { TokenService } from '@token';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public partnerData = null;
  private $banks: BehaviorSubject<any[]> = new BehaviorSubject(null);
  readonly banks = this.$banks.asObservable();
  private $regions: BehaviorSubject<any[]> = new BehaviorSubject(null);
  private $stripe: ReplaySubject<any> = new ReplaySubject(null);
  readonly stripeChanges = this.$stripe.asObservable();
  readonly regions = this.$regions.asObservable();
  partnerDataEvent = new EventEmitter();
  isLoggedIn = new Subject();
  applyScheme = new Subject();
  tooltipData = TOOLTIP_DATA
  cookieService: any;
  constructor(private $http: HttpService,private token:TokenService) {
    // this.fetchRegions();
  }
  async fetchRegions() {
    if (!this.$regions.getValue()) {
      const { data } = await this.$http.get(`~/content/regions`);
      this.$regions.next(data);
    }
  }
  async fetauredCities() {
    const { data } = await this.$http.get(`~/cities/featured`);
    return data;
  }
  encodeData(data: any) {
    return window.btoa(JSON.stringify(data));
  }
  decodeData(data: string) {
    return JSON.parse(window.atob(data));
  }
  moveToError() {
    setTimeout(() => {
      const errElement = document.querySelector('mat-error');
      if (errElement) {
        const rootElement = errElement.parentElement.parentElement.parentElement;
        rootElement.scrollIntoView();
      }
    });
  }
  async queryBanks() {
    if (!this.$banks.getValue()) {
      const { data } = await this.$http.get(`~/banks/shuffle`);
      this.$banks.next(data);
    }
  }

  changeTooltip(property,type){
   let text =  this.tooltipData[`${property}`][`${type}`]
   return text;
  }

  getDeviceId(): string {
    if (localStorage.getItem('deviceId')) {
      return localStorage.getItem('deviceId');
    } else {
      const deviceId = (Math.floor(Math.random() * 1000) + 1).toString() + new Date().getTime().toString() + Math.random().toString(36).substring(2,18);
      localStorage.setItem('deviceId', deviceId);
      return deviceId;
    }
  }

  getLoanSelected(type): string {
    if (localStorage.getItem('loanTypeSelected')) {
      return localStorage.getItem('loanTypeSelected');
    } else {
      const loanTypeSelected = type;
      localStorage.setItem('loanTypeSelected', loanTypeSelected);
      return loanTypeSelected;
    }
  }

}