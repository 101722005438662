<h1 class="title-sec">Login to your account</h1>
<form class="form-wrapper loginForm" [formGroup]="formGroup">
    <div class="form_field">
        <label class="form_label" for="email">Email</label>
        <mat-form-field class="matInput_wrap">
            <input id="email" matInput formControlName="email" placeholder="Email" spellcheck="false" autocomplete="off">
            <mat-error>
                <mat-icon [matTooltip]="formGroup.controls['email'] | validationError : 'email'"
                    matTooltipClass="error_tooltip">error</mat-icon>
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form_field">
        <label class="form_label" for="password">Password</label>
        <nook-input-password id="password" [control]="formGroup.controls['password']"></nook-input-password>
    </div>
    <div class="actions">
        <div class="col-left">
            <mat-checkbox formControlName="rememberToken">Remember me</mat-checkbox>
        </div>
        <div class="col-right">
            <a href="javascript:void(0)" class="forgot" (click)="onForgotPassword()">Forgot password?</a>
        </div>
    </div>
    <button type="button" mat-flat-button [nookButtonLoader]="formGroup.disabled" [disabled]="formGroup.disabled"
        (click)="onSubmitHandler()" color="primary" class="submit_btn">Login</button>
    <p class="para"><a href="javascript:void(0)" (click)="redirectToRegister()">No account yet? Register Now</a></p>
    <!-- <p class="para"><a [href]="videoUrl" target="_blank">How to create an account?</a></p> -->
</form>