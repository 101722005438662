<footer>
    <div class="container">
        <div class="copy-right">
            <div class="copy">
                <a [href]="homeUrl" class="logo">
                    <svg mlns="http://www.w3.org/2000/svg" width="100.5" height="23.13" viewBox="0 0 100.5 23.13">
                        <g id="Group_1" data-name="Group 1" transform="translate(28.179)">
                            <path id="Path_1" data-name="Path 1" d="M236.762,52.386a7.292,7.292,0,0,1,4.842-2.1,5.39,5.39,0,0,1,5.613,5.613V67.1h-4.1V59.356c0-3.238-.278-5.3-2.775-5.3a3.217,3.217,0,0,0-2.776,1.573c-.833,1.326-.8,2.806-.8,4.5V67.1h-4.1V50.721h4.1Z" transform="translate(-232.66 -44.399)" fill="#f2f2f2"/>
                            <path id="Path_2" data-name="Path 2" d="M386.208,58.893a8.589,8.589,0,1,1-8.7-8.6A8.531,8.531,0,0,1,386.208,58.893Zm-13.045,0c0,2.776,1.758,4.749,4.41,4.749a4.433,4.433,0,0,0,4.5-4.719,4.465,4.465,0,1,0-8.913-.031Z" transform="translate(-351.914 -44.399)" fill="#f2f2f2"/>
                            <path id="Path_3" data-name="Path 3" d="M538.552,58.893a8.589,8.589,0,1,1-8.7-8.6A8.531,8.531,0,0,1,538.552,58.893Zm-13.045,0c0,2.776,1.758,4.749,4.41,4.749a4.433,4.433,0,0,0,4.5-4.719,4.465,4.465,0,1,0-8.913-.031Z" transform="translate(-485.137 -44.399)" fill="#f2f2f2"/>
                            <path id="Path_4" data-name="Path 4" d="M680.1,3.358h4.1V16.434l6.076-6.754H695.4l-7.031,7.864,7.9,8.512h-5.089l-6.97-7.556v7.556h-4.1Z" transform="translate(-623.944 -3.358)" fill="#f2f2f2"/>
                        </g>
                        <path id="Path_5" data-name="Path 5" d="M31.775,24.972,20.444,14a.879.879,0,0,0-1.237.014L8.4,24.97a.879.879,0,0,0,.626,1.5h2.149v8.711h4.289V33.42H12.931V25.587a.879.879,0,0,0-.879-.879h-.927l8.721-8.843,9.147,8.859h-.438a.879.879,0,0,0-.879.879V33.42H18.411V28.046H21.87v4.476h1.757V26.289H16.654v8.888h11.9c.029,0,.057,0,.085,0h.8v-1.7h0V26.481h1.729a.879.879,0,0,0,.611-1.51Z" transform="translate(-8.147 -12.446)" fill="#f2f2f2"/>
                    </svg>
                    <span>{{year}} Nook. All rights reserved.</span>
                </a>
            </div>
            <ul class="terms">
                <li>
                    <a href="https://www.nook.com.ph/terms/">Terms</a>
                </li>
                <li class="separator" aria-hidden="true">
                    |
                </li>
                <li>
                    <a href="https://www.nook.com.ph/privacy/">Privacy</a>
                </li>
            </ul>
        </div>
    </div>
</footer>