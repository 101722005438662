
export const LANDING_ROUTE = {
    path: '',
    get url(): string {
        return '/';
    }
};

export const BANKS_ROUTE = {
    path: 'banks',
    get url(): string {
        return `/${this.path}`;
    }
};

export const BANK_ROUTE = {
    path: ':bank',
    url(name: string): string {
        return `${BANKS_ROUTE.url}/${name}`;
    }
};

export const PRE_QUALIFICATION_ROUTE = {
    path: 'pre-qualification',
    get url(): string {
        return `/${this.path}`;
    }
};

export const LOAN_SCHEMES_ROUTE = {
    path: 'loan-schemes',
    get url(): string {
        return `/${this.path}`;
    }
};


export const LOAN_APPLICATION_ROUTE = {
    path: 'application',
    get url(): string {
        return `/${this.path}`;
    }
};


export const LOAN_SUCCESS_ROUTE = {
    path: 'success',
    get url(): string {
        return `/${this.path}`;
    }
};

export const CALCULATORS_ROUTE = {
    path: 'loan-calculators',
    get url(): string {
        return `/${this.path}`;
    }
};

export const MORTGAGE_ROUTE = {
    path: 'mortgage-brokering',
    get url(): string {
        return `/${this.path}`;
    }
};

export const REPAYMENT_CALCULATOR_ROUTE = {
    path: 'monthly_repayment',
    get url(): string {
        return `/${CALCULATORS_ROUTE.url}/${this.path}`;
    }
};

export const POWER_CALCULATOR_ROUTE = {
    path: 'borrowing_power',
    get url(): string {
        return `/${CALCULATORS_ROUTE.url}/${this.path}`;
    }
};

// Layout Modules =>

export const LAYOUT_ROUTE = {
    path: 'layout',
    get url() {
        return `/${this.path}`;
    }
};

export const DASHBOARD_ROUTE = {
    path: 'dashboard',
    get url() {
        return `${LAYOUT_ROUTE.url}/${this.path}`;
    }
};

export const REQUIRED_DOCUMENT_ROUTE = {
    path: 'requried-document',
    get url(): string {
        return `/${this.path}`;
    }
};

export const PUBLIC_ROUTE = {
    path:'public',
    get url():string{
        return `/${this.path}`
    }
}

export const LOGIN_ROUTE = {
    path:'login',
    get url():string{
        return `/${this.path}`
    }
}


export const REGISTER_ROUTE = {
    path:'register',
    get url():string{
        return `/${this.path}`
    }
}


export const FORGOT_ROUTE = {
    path:'forgot-password',
    get url():string{
        return `/${this.path}`
    }
}


export const RESET_ROUTE = {
    path:'reset-password',
    get url():string{
        return `/${this.path}`
    }
}
export const PROFILE_ROUTE = {
    path: 'profile',
    get url() {
        return `${LAYOUT_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_REFERRALS_ROUTE = {
    path: 'loan-referrals',
    get url() {
        return `${LAYOUT_ROUTE.url}/${this.path}`;
    }
};

export const PRE_QUALIFICATIONS_ROUTE = {
    path: 'pre-qualifications',
    get url() {
        return `${LAYOUT_ROUTE.url}/${this.path}`;
    }
};


export const HELP_CENTER_ROUTE = {
    path: 'help-center',
    get url() {
        return `/${this.path}`;
    }
};

export const ACCOUNT_HELP_ROUTE = {
    path: 'account',
    get url() {
        return `${HELP_CENTER_ROUTE.url}/${this.path}`;
    }
};

export const LOANS_HELP_ROUTE = {
    path: 'home-loans',
    get url() {
        return `${HELP_CENTER_ROUTE.url}/${this.path}`;
    }
};

export const FAQ_HELP_ROUTE = {
    path: 'faqs',
    get url() {
        return `${HELP_CENTER_ROUTE.url}/${this.path}`;
    }
};

// Layout Modules End

export const ARTICLES_ROUTE = {
    path: 'articles',
    get url() {
        return `/${this.path}`;
    }
};

export const DOCS_ROUTE = {
    path: 'docs',
    get url() {
        return `/${this.path}`;
    }
};

export const CONTACT_ROUTE = {
    path: 'contact-us',
    get url() {
        return `/${this.path}`;
    }
};

export const TERMS_ROUTE = {
    path: 'terms',
    get url() {
        return `/${this.path}`;
    }
};

export const LOAN_TERMS_ROUTE = {
    path: 'for-loans',
    get url() {
        return `${TERMS_ROUTE.url}/${this.path}`;
    }
};

export const PROPERTY_TERMS_ROUTE = {
    path: 'for-properties',
    get url() {
        return `${TERMS_ROUTE.url}/${this.path}`;
    }
};

export const PRIVACY_ROUTE = {
    path: 'privacy',
    get url() {
        return `/${this.path}`;
    }
};

export const ERROR_ROUTE = {
    path: 'tapfiliate-error',
    get url() {
        return `/${this.path}`;
    }
};

export const SITE_MAP_ROUTE = {
    path: 'site-map',
    get url() {
        return `/${this.path}`;
    }
};

export const ABOUT_ROUTE = {
    path: 'about',
    get url() {
        return `/${this.path}`;
    }
};

export const ABOUT_STORY_ROUTE = {
    path: 'our-story',
    get url() {
        return `${ABOUT_ROUTE.url}/${this.path}`;
    }
};

export const ABOUT_CEO_ROUTE = {
    path: 'ceo',
    get url() {
        return `${ABOUT_ROUTE.url}/${this.path}`;
    }
};

export const LOAN_APPLICATIONS_ROUTE = {
    path: 'loan-applications',
    get url() {
        return `${LAYOUT_ROUTE.url}/${this.path}`;
    }
};

export const CONSTANT_ROUTE = {
    blogUrl:"https://www.nook.com.ph/blog/",
    carrerUrl : "https://www.nook.com.ph/careers/",
    bankPatnersUrl: "https://www.nook.com.ph/banking-partners/",
    aboutUrl:"https://www.nook.com.ph/about-us/",
    contactUrl: "https://www.nook.com.ph/contact-us/",
    helpCenter:"https://www.nook.com.ph/help-center"
}

