<ul class="breadcrumb">
    <li *ngFor="let route of routes" [ngClass]="{'active':route.value.length===0}" class="item">
        <a href="javascript:void(0)" *ngIf="route.value && route?.isRoute" routerLink="{{route?.value}}">
            {{ route.key }}
        </a>
        <a href="javascript:void(0)" *ngIf="route.value && !route?.isRoute" (click)="routeToURl(route.value)">
            <span *ngIf="route.key === 'Home'">
                <svg xmlns="http://www.w3.org/2000/svg" class="home-icon" width="15.688" height="16.514" viewBox="0 0 15.688 16.514">
                    <g id="Action_Button" data-name="Action Button" transform="translate(-8.156 -7.743)">
                      <g id="Iconly_Bold_Home" data-name="Iconly/Bold/Home" transform="translate(5.656 5.743)">
                        <g id="Home" transform="translate(2.5 2)">
                          <path id="Path_36514" data-name="Path 36514" d="M5.478,15.5V12.976a1.171,1.171,0,0,1,1.176-1.167H9.027a1.18,1.18,0,0,1,.831.342,1.163,1.163,0,0,1,.344.825V15.5a1,1,0,0,0,.294.716,1.017,1.017,0,0,0,.719.3h1.619a2.857,2.857,0,0,0,2.017-.825,2.815,2.815,0,0,0,.836-2V6.5a2.042,2.042,0,0,0-.739-1.571L9.441.558a2.558,2.558,0,0,0-3.26.059L.8,4.925A2.043,2.043,0,0,0,0,6.5v7.185a2.843,2.843,0,0,0,2.854,2.833H4.436a1.017,1.017,0,0,0,1.02-1.006Z" transform="translate(0 0)" fill="#5f6673"/>
                        </g>
                      </g>
                    </g>
                </svg>
            </span>
            <span *ngIf="route.key !== 'Home'">{{ route.key }}</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="arrow" width="4.74" height="7.98" viewBox="0 0 4.74 7.98">
                <path id="Shape" d="M5.5-626a.749.749,0,0,0-.22-.531c-.577-.606-2.038-1.969-3.239-3.239a.747.747,0,0,0-.531-.22.75.75,0,0,0-.75.75.745.745,0,0,0,.22.53L3.688-626l-2.71,2.709a.749.749,0,0,0-.22.531.75.75,0,0,0,.75.75.747.747,0,0,0,.531-.22l3.239-3.24A.745.745,0,0,0,5.5-626Z" transform="translate(-0.758 629.987)" fill="#464646"/>
            </svg>
        </a>
        <span class="current" *ngIf="!route.value">{{ route.key }}</span>
    </li>
</ul>