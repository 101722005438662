import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'nook-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  @Input() name: string;
  @Input() control: FormControl = new FormControl();
  get isError(): boolean {
    return this.control.touched && this.control.invalid;
  }
  constructor() { }

  ngOnInit() {
  }

}
