<div id="fb-root"></div>

<div class="social-share">
    <a href="javascript:void(0)" (click)="onFacebookShare()" mat-icon-button>
        <img src="assets/icons/facebook.svg" width="20px" alt="">
    </a>
    <!-- <a href="javascript:void()" (click)="onShareFb()" class="fb-xfbml-parse-ignore" mat-icon-button>
        <img src="assets/icons/facebook.svg" width="20px" alt="">
    </a> -->
    <a href="javascript:void(0)" (click)="onTwitterShare()" mat-icon-button>
        <img src="assets/icons/twitter.svg" width="20px" alt="">
    </a>
    <a href="javascript:void(0)" (click)="onInstagramShare()" mat-icon-button>
        <img src="assets/icons/instagram.svg" width="18px" alt="">
    </a>
</div>