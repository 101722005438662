import {
    DASHBOARD_ROUTE,
    PROFILE_ROUTE,
    LOAN_REFERRALS_ROUTE,
    LOAN_APPLICATIONS_ROUTE,
    PRE_QUALIFICATIONS_ROUTE,
} from './route.constants';


export const DRAWER_MENUS: Drawer.Menus = [
    {
        label: 'User Dashboard',
        icon: 'dashboard',
        url: DASHBOARD_ROUTE.url,
    },
    {
        label: 'My Profile',
        icon: 'person_outline',
        url: PROFILE_ROUTE.url,
    },
    // {
    //     label: 'Pre-Qualifications',
    //     icon: 'assessment',
    //     url: PRE_QUALIFICATIONS_ROUTE.url,
    // },
    {
        label: 'Loan Applications',
        icon: 'gradient',
        url: LOAN_APPLICATIONS_ROUTE.url,
    },
    {
        label: 'Loan Referrals',
        icon: 'home_work',
        url: LOAN_REFERRALS_ROUTE.url,
    },
    // {
    //     label: 'Help Center',
    //     icon: 'help_outline',
    //     url: HELP_CENTER_ROUTE.url,
    //     access: [USER_TYPES.AGENT.value, USER_TYPES.OWNER.value, USER_TYPES.TENANT.value]
    // },
];
